import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    createClient,
    deleteClient,
    getClients,
    getAllIdTypes,
    getAllSexTypes,
    getAllCountries,
    getAllStatesByCountry,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";

import {
    Table,
    Form,
    Input,
    Button,
    message,
    Modal,
    Select,
    Spin,
    DatePicker,
    Dropdown,
    Menu,
} from "antd";
import {
    DeleteOutlined,
    ExclamationCircleOutlined,
    EyeOutlined,
    LoadingOutlined,
    MoreOutlined,
    PlusOutlined,
} from "@ant-design/icons";

import styles from "./Clients.module.scss";
import { useNavigate } from "react-router-dom";

const Clients = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [data, setData] = useState<any[]>([]);
    const [idTypes, setIdTypes] = useState<any[]>([]);
    const [sexTypes, setSexTypes] = useState<any[]>([]);
    const [countries, setCountries] = useState<any[]>([]);
    const [statesByCountry, setStatesByCountry] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [totalSize, setTotalSize] = useState(0);
    const [actualPage, setActualPage] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [form] = Form.useForm();

    useEffect(() => {
        if (hasPermission(privilegesEnum.READ_CLIENTS)) {
            // handleFetchClients();
            handleFetchIdTypes();
            handleFetchSexTypes();
            handleFetchCountries();
        }
    }, []);

    const handleFetchClients = async (pagination = actualPage) => {
        const response = await getClients(pagination);
        const allClients = response.clients;
        const clientsWithKey: any[] = [];

        allClients.forEach((client: any) => {
            client.key = client.id;
            clientsWithKey.push(client);
        });
        setData(clientsWithKey);
        setTotalSize(response.totalElements);
        setLoading(false);
    };

    const handleFetchIdTypes = async () => {
        const response = await getAllIdTypes();
        setIdTypes(response);
    };

    const handleFetchCountries = async () => {
        const response = await getAllCountries();
        setCountries(response);
    };

    const handleFetchSexTypes = async () => {
        const response = await getAllSexTypes();
        setSexTypes(response);
    };

    const getIdTypesOptions = (items: any) => {
        const options: any[] = [];

        items?.forEach(function (item: any) {
            options.push(
                <Select.Option value={item.id} key={item.id}>
                    {item.descriptionEs}
                </Select.Option>
            );
        });
        return options;
    };

    const getCountriesOptions = (items: any) => {
        const options: any[] = [];

        items?.forEach(function (item: any) {
            options.push(
                <Select.Option value={item.id} key={item.id}>
                    {item.name}
                </Select.Option>
            );
        });
        return options;
    };

    const handleFetchStatesByCountry = async (countryId: number) => {
        const response = await getAllStatesByCountry(countryId);
        setStatesByCountry(response);
    };

    const getSexTypesOptions = (items: any) => {
        const options: any[] = [];

        items?.forEach(function (item: any) {
            options.push(
                <Select.Option value={item.value} key={item.value}>
                    {item.description_es}
                </Select.Option>
            );
        });
        return options;
    };

    const showDeleteConfirm = (itemId: number) => {
        Modal.confirm({
            title: t("Common-DeleteConfirm"),
            icon: <ExclamationCircleOutlined />,
            content: "",
            okText: t("Common-Yes"),
            okType: "danger",
            cancelText: t("Common-No"),
            onOk() {
                handleDeleteClient(itemId);
            },
        });
    };

    const handleDeleteClient = async (clientId: number) => {
        setLoading(true);
        const deleteStatus = await deleteClient(clientId);
        if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
        else message.success(t("Common-DeleteSuccessMessage"));
        await handleFetchClients();
        setLoading(false);
    };

    const handleCreate = async (data: any) => {
        setLoading(true);

        if (data.birthdate) data.birthdate = data.birthdate.format("YYYY-MM-DD");

        const response = await createClient(data);
        if (response) {
            message.success(t("Common-CreateSuccessMessage"));
            form.resetFields();
            setIsModalVisible(false);
            await handleFetchClients(actualPage);
        } else message.error(t("Common-ErrorMessage"));

        setLoading(false);
    };

    const handlePagination = (page: number) => {
        handleFetchClients(page);
        setActualPage(page);
    };

    const showNewModal = () => {
        setIsModalVisible(true);
    };

    const handleCancelNewModal = () => {
        setIsModalVisible(false);
    };

    const columns = [
        {
            title: t("Common-Identifier"),
            key: "identifier",
        },
        {
            title: t("Common-BirthDate"),
            key: "birthdate",
        },
        {
            title: t("Common-RegisterDate"),
            key: "registerDate",
        },
        {
            title: t("Common-Level"),
            key: "level",
        },
        {
            title: t("Common-Segments"),
            key: "segments",
        },
        {
            title: t("Common-TotalAccumulationUnits"),
            key: "totalAccumulationUnits",
        },
        {
            title: t("Common-Actions"),
            key: "action",
            render: (record: any) => (
                <Dropdown
                    overlay={
                        <Menu>
                            <Menu.Item
                                key="view"
                                onClick={() => {
                                    navigate(`/promotions/clients/${record.id}/view`);
                                }}
                                icon={<EyeOutlined />}
                            >
                                {t("Common-View")}
                            </Menu.Item>

                            {hasPermission(privilegesEnum.DELETE_CLIENTS) && (
                                <Menu.Item
                                    key="delete"
                                    onClick={() => {
                                        showDeleteConfirm(record.id);
                                    }}
                                    icon={<DeleteOutlined />}
                                >
                                    {t("Common-Delete")}
                                </Menu.Item>
                            )}
                        </Menu>
                    }
                >
                    <Button
                        onClick={(e) => e.preventDefault()}
                        className={styles.buttonMore}
                    >
                        <MoreOutlined className={styles.moreIcon} />
                    </Button>
                </Dropdown>
            ),
        },
    ];

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <h1 className={styles.title}>{t("Common-Clients")}</h1>
                {hasPermission(privilegesEnum.WRITE_CLIENTS) && (
                    <div className={styles.actionButtonsWrapper}>
                        <Button
                            onClick={() => {
                                navigate("/promotions/clients/new");
                            }}
                            className={styles.newButton}
                            type="primary"
                            icon={<PlusOutlined />}
                        />
                        <Button className={styles.importButton} type="primary">
                            {t("Common-DownloadCsv")}
                        </Button>
                    </div>
                )}
            </div>
            <div className={styles.contentWrapper}>
                {hasPermission(privilegesEnum.READ_CLIENTS) && (
                    <div className={styles.tableWrapper}>
                        <Table
                            size="small"
                            className={styles.table}
                            loading={loading}
                            columns={columns}
                            dataSource={data}
                            pagination={{
                                total: totalSize,
                                showSizeChanger: false,
                                onChange: handlePagination,
                            }}
                        />
                    </div>
                )}
            </div>
            {hasPermission(privilegesEnum.WRITE_CLIENTS) && (
                <Modal
                    title={t("Common-NewClient")}
                    visible={isModalVisible}
                    okButtonProps={{ style: { display: "none" } }}
                    cancelButtonProps={{ style: { display: "none" } }}
                    onCancel={handleCancelNewModal}
                >
                    <Form
                        name="basic"
                        initialValues={{ remember: true }}
                        autoComplete="off"
                        className={styles.formNew}
                        form={form}
                        onFinish={handleCreate}
                    >
                        <Form.Item
                            className={styles.newItem}
                            label={t("Common-Name")}
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: t("Common-NameNotEntered"),
                                },
                            ]}
                        >
                            <Input placeholder={t("Common-Name")} />
                        </Form.Item>
                        <Form.Item
                            className={styles.newItem}
                            label={t("Common-LastName")}
                            name="lastname"
                        >
                            <Input placeholder={t("Common-LastName")} />
                        </Form.Item>
                        <Form.Item
                            className={styles.newItem}
                            label={t("Common-Email")}
                            name="email"
                        >
                            <Input placeholder={t("Common-Email")} />
                        </Form.Item>
                        <Form.Item
                            className={styles.newItem}
                            label={t("Common-Sex")}
                            name="sex"
                        >
                            <Select placeholder={t("Common-Sex")} allowClear>
                                {getSexTypesOptions(sexTypes)}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            className={styles.newItem}
                            label={t("Common-DocumentType")}
                            name="documentType"
                        >
                            <Select placeholder={t("Common-DocumentType")} allowClear>
                                {getIdTypesOptions(idTypes)}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            className={styles.newItem}
                            label={t("Common-DocumentNumber")}
                            name="documentNumber"
                        >
                            <Input placeholder={t("Common-DocumentNumber")} />
                        </Form.Item>
                        <Form.Item
                            className={styles.newItem}
                            label={t("Common-FidelityId")}
                            name="fidelity"
                        >
                            <Input placeholder={t("Common-FidelityId")} />
                        </Form.Item>
                        <Form.Item
                            className={styles.newItem}
                            label={t("Common-Cellphone")}
                            name="cellphone"
                        >
                            <Input placeholder={t("Common-Cellphone")} />
                        </Form.Item>
                        <Form.Item
                            className={styles.newItem}
                            label={t("Common-Telephone")}
                            name="telephone"
                        >
                            <Input placeholder={t("Common-Telephone")} />
                        </Form.Item>
                        <Form.Item
                            className={styles.newItem}
                            label={t("Common-Birthdate")}
                            name="birthdate"
                        >
                            <DatePicker
                                className={styles.dateSelector}
                                placeholder={t("Common-Birthdate")}
                                // onChange={handleChangeDate}
                            />
                        </Form.Item>
                        <Form.Item
                            className={styles.newItem}
                            label={t("Common-Country")}
                            name="country"
                        >
                            <Select
                                placeholder={t("Common-Country")}
                                onChange={(value: any) => {
                                    handleFetchStatesByCountry(value);
                                }}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input: string, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                    0
                                }
                            >
                                {getCountriesOptions(countries)}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            className={styles.newItem}
                            label={t("Common-City")}
                            name="city"
                        >
                            <Select
                                placeholder={t("Common-City")}
                                filterOption={(input: string, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                    0
                                }
                                showSearch
                                optionFilterProp="children"
                            >
                                {getCountriesOptions(statesByCountry)}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            className={styles.newItem}
                            label={t("Common-Locality")}
                            name="locality"
                        >
                            <Input placeholder={t("Common-Locality")} />
                        </Form.Item>
                        <Form.Item
                            className={styles.newItem}
                            label={t("Common-Address")}
                            name="address"
                        >
                            <Input placeholder={t("Common-Address")} />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                className={styles.addNewButton}
                                type="primary"
                                htmlType="submit"
                                disabled={loading}
                            >
                                {loading ? (
                                    <Spin
                                        indicator={
                                            <LoadingOutlined className="ol-loading-spin-icon" />
                                        }
                                        delay={500}
                                    />
                                ) : (
                                    t("Common-Add")
                                )}
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            )}
        </div>
    );
};

export default Clients;

import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import {
  createPurchaseOrder,
  createPurchaseOrderDraft,
  getArticlesFiltered,
  getBranchOfficesFiltered,
  getPurchaseOrderDraft,
  getSkusWithStockFiltered,
  getSuppliersFiltered,
} from "../../api/Services";
import { BusinessUniteContext } from "../../components/laoyut/Layout";

import {
  Button,
  Divider,
  InputNumber,
  message,
  Modal,
  Select,
  Table,
  Typography,
} from "antd";

import styles from "./NewPurchaseOrder2.module.scss";
import OrderBySupplierPreviewDetail from "../../components/orderbysupplierpreviewdetail/OrderBySupplierPreviewDetail";
import OrderPreviewDetail from "../../components/orderpreviewdetail/OrderPreviewDetail";

enum steps {
  CREATING_ORDER = "CREATING_ORDER",
  REVIEWING_DRAFT = "REVIEWING_DRAFT",
  FINAL_REVIEW = "FINAL_REVIEW",
}
const NewPurchaseOrder2 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loadingSkus, setLoadingSkus] = useState(false);
  const [loadingArticles, setLoadingArticles] = useState(false);
  const [loadingBranchOffices, setLoadingBranchOffices] = useState(false);
  const [articleOptions, setArticleOptions] = useState<any[]>([]);
  const [suppliers, setSuppliers] = useState<any[]>([]);
  const [branchOfficeOptions, setBranchOfficeOptions] = useState<any[]>([]);
  const [branchOfficeSelectedId, setBranchOfficeSelectedId] =
    useState(undefined);
  const [selectedSupplierId, setSelectedSupplierId] = useState(undefined);
  const [articleSelected, setArticleSelected] = useState(undefined);
  const [skus, setSkus] = useState<any[]>([]);
  const [selectedSku, setSelectedSku] = useState<any>(null);
  const [actualPage, setActualPage] = useState(1);
  const [totalSize, setTotalSize] = useState(0);
  const [selectedFromTable, setSelectedFromTable] = useState<any[]>([]);
  const [actualDraft, setActualDraft] = useState<any[]>([]);
  const [quantity, setQuantity] = useState<any>({});
  const [currentStep, setCurrentStep] = useState(steps.CREATING_ORDER);
  const [draftReview, setDraftReview] = useState<any[]>([]);
  const [ordersReview, setOrdersReview] = useState<any[]>([]);
  const [finalOrders, setFinalOrders] = useState<any[]>([]);

  const { contextValue: businessUnitSelected } =
    useContext(BusinessUniteContext);

  const handlePagination = (page: number) => {
    handleFetchSkus(page);
    setActualPage(page);
  };

  useEffect(() => {
    handleSearchBranchOffices("");
    handleSearchArticles("");
  }, [businessUnitSelected]);

  useEffect(() => {
    handleFetchSkus();
  }, [articleSelected, branchOfficeSelectedId]);

  useEffect(() => {
    if (
      (currentStep === steps.REVIEWING_DRAFT ||
        currentStep === steps.CREATING_ORDER) &&
      businessUnitSelected
    ) {
      handleFetchDraft();
    }
  }, [currentStep, businessUnitSelected]);

  const handleFetchDraft = useCallback(async () => {
    if (businessUnitSelected) {
      const response = await getPurchaseOrderDraft(businessUnitSelected);
      setDraftReview(response);
    }
  }, [businessUnitSelected]);

  useEffect(() => {
    if (selectedSku) handleFetchSuppliers();
  }, [selectedSku]);

  const handleFetchSuppliers = useCallback(async () => {
    if (businessUnitSelected) {
      const response = await getSuppliersFiltered(0, {
        businessUnitId: businessUnitSelected,
        articleId: selectedSku.articleId,
      });
      setSuppliers(response.suppliers);
    }
  }, [selectedSku]);

  const handlePreviewOrder = useCallback(async () => {
    if (businessUnitSelected) {
      const response = await createPurchaseOrder(draftReview, true);
      setOrdersReview(response.data);
      setCurrentStep(steps.FINAL_REVIEW);
      const orders = response.data?.map((order: any) => {
        return {
          supplierId: order.supplier.id,
          branchOfficeOrDeposit: "BRANCH_OFFICE",
          branchOfficeOrDepositId: order.branchOffice.id,
          suppliers: order.supplierId,
          skus: order.skus.map((sku: any) => {
            return {
              skuId: sku.skuId,
              quantity: sku.quantity,
            };
          }),
        };
      });
      setFinalOrders(orders);
    }
  }, [businessUnitSelected, draftReview]);

  const handleConfirmOrders = async () => {
    if (businessUnitSelected) {
      const response = await createPurchaseOrder(
        finalOrders,
        false,
        businessUnitSelected
      );
      if (response) {
        message.success(t("Common-CreateSuccessMessage"));
        navigate("/purchaseorders/management");
      } else message.error(t("Common-ErrorMessage"));
    }
  };

  const onChangeDate = (dateString: string, indexOrder: any) => {
    finalOrders[indexOrder].estimatedDeliveryDate = dateString;
  };

  useEffect(() => {
    setArticleOptions([]);
    setSkus([]);
    setArticleSelected(undefined);
    setQuantity({});
    setSelectedSupplierId(undefined);
    setBranchOfficeOptions([]);
  }, [businessUnitSelected]);

  const handleFetchSkus = useCallback(
    async (pagination = actualPage) => {
      if (branchOfficeSelectedId && businessUnitSelected) {
        setLoadingSkus(true);
        const response = await getSkusWithStockFiltered({
          pagination: pagination - 1,
          articleId: articleSelected ?? undefined,
          branchOfficeId: branchOfficeSelectedId,
          businessUnitId: businessUnitSelected,
        });

        setSkus(response.skus);
        setTotalSize(response.totalElements);
        setLoadingSkus(false);
      }
    },
    [articleSelected, branchOfficeSelectedId]
  );

  const handleSearchArticles = async (value: string) => {
    setLoadingArticles(true);
    const response = await getArticlesFiltered(0, { articleName: value });
    const options = response.articles.map((item: any) => ({
      value: item.id,
      text: item.name,
    }));
    setArticleOptions(options);
    setLoadingArticles(false);
  };

  const handleSearchBranchOffices = async (value: string) => {
    if (businessUnitSelected) {
      setLoadingArticles(true);
      const response = await getBranchOfficesFiltered(0, {
        name: value,
        businessUnitId: businessUnitSelected,
      });
      const options = response.branchOffices.map((item: any) => ({
        value: item.id,
        text: item.name,
      }));
      setBranchOfficeOptions(options);
      setArticleOptions([]);
      setArticleSelected(undefined);
      setSkus([]);
      setLoadingBranchOffices(false);
      if (value === "" && response.branchOffices.length > 0)
        setBranchOfficeSelectedId(response.branchOffices[0].id);
    } else setBranchOfficeOptions([]);
  };

  const rowSelection = {
    selectedRowKeys: selectedFromTable.map(
      (record: any) => `${record.type}-${record.id}`
    ),
    onChange: (selectedRowKeys: React.Key[], selectedBranchOffices: any[]) => {
      setSelectedFromTable(selectedBranchOffices);
    },
  };

  const skusColumns = [
    {
      title: t("Common-Article"),
      key: "article",
      render: (record: any) => (
        <a href={`/admin/articles/${record.articleId}/view`}>
          {record.articleName}
        </a>
      ),
    },
    {
      title: t("Common-Name"),
      dataIndex: "name",
    },
    {
      title: t("Common-Stock"),
      key: "stock",
      render: (record: any) => (
        <div className={styles.stockWrapper}>
          <Typography.Text>{`${record.stockInBranchOffice} ${t(
            "Common-StockCurrent"
          )}`}</Typography.Text>
          <Typography.Text>{`${record.stockInOrders} ${t(
            "Common-StockInTransit"
          )}`}</Typography.Text>
        </div>
      ),
    },
    {
      title: t("Common-QuantityInDraft"),
      key: "quantity",
      render: (record: any) => {
        return draftReview.reduce((total, draft) => {
          if (
            draft.skuId === record.id &&
            draft.branchOfficeOrDepositId === branchOfficeSelectedId
          ) {
            return total + draft.quantity;
          }
          return total;
        }, 0);
      },
    },
    {
      render: (record: any) => (
        <Button
          className={styles.addSkuButton}
          onClick={async () => {
            if (businessUnitSelected) {
              const draft = await getPurchaseOrderDraft(businessUnitSelected);
              setActualDraft(draft);
              const newQuantity: any = {};
              const newSelectedFromTable: any = [];
              draft.forEach((info: any) => {
                newQuantity[
                  `${info.branchOfficeOrDepositFrom}-${info.branchOfficeOrDepositFromId}`
                ] = info.quantity;
                if (info.branchOfficeOrDepositToId === selectedSupplierId)
                  newSelectedFromTable.push({
                    id: info.branchOfficeOrDepositFromId,
                    name: info.branchOfficeOrDepositFrom,
                    stock: info.quantity,
                    type: info.branchOfficeOrDepositFrom,
                  });
              });
              setSelectedFromTable(newSelectedFromTable);
              setQuantity(newQuantity);
            }
            setSelectedSku(record);
          }}
          type="primary"
        >
          {t("Common-Add")}
        </Button>
      ),
    },
  ];

  const suppliersColumns = [
    {
      title: t("Common-Name"),
      dataIndex: "name",
      width: 20,
    },
    {
      title: t("Common-Quantity"),
      render: (record: any) => {
        const quantityInDraft = actualDraft.find(
          (draft) =>
            draft.skuId === selectedSku.id && draft.supplierId === record.id
        )?.quantity;

        return selectedFromTable.find(
          (entity) => entity.id === record.id && record.type === entity.type
        ) || quantityInDraft ? (
          <InputNumber
            defaultValue={quantityInDraft ?? 0}
            onChange={(value) =>
              setQuantity((prevQuantity: any) => ({
                ...prevQuantity,
                [`${record.type}-${record.id}`]: value,
              }))
            }
          />
        ) : (
          ""
        );
      },
      width: 40,
    },
  ];

  const draftColumns = [
    {
      title: t("Common-Article"),
      key: "skuName",
      width: 20,
      render: (record: any) => `${record.articleName} - ${record.skuName}`,
    },
    {
      title: t("Common-Origin"),
      dataIndex: "branchOfficeOrDepositFromName",
      width: 20,
    },
    {
      title: t("Common-Destiny"),
      dataIndex: "branchOfficeOrDepositToName",
      width: 20,
    },
    {
      title: t("Common-Quantity"),
      dataIndex: "quantity",
      width: 20,
    },
  ];

  const handleAdd = async () => {
    if (branchOfficeSelectedId) {
      const draft = selectedFromTable.map((selected) => {
        return {
          skuId: selectedSku.id,
          branchOfficeOrDeposit: "BRANCH_OFFICE",
          branchOfficeOrDepositId: branchOfficeSelectedId,
          quantity: quantity[`${selected.type}-${selected.id}`],
          supplierId: selected.id,
        };
      });

      if (businessUnitSelected) {
        const response = await createPurchaseOrderDraft(
          draft,
          businessUnitSelected
        );
        if (response.success) {
          setSelectedSku(null);
          handleFetchDraft();
        }
      }
    }
  };

  return currentStep === steps.CREATING_ORDER ? (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-NewPurchaseOrder")}</h1>
        <Button
          onClick={() => setCurrentStep(steps.REVIEWING_DRAFT)}
          className={styles.saveButton}
          type="primary"
          disabled={businessUnitSelected === null}
        >
          {t("Common-ViewSummary")}
        </Button>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.tableWrapper}>
          <div className={styles.filtersWrapper}>
            <div className={styles.filterWrapper}>
              <Select
                showSearch
                value={branchOfficeSelectedId ?? undefined}
                placeholder={t("Common-SearchBranchOfficeTextPlaceholder")}
                onSelect={(_, option) => {
                  if (option) setBranchOfficeSelectedId(option.value);
                }}
                loading={loadingBranchOffices}
                className={styles.filterSelect}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={handleSearchBranchOffices}
                options={(branchOfficeOptions || []).map((d: any) => ({
                  value: d.value,
                  label: d.text,
                }))}
              />
            </div>
            <div className={styles.filterWrapper}>
              <Select
                showSearch
                value={articleSelected}
                allowClear
                onClear={() => setArticleSelected(undefined)}
                placeholder={t("Common-SearchArticleTextPlaceholder")}
                onSelect={(_, option) => {
                  if (option) setArticleSelected(option.value);
                }}
                loading={loadingArticles}
                className={styles.filterSelect}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={handleSearchArticles}
                options={(articleOptions || []).map((d: any) => ({
                  value: d.value,
                  label: d.text,
                }))}
              />
            </div>
          </div>
          <Table
            size="small"
            className={styles.table}
            rowKey={(record) => record.id}
            loading={loadingSkus}
            columns={skusColumns}
            dataSource={skus}
            pagination={{
              total: totalSize,
              showSizeChanger: false,
              onChange: handlePagination,
            }}
          />
        </div>
      </div>
      <Modal
        title={t("Common-Add")}
        visible={selectedSku !== null}
        destroyOnClose={true}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={() => setSelectedSku(null)}
      >
        {selectedSku && (
          <>
            <div className={styles.actionWrapper}>
              <Button
                className={styles.addButton}
                type="primary"
                onClick={handleAdd}
                disabled={selectedFromTable.length === 0}
              >
                {t("Common-Add")}
              </Button>
            </div>
            <Divider />
            <Table
              size="small"
              rowKey={(record) => `${record.type}-${record.id}`}
              loading={loadingBranchOffices}
              columns={suppliersColumns}
              dataSource={suppliers.filter((obj: any) => obj !== null)}
              rowSelection={{
                ...rowSelection,
              }}
            />
          </>
        )}
      </Modal>
    </div>
  ) : currentStep === steps.REVIEWING_DRAFT ? (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-ReviewDraft")}</h1>
        <div className={styles.actionButtonsPreview}>
          <Button
            onClick={() => {
              setCurrentStep(steps.CREATING_ORDER);
            }}
            className={styles.backButton}
            type="primary"
          >
            {t("Common-GoBack")}
          </Button>
          <Button
            onClick={handlePreviewOrder}
            className={styles.saveButton}
            type="primary"
          >
            {t("Common-GenerateOrder")}
          </Button>
        </div>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.tableWrapper}>
          <Table
            size="small"
            rowKey={(record) => record.id}
            columns={draftColumns}
            dataSource={draftReview}
          />
        </div>
      </div>
    </div>
  ) : currentStep === steps.FINAL_REVIEW ? (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-GeneratedOrders")}</h1>
        <div className={styles.actionButtonsPreview}>
          <Button
            onClick={() => {
              setCurrentStep(steps.REVIEWING_DRAFT);
            }}
            className={styles.backButton}
            type="primary"
          >
            {t("Common-GoBack")}
          </Button>
          <Button
            onClick={handleConfirmOrders}
            className={styles.saveButton}
            type="primary"
          >
            {t("Common-Confirm")}
          </Button>
        </div>
      </div>

      <div>
        {/*{bySupplier ? (*/}
        <OrderBySupplierPreviewDetail orders={ordersReview} />
        {/*) : (*/}
        {/*  ordersReview.map((order: any, index: number) => {*/}
        {/*    return (*/}
        {/*      <OrderPreviewDetail*/}
        {/*        order={order}*/}
        {/*        indexOrder={index}*/}
        {/*        onChangeDate={onChangeDate}*/}
        {/*      />*/}
        {/*    );*/}
        {/*  })*/}
        {/*)}*/}
      </div>
    </div>
  ) : null;
};

export default NewPurchaseOrder2;

import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getTickets, deleteTicket } from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";

import { Table, Button, message, Space, Modal } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";

import styles from "./Tickets.module.scss";

const TicketsMockup = () => {
  const { t } = useTranslation();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalSize, setTotalSize] = useState(0);
  const [actualPage, setActualPage] = useState(0);

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_TICKETS)) {
      handleFetchTickets();
    }
  }, []);

  const handleFetchTickets = async (pagination = actualPage) => {
    const response = await getTickets(pagination);
    const allTickets = response.tickets;
    const ticketsWithKey: any[] = [];

    allTickets.forEach((ticket: any) => {
      ticket.key = ticket.id;
      ticketsWithKey.push(ticket);
    });
    setData(ticketsWithKey);
    setTotalSize(response.totalElements);
    setLoading(false);
  };

  const showDeleteConfirm = (itemId: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDelete(itemId);
      },
    });
  };

  const handleDelete = async (ticketId: number) => {
    setLoading(true);
    const deleteStatus = await deleteTicket(ticketId);
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));
    await handleFetchTickets();
    setLoading(false);
  };

  const handlePagination = (page: number) => {
    handleFetchTickets(page);
    setActualPage(page);
  };

  const columns = [
    {
      title: t("Common-Code"),
      dataIndex: "code",
      key: "code",
    },
    {
      title: t("Common-Client"),
      dataIndex: "clientRut",
      key: "clientRut",
    },
    {
      title: t("Common-Date"),
      dataIndex: "date",
      key: "date",
      render: (date: any) => moment(date, "YYYY-MM-DD").format("DD-MM-YYYY"),
    },

    {
      title: t("Common-Total"),
      dataIndex: "total",
      key: "total",
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          {hasPermission(privilegesEnum.WRITE_TICKETS) ? (
            <EditOutlined
              href={`/admin/tickets/${record.id}/view`}
              onClick={() => {
                window.location.href = `/admin/tickets/${record.id}/view`;
              }}
            />
          ) : (
            <EyeOutlined
              href={`/admin/tickets/${record.id}/view`}
              onClick={() => {
                window.location.href = `/admin/tickets/${record.id}/view`;
              }}
            />
          )}
          {hasPermission(privilegesEnum.DELETE_TICKETS) && (
            <DeleteOutlined
              onClick={() => {
                showDeleteConfirm(record.key);
              }}
            />
          )}
        </Space>
      ),
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-Tickets")}</h1>
        {hasPermission(privilegesEnum.WRITE_TICKETS) && (
          <div className={styles.actionButtonsWrapper}>
            {/*<Button*/}
            {/*  href="/tickets/create"*/}
            {/*  className={styles.newButton}*/}
            {/*  type="primary"*/}
            {/*  icon={<PlusOutlined />}*/}
            {/*/>*/}
            <Button
              // onClick={handleCancelButton}
              className={styles.importButton}
              type="primary"
            >
              {t("Common-Import")}
            </Button>
          </div>
        )}
      </div>
      <div className={styles.contentWrapper}>
        {hasPermission(privilegesEnum.READ_TICKETS) && (
          <div className={styles.tableWrapper}>
            <Table
              size="small"
              className={styles.table}
              loading={loading}
              columns={columns}
              dataSource={data}
              pagination={{ total: totalSize, showSizeChanger:false, onChange: handlePagination }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TicketsMockup;

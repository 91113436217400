import { useTranslation } from "react-i18next";
import { useEffect, useState, useRef, ReactNode } from "react";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";
import {
  addArticleStock,
  discontinueBranchOfficeArticle,
  editArticleStock,
  getSkusFiltered,
  getStockAdjustments,
} from "../../api/Services";

import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Space,
  Spin,
  Table,
  Select,
  Switch,
} from "antd";
import {
  EditOutlined,
  EyeOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import styles from "./ArticleStock.module.scss";

const ArticleStock = (props: {
  data: any;
  fetchData: any;
  header?: ReactNode;
  type: "deposits" | "branchOffices";
  placeId: number;
  totalElements?: number;
  updatePage?: any;
  searchByName: boolean;
  updateName?: any;
  page?: number;
  loadingPage?: boolean;
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [loadingStock, setLoadingStock] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [articles, setArticles] = useState<any[]>([]);
  const [stockAdjustments, setStockAdjustments] = useState<any[]>([]);

  const [form] = Form.useForm();
  const [editStockForm] = Form.useForm();
  const firstLoadRef = useRef(true);

  const canEdit = hasPermission(privilegesEnum.READ_ARTICLES);
  const { data, totalElements } = props;

  const handleFetchArticles = async (value: string) => {
    const response = await getSkusFiltered(0, {
      articleName: value,
    });
    setArticles(response.skus);
  };

  const handleFetchStockAdjustments = async (articleStock: any) => {
    setLoadingStock(true);
    const response = await getStockAdjustments(articleStock.id, props.type, 0);
    setStockAdjustments(response.data);
    setLoadingStock(false);
  };

  useEffect(() => {
    if (!firstLoadRef.current && !loadingStock) {
      showStockAdjustments();
    } else {
      firstLoadRef.current = false;
    }
  }, [stockAdjustments, loadingStock]);

  const handleAddStock = async (data: any) => {
    setLoading(true);
    const { type, placeId, fetchData } = props;
    const { stock, article } = data;

    const response = await addArticleStock(stock, article, type, placeId);
    if (response) {
      message.success(t("Common-CreateSuccessMessage"));
      form.resetFields();
      setIsModalVisible(false);
      await fetchData();
    } else message.error(t("Common-ErrorMessage"));

    setLoading(false);
  };

  const getOptions = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {`${item.articleName} - ${item.name}`}
        </Select.Option>
      );
    });

    return options;
  };

  const showNewModal = () => {
    setIsModalVisible(true);
  };

  const handleCancelNewModal = () => {
    setIsModalVisible(false);
  };

  const handleEditStock = async () => {
    setLoading(true);
    const { type, fetchData } = props;

    const { id, stock } = editStockForm.getFieldsValue();
    const response = await editArticleStock(id, stock, type);

    if (response) {
      message.success(t("Common-EditSuccessMessage"));
    } else message.error(t("Common-EditErrorMessage"));

    Modal.destroyAll();
    await fetchData();
    setLoading(false);
  };

  const handlePagination = (page: number) => {
    if (props.fetchData && props.updatePage) {
      props.fetchData(page);
      props.updatePage(page);
    }
  };

  const showStockAdjustments = () => {
    Modal.info({
      title: t("Common-StockAdjustments"),
      closable: true,
      maskClosable: true,
      icon: null,
      okButtonProps: { style: { display: "none" } },
      content: (
        <>
          <Table
            columns={stockColumns}
            rowKey={(record) => record.id}
            dataSource={stockAdjustments}
            loading={loadingStock}
          />
        </>
      ),
    });
  };

  const stockColumns = [
    {
      title: t("Common-Stock"),
      dataIndex: "stock",
    },
    {
      title: t("Common-Date"),
      dataIndex: "date",
      render: (date: string) => date.replace("T", " "),
    },
  ];

  const articlesColumns = [
    {
      title: t("Common-Name"),
      dataIndex: "sku",
      render: (sku: any) => (
        <a href={`/admin/articles/${sku.articleId}/view`}>{sku.name}</a>
      ),
    },
    {
      title: t("Common-Stock"),
      dataIndex: "stock",
    },
    {
      title: t("Common-Supply"),
      dataIndex: "unsupply",
      render: (unsupply: any, record: any) => (
        <Switch
          defaultChecked={!unsupply}
          onChange={() => handleUnsupplyArticle(record)}
        />
      ),
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          <EyeOutlined
            onClick={() => {
              handleFetchStockAdjustments(record);
            }}
          />
        </Space>
      ),
    },
  ];

  const handleUnsupplyArticle = async (article: any) => {
    setLoading(true);
    const deleteStatus = await discontinueBranchOfficeArticle(
      article.branchOffice.id,
      article.sku.id
    );
    if (!deleteStatus) message.error(t("Common-ErrorMessage"));
    else message.success(t("Common-SuccessMessage"));
    await props.fetchData();
    setLoading(false);
  };

  return (
    <div className={styles.tableWrapper}>
      <div className={styles.tablesHeader}>
        <h1 className={styles.tableTitle}>{t("Common-Articles")}</h1>
        <div className={styles.actionsButtonWrapper}>
          {props.header}
          {canEdit && (
            <Button
              type="primary"
              className={styles.actionsButton}
              onClick={() => showNewModal()}
              icon={<PlusOutlined />}
            />
          )}
        </div>
      </div>
      <div>
        {props.searchByName && props.updateName && (
          <div className={styles.filterWrapper}>
            <Input
              className={styles.filterInput}
              placeholder={t("Common-SearchBySkuName")}
              onChange={(e) => props.updateName(e.target.value)}
            />
          </div>
        )}

        <Table
          size="small"
          rowKey={(record) => record.id}
          className={styles.table}
          loading={loading || props.loadingPage}
          columns={articlesColumns}
          dataSource={data}
          pagination={{
            total: totalElements,
            showSizeChanger: false,
            onChange: handlePagination,
            current: props.page,
          }}
        />
      </div>

      {hasPermission(privilegesEnum.WRITE_ARTICLES) && (
        <Modal
          title={t("Common-AddStock")}
          visible={isModalVisible}
          okButtonProps={{ style: { display: "none" } }}
          cancelButtonProps={{ style: { display: "none" } }}
          onCancel={handleCancelNewModal}
        >
          <Form
            name="basic"
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.formNewCompetitor}
            form={form}
            onFinish={handleAddStock}
          >
            <Form.Item
              className={styles.newItem}
              label={t("Common-Article")}
              name="article"
              rules={[
                {
                  required: true,
                  message: t("Common-Article"),
                },
              ]}
            >
              <Select
                className={styles.privileges}
                allowClear
                placeholder={t("Common-SearchByName")}
                filterOption={(input: string, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                showSearch
                style={{ width: "100%" }}
                onSearch={handleFetchArticles}
              >
                {getOptions(articles)}
              </Select>
            </Form.Item>
            <Form.Item
              className={styles.newItem}
              label={t("Common-Stock")}
              name="stock"
              rules={[
                {
                  required: true,
                  message: t("Common-Stock"),
                },
              ]}
            >
              <Input placeholder={t("Common-Stock")} />
            </Form.Item>
            <Form.Item>
              <Button
                className={styles.addNewButton}
                type="primary"
                htmlType="submit"
                disabled={loading}
              >
                {loading ? (
                  <Spin
                    indicator={
                      <LoadingOutlined className="ol-loading-spin-icon" />
                    }
                    delay={500}
                  />
                ) : (
                  t("Common-Add")
                )}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </div>
  );
};

export default ArticleStock;

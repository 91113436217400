import axios from "axios";
import {
  addDepositToCluster,
  addSeasonToArticle,
  deletePlaceFromCluster,
  getClusterById,
  getSkusByBrandCategoryCatalogue,
} from "./Services";

const baseURL = process.env.REACT_APP_API_URL;

const token = localStorage.getItem("token") ?? "";

const client = axios.create({
  baseURL,
  // headers: {
  //   Authorization: token,
  // },
});

client.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 403 && !originalRequest._retry) {
      console.log(error);
      originalRequest._retry = true;
      //todo call refresh token and have generic function for logout
      localStorage.removeItem("token");
      localStorage.removeItem("organizationId");
      localStorage.removeItem("privileges");
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export class BrightMarketApi {
  static async getOrganizations() {
    const response = await client.get("/api/organizations");
    const organizations = response.data._embedded;
    return organizations;
  }

  static async getUsers(
    organizationId: string,
    filters: {
      email?: string;
      name?: string;
      businessUnitId?: string;
      rolId?: number;
      branchOfficeId?: number;
    }
  ) {
    const { name, email, rolId, branchOfficeId, businessUnitId } = filters;
    const response = await client.get(
      `/api/users/search/findByOrganizationId?id=${organizationId}${
        email ? `&email=${email}` : ""
      }${name ? `&name=${name}` : ""}${
        businessUnitId ? `&businessUnitId=${businessUnitId}` : ""
      }${rolId ? `&rolId=${rolId}` : ""}${
        branchOfficeId ? `&branchOfficeId=${branchOfficeId}` : ""
      }`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    const users = response.data._embedded;

    return users;
  }

  static async getUser() {
    const response = await client.get(`/api/users/`, {
      headers: {
        Authorization: token,
      },
    });
    return response.data.data;
  }

  static async registerOrganization(
    organizationName: string,
    username: string,
    password: string
  ) {
    const formData = new FormData();
    formData.append("organizationName", organizationName);
    formData.append("userEmail", username);
    formData.append("password", password);
    const response = await client.post("/api/organizations/register", formData);

    return response;
  }

  static async login(
    username: string,
    password: string,
    organizationName: string
  ) {
    const response = await client.post("/api/auth/login", {
      email: username,
      password,
      organizationName,
    });

    return response;
  }

  static async loginSuperAdmin(organizationName: string) {
    const response = await client.post(
      "/api/auth/loginSuperAdmin",
      {
        organizationName,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async validateEmail(token: string) {
    const response = await client.get(`/api/auth/confirmToken?token=${token}`);
    return response;
  }

  static async confirmUserAndSetPassword(token: string, password: string) {
    const formData = new FormData();
    formData.append("token", token);
    formData.append("password", password);
    const response = await client.post(`/api/auth/confirm`, formData);
    return response;
  }

  static async confirmUser(token: string) {
    const response = await client.get(`/api/auth/confirm?token=${token}`);
    return response;
  }

  static async resendValidateEmail(email: string, organizationName: string) {
    const formData = new FormData();
    formData.append("organizationName", organizationName);
    formData.append("userEmail", email);

    const response = await client.post(
      `/api/auth/resendUserValidation`,
      formData
    );

    return response;
  }

  static async sendForgotPassword(email: string, organizationName: string) {
    const formData = new FormData();
    formData.append("organizationName", organizationName);
    formData.append("userEmail", email);

    const response = await client.post(`/api/auth/resetPasswordLink`, formData);

    return response;
  }

  static async sendResetPassword(password: string, token: string) {
    const formData = new FormData();
    formData.append("newPassword", password);
    formData.append("token", token);

    const response = await client.post(`/api/auth/resetPassword`, formData);

    return response;
  }

  static async existsOrganizationByName(organizationName: string) {
    const response = await client.get(
      `/api/organizations/search/existsByName?name=${organizationName}`
    );

    return response;
  }

  static async createUser(
    username: string,
    roles: any,
    businessUnitsIds: any,
    branchOfficesIds: any
  ) {
    const formData = new FormData();
    formData.append("userEmail", username);
    formData.append("roleIds", roles);
    formData.append("businessUnitsIds", businessUnitsIds);
    formData.append("branchOfficesIds", branchOfficesIds);
    const response = await client.post("/api/users/create", formData, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async deleteUser(userId: number) {
    const response = await client.delete(`/api/users/${userId}`, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async fetchOrganizationByName(
    organizationName: string,
    authToken?: string
  ) {
    const response = await client.get(
      `/api/organizations/search/findByName?name=${organizationName}&projection=organizationProjection`,
      {
        headers: {
          Authorization: authToken ?? token,
        },
      }
    );

    return response;
  }

  static async fetchOrganizationById(
    organizationId: string,
    authToken?: string
  ) {
    const response = await client.get(`/api/organizations/${organizationId}`, {
      headers: {
        Authorization: authToken ?? token,
      },
    });

    return response;
  }

  static async updateOrganization(
    organizationId: string,
    realName: string,
    businessName: string,
    rut: string,
    address: string
  ) {
    const response = await client.put(
      `/api/organizations/${organizationId}`,
      {
        realName,
        businessName,
        rut,
        address,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async getRoles(
    organizationId: string,
    pagination: number,
    filters: { name?: string; privilegeId?: number }
  ) {
    const { name, privilegeId } = filters;
    const response = await client.get(
      `/api/roles/search/findByOrganizationId?organizationId=${organizationId}&projection=roleProjection&size=10&page=${
        pagination - 1
      }${name ? `&name=${name}` : ""}${
        privilegeId ? `&privilegeId=${privilegeId}` : ""
      }`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const roles = response.data._embedded;
    const { totalElements } = response.data.page;

    return { roles, totalElements };
  }

  static async createRol(name: string, description: string, privileges: any) {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("privilegesId", privileges);

    const response = await client.post("/api/roles/", formData, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async getPrivileges() {
    const response = await client.get(
      `/api/privileges/search/getAll?projection=privilegeProjection`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const privileges = response.data._embedded;

    return privileges;
  }

  static async getAllRoles(organizationId: string) {
    const response = await client.get(
      `/api/roles/search/getAllByOrganizationId?organizationId=${organizationId}&projection=roleProjection`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const roles = response.data._embedded;

    return roles;
  }

  static async deleteRol(rolId: number) {
    const response = await client.delete(`/api/roles/${rolId}`, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async editRol(
    rolId: number,
    name: string,
    description: string,
    privileges: any
  ) {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("privilegesId", privileges);

    const response = await client.put(`/api/roles/${rolId}`, formData, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async getCurrencies() {
    const response = await client.get(
      `/api/currencies/search/getAll?projection=currencyProjection`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const currencies = response.data._embedded;

    return currencies;
  }
  static async getLanguages() {
    const response = await client.get(
      `/api/languages/search/getAll?projection=languageProjection`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const languages = response.data._embedded;

    return languages;
  }

  static async getChannelsTypes() {
    const response = await client.get(
      `/api/channelTypes/search/getAll?projection=channelTypeProjection`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const channelTypes = response.data._embedded;

    return channelTypes;
  }

  static async getBusinessUnits(organizationId: string, pagination: number) {
    const response = await client.get(
      `/api/businessUnits/search/findByOrganizationId?organizationId=${organizationId}&projection=businessUnitProjection&size=10&page=${
        pagination - 1
      }`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const businessUnits = response.data._embedded;
    const { totalElements } = response.data.page;

    return { businessUnits, totalElements };
  }

  static async getAllBusinessUnits() {
    const response = await client.get(`/api/businessUnits/getAll`, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  }

  static async createBusinessUnit(
    name: string,
    languageId: number,
    currencyId: number
  ) {
    const formData = new FormData();
    formData.append("name", name);
    if (languageId) formData.append("languageId", languageId.toString());
    if (currencyId) formData.append("currencyId", currencyId.toString());

    const response = await client.post("/api/businessUnits/", formData, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async createChannel(
    businessUnitId: number,
    currenciesIds: any,
    channelTypeId: number
  ) {
    const formData = new FormData();
    if (currenciesIds) formData.append("currenciesIds", currenciesIds);
    formData.append("channelTypeId", channelTypeId.toString());

    const response = await client.post(
      `/api/businessUnits/${businessUnitId}/createChannel`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async getAllChannelsByBusinessUnits(
    organizationId: string,
    businessUnitsIds: any
  ) {
    let bus = "";
    businessUnitsIds.forEach((id: any) => {
      bus = bus + `${id},`;
    });
    const response = await client.get(
      `/api/channels/search/findByOrganizationIdAndBussinesUnitIds?organizationId=${organizationId}&businessUnitIds=${businessUnitsIds}&projection=ChannelWithBusinessUnitProjection`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    const channels = response.data._embedded;
    return channels;
  }

  static async getChannels(organizationId: string, pagination: number) {
    const response = await client.get(
      `/api/channels/search/findByOrganizationId?organizationId=${organizationId}&projection=ChannelWithBusinessUnitProjection&size=10&page=${pagination}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const channels = response.data._embedded;
    const { totalElements } = response.data.page;

    return { channels, totalElements };
  }

  static async deleteChannel(channelId: number) {
    const response = await client.delete(`/api/channels/${channelId}`, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async editBusinessUnit(
    businessUnitId: number,
    businessUnit: {
      name?: string;
      languageId?: string;
      currencyId?: string;
      targetMargin?: number;
    }
  ) {
    const { name, languageId, currencyId, targetMargin } = businessUnit;
    const formData = new FormData();
    if (name) formData.append("name", name);
    if (languageId) formData.append("languageId", languageId.toString());
    if (currencyId) formData.append("currencyId", currencyId.toString());
    if (targetMargin) formData.append("targetMargin", targetMargin.toString());

    const response = await client.put(
      `/api/businessUnits/${businessUnitId}`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async editChannel(
    channelId: number,
    type: string,
    currenciesIds: any
  ) {
    const formData = new FormData();
    formData.append("channelTypeId", type);
    if (currenciesIds) formData.append("currenciesIds", currenciesIds);

    const response = await client.put(
      `/api/businessUnits/channel/${channelId}`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async deleteBusinessUnit(businessUnitId: number) {
    const response = await client.delete(
      `/api/businessUnits/${businessUnitId}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }
  static async editUser(
    userId: number,
    name: string,
    lastName: string,
    roles: any,
    businessUnitsIds: any,
    branchOfficesIds: any
  ) {
    const formData = new FormData();
    formData.append("name", name ?? "");
    formData.append("lastname", lastName ?? "");
    formData.append("roleIds", roles);
    formData.append("businessUnitsIds", businessUnitsIds);
    formData.append("branchOfficesIds", branchOfficesIds);

    const response = await client.put(`/api/users/${userId}`, formData, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async editUserPassword(oldPassword: string, newPassword: string) {
    const formData = new FormData();
    formData.append("oldPassword", oldPassword);
    formData.append("newPassword", newPassword);

    const response = await client.put(`/api/users/password`, formData, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async editUserProfile(name: string, lastName: string) {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("lastname", lastName);

    const response = await client.put(`/api/users/editMyUser`, formData, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async getCategoryById(categoryId: string) {
    const response = await client.get(`/api/categories/${categoryId}`, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  }

  static async getCategoriesByOrganization(
    organizationId: string,
    pagination: number
  ) {
    const response = await client.get(
      `/api/categories/search/findByOrganizationId?organizationId=${organizationId}&projection=categoryProjection&size=10&page=${
        pagination - 1
      }`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const categories = response.data._embedded;
    const { totalElements } = response.data.page;

    return { categories, totalElements };
  }

  static async getCategoriesFiltered(name: string) {
    const response = await client.get(
      `/api/categories/query?name=${name}&page=0&size=10`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response.data;
  }

  static async getSuppliersFiltered(
    pagination: number,
    filters: {
      name?: string;
      businessUnitId?: string;
      externalId?: string;
      articleId?: string;
    }
  ) {
    const { name, businessUnitId, articleId, externalId } = filters;

    const response = await client.get(
      `/api/suppliers/query?${name ? `&name=${name}` : ""}${
        externalId ? `&externalId=${externalId}` : ""
      }${businessUnitId ? `&businessUnitId=${businessUnitId}` : ""}${
        articleId ? `&articleId=${articleId}` : ""
      }&page=${pagination}&size=10`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const suppliers = response.data.data.content;
    const { totalElements } = response.data.data;

    return { suppliers, totalElements };
  }

  static async getBranchOfficesFiltered(
    pagination: number,
    filters: {
      name?: string;
      externalId?: string;
      businessUnitId?: string;
    }
  ) {
    const { name, businessUnitId, externalId } = filters;

    const response = await client.get(
      `/api/branchOffices/query?${name ? `&name=${name}` : ""}${
        externalId ? `&externalId=${externalId}` : ""
      }${
        businessUnitId ? `&businessUnitId=${businessUnitId}` : ""
      }&page=${pagination}&size=10`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const branchoffices = response.data.data.content;
    const { totalElements } = response.data.data;

    return { branchoffices, totalElements };
  }

  static async getClientSegmentsFiltered(name: string) {
    const response = await client.get(
      `/api/clients/segments/query?name=${name}&page=0&size=10`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response.data;
  }

  static async getDepositsFiltered(
    pagination: number,
    filters: { name?: string }
  ) {
    const { name } = filters;
    const response = await client.get(
      `/api/deposits/query?${
        name ? `&name=${name}` : ""
      }&page=${pagination}&size=10`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const deposits = response.data.data.content;
    const { totalElements } = response.data.data;

    return { deposits, totalElements };
  }

  static async getBrandsFiltered(name: string) {
    const response = await client.get(
      `/api/brands/query?name=${name}&page=0&size=10`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response.data;
  }

  static async getPurchaseUnitsFiltered(name: string) {
    const response = await client.get(
      `/api/purchaseUnits/query?name=${name}&page=0&size=10`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response.data;
  }

  static async getCategoriesByFather(
    organizationId: string,
    pagination: number,
    filters: { name?: string; externalId?: string; enable?: number },
    fatherId?: number
  ) {
    const { name, externalId, enable } = filters;

    const response = await client.get(
      `/api/categories/search/findByFatherId?fatherId=${
        fatherId ?? ""
      }&organizationId=${organizationId}&projection=categoryProjection${
        fatherId ? "" : "&size=10"
      }&page=${pagination} ${name ? `&name=${name}` : ""}${
        enable !== undefined ? `&enable=${enable}` : ""
      }${externalId ? `&externalId=${externalId}` : ""}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const categories = response.data._embedded;
    const { totalElements } = response.data.page;

    return { categories, totalElements };
  }

  static async getRoundingTypes() {
    const response = await client.get(
      `/api/roundingTypes/search/getAll?projection=roundingType`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const roundingTypes = response.data._embedded;

    return roundingTypes;
  }

  static async createCategory(category: {
    name: string;
    externalId: string;
    description: string;
    fatherId: number;
    roundingTypeId: number;
    enable: boolean;
    businessUnitId: any;
  }) {
    const formData = new FormData();
    formData.append("name", category.name);
    if (category.description)
      formData.append("description", category.description);
    if (category.externalId) formData.append("externalId", category.externalId);
    formData.append("fatherId", category.fatherId.toString());
    if (category.roundingTypeId)
      formData.append("roundingTypeId", category.roundingTypeId.toString());
    formData.append("enable", category.enable.toString());
    formData.append("businessUnitsIds", category.businessUnitId);

    const response = await client.post("/api/categories/", formData, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async deleteCategory(categoryId: number) {
    const response = await client.delete(`/api/categories/${categoryId}`, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async editCategory(
    categoryId: number,
    name: string,
    externalId: string,
    description: string,
    fatherId: number,
    roundingTypeId: number,
    enable: boolean,
    businessUnitId: any
  ) {
    const formData = new FormData();
    formData.append("name", name);
    if (externalId) formData.append("externalId", externalId);
    if (description) formData.append("description", description);
    formData.append("fatherId", fatherId.toString());
    if (roundingTypeId)
      formData.append("roundingTypeId", roundingTypeId.toString());
    formData.append("enable", enable.toString());
    formData.append("businessUnitsIds", businessUnitId);

    const response = await client.put(
      `/api/categories/${categoryId}`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async getAllCategories() {
    const response = await client.get(`/api/categories/getAll`, {
      headers: {
        Authorization: token,
      },
    });

    return response.data;
  }

  static async getSkusByBranchOfficeId(
    branchOfficeId: number,
    pagination: number
  ) {
    const response = await client.get(
      `/api/articles/sku/findByBranchOfficeId?size=10&page=${pagination}&branchOfficeId=${branchOfficeId}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const skus = response.data.data.content;
    const { totalElements } = response.data.data;

    return { skus, totalElements };
  }

  static async getBrandsByBranchOfficeId(
    entity: {
      branchOfficeId?: number;
      depositId?: number;
    },
    pagination: number
  ) {
    const { branchOfficeId, depositId } = entity;
    const response = await client.get(
      `/api/articles/sku/findBrandsDistinctBrandsByBranchOfficeIdOrDepositIdAndOrganizationId?size=10&page=${pagination}
      ${branchOfficeId ? `&branchOfficeId=${branchOfficeId}` : ""}${
        depositId ? `&depositId=${depositId}` : ""
      }
      `,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const brands = response.data.data.content;
    const { totalElements } = response.data.data;

    return { brands, totalElements };
  }

  static async getBrandsCatalogue(
    pagination: number,
    filters?: { skuId: number; articleId: number }
  ) {
    const response = await client.get(
      `/api/articles/sku/findBrandsDistinctBrandsByOrganizationIdBrandCategoryArticleSkuWithStock?size=10&page=${pagination}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const brands = response.data.data.content;
    const { totalElements } = response.data.data;

    return { brands, totalElements };
  }

  static async getCategoriesByBrandCatalogue(
    brandId: number,
    pagination: number
  ) {
    const response = await client.get(
      `/api/articles/sku/findCategoriesDistinctBrandsByOrganizationIdBrandIdCategoryIdArticleIdSkuIdWithStock?size=10&page=${pagination}&brandId=${brandId}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const categories = response.data.data.content;
    const { totalElements } = response.data.data;

    return { categories, totalElements };
  }

  static async getCategoriesByBrandAndBranchOfficeId(
    entity: {
      branchOfficeId?: number;
      depositId?: number;
    },
    brandId: number,
    pagination: number
  ) {
    const { branchOfficeId, depositId } = entity;

    const response = await client.get(
      `/api/articles/sku/findCategoriesDistinctBrandsByBranchOfficeIdOrDepositAndBrandIdAndOrganizationId?size=10&page=${pagination}${
        branchOfficeId ? `&branchOfficeId=${branchOfficeId}` : ""
      }${depositId ? `&depositId=${depositId}` : ""}&brandId=${brandId}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const categories = response.data.data.content;
    const { totalElements } = response.data.data;

    return { categories, totalElements };
  }

  static async getSkusByBrandCategoryAndBranchOfficeId(
    entity: {
      branchOfficeId?: number;
      depositId?: number;
    },
    brandId: number,
    categoryId: number,
    pagination: number,
    unsupply?: number
  ) {
    const { branchOfficeId, depositId } = entity;

    const response = await client.get(
      `/api/articles/sku/findSkuDistinctBrandsByBranchOfficeIdOrDepositIdAndBrandIdAndCategoryIdAndOrganizationId?size=10&page=${pagination}${
        branchOfficeId ? `&branchOfficeId=${branchOfficeId}` : ""
      }${
        depositId ? `&depositId=${depositId}` : ""
      }&brandId=${brandId}&categoryId=${categoryId}${
        unsupply !== undefined ? `&unsupply=${unsupply}` : ""
      }`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const skus = response.data.data.content;
    const { totalElements } = response.data.data;

    return { skus, totalElements };
  }
  static async getSkusByBrandCategoryCatalogue(
    brandId: number,
    categoryId: number,
    pagination: number
  ) {
    const response = await client.get(
      `/api/articles/sku/findSkusDistinctBrandsByOrganizationIdBrandIdCategoryIdArticleIdSkuId?size=10&page=${pagination}&brandId=${brandId}&categoryId=${categoryId}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const skus = response.data.data.content;
    const { totalElements } = response.data.data;

    return { skus, totalElements };
  }

  static async getRepositionOrderRequests(
    pagination: number,
    branchOfficeDestinyId?: number
  ) {
    const response = await client.get(
      `/api/repositionOrders/findSkuArticleQuantityDestiny/?size=10&page=${pagination}${
        branchOfficeDestinyId
          ? `&branchOfficeToId=${branchOfficeDestinyId}`
          : ""
      }`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const data = response.data.data.content;
    const { totalElements } = response.data.data;
    return { data, totalElements };
  }

  static async getSkusWithStockFiltered(request: {
    pagination: number;
    articleId?: number;
    skuId?: number;
    branchOfficeId: number;
    businessUnitId: string;
  }) {
    const { pagination, articleId, branchOfficeId, skuId, businessUnitId } =
      request;
    const response = await client.get(
      `/api/articles/querySkuStockBranchOffice?&size=10&page=${pagination}${
        articleId ? `&articleId=${articleId}` : ""
      }${
        skuId ? `&skuId=${skuId}` : ""
      }&branchOfficeId=${branchOfficeId}&businessUnitId=${businessUnitId}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const skus = response.data.data.content;
    const { totalElements } = response.data.data;

    return { skus, totalElements };
  }

  static async getBranchOfficesByName(name: string, organizationId: string) {
    const response = await client.get(
      `/api/branchOffices/search/findByNameContainingAndOrganizationId?organizationId=${organizationId}&projection=branchOfficeProjectionIdName&name=${name}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const { branchOffices } = response.data._embedded;
    const { totalElements } = response.data.page;

    return { branchOffices, totalElements };
  }

  static async getBusinessUnitsByName(name: string, organizationId: string) {
    const response = await client.get(
      `/api/businessUnits/search/findByNameContainingAndOrganizationId?organizationId=${organizationId}&projection=businessUnitProjection&name=${name}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const { businessUnits } = response.data._embedded;
    const { totalElements } = response.data.page;

    return { businessUnits, totalElements };
  }

  static async getBranchOfficesByNameAndBU(
    name: string,
    organizationId: string,
    businessUnitId: string
  ) {
    const response = await client.get(
      `/api/branchOffices/search/findByNameContainingAndBusinessUnitIdAndOrganizationId?organizationId=${organizationId}&businessUnitId=${businessUnitId}&projection=branchOfficeProjectionIdName&name=${name}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    const { branchOffices } = response.data._embedded;

    return { branchOffices };
  }

  static async getCategoriesPossibleFathers(categoryId: number) {
    const response = await client.get(
      `/api/categories/getPossibleFathers/${categoryId}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response.data;
  }

  static async getArticlesByName(name: string, organizationId: string) {
    const response = await client.get(
      `/api/articles/search/findByNameContainingAndOrganizationId?organizationId=${organizationId}&projection=articleIdNameStockProjection&name=${name}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const { articles } = response.data._embedded;
    const { totalElements } = response.data.page;

    return { articles, totalElements };
  }

  static async getArticlesByOrganization(
    organizationId: string,
    pagination: number
  ) {
    const response = await client.get(
      `/api/articles/search/findByOrganizationId?organizationId=${organizationId}&projection=articleProjection&size=10&page=${pagination}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const articles = response.data._embedded;
    const { totalElements } = response.data.page;

    return { articles, totalElements };
  }

  static async getArticlesFiltered(
    organizationId: string,
    pagination: number,
    filters: {
      externalId?: string;
      id?: string;
      categoryId?: string;
      brandId?: number;
      articleName?: string;
      businessUnitId?: string;
    },
    onlyNameId?: boolean,
    projection?: string
  ) {
    const { id, externalId, categoryId, articleName, businessUnitId, brandId } =
      filters;
    const response = await client.get(
      `/api/articles/search/queryArticles?organizationId=${organizationId}&projection=${
        projection
          ? projection
          : onlyNameId
          ? "articleIdNameProjection"
          : "articleProjection"
      }&size=10&page=${pagination}${
        externalId ? `&externalId=${externalId}` : ""
      }${id ? `&id=${id}` : ""}${
        categoryId ? `&categoryId=${categoryId}` : ""
      }${articleName ? `&name=${articleName}` : ""}${
        brandId ? `&brandId=${brandId}` : ""
      }${businessUnitId ? `&businessUnitId=${businessUnitId}` : ""}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    const articles = response.data._embedded;
    const { totalElements } = response.data.page;

    return { articles, totalElements };
  }

  static async getSkusFiltered(
    organizationId: string,
    pagination: number,
    filters: {
      externalId?: string;
      id?: string;
      categoryId?: string;
      articleId?: string;
      articleName?: string;
      skuName?: string;
    }
  ) {
    const { id, externalId, categoryId, articleName, articleId, skuName } =
      filters;
    const response = await client.get(
      `/api/articles/querySkus?organizationId=${organizationId}&projection=articleProjection&size=10&page=${pagination}${
        externalId ? `&externalId=${externalId}` : ""
      }${id ? `&id=${id}` : ""}${
        categoryId ? `&categoryId=${categoryId}` : ""
      }${articleId ? `&articleId=${articleId}` : ""}${
        skuName ? `&name=${skuName}` : ""
      }${articleName ? `&articleName=${articleName}` : ""}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const skus = response.data.data.content;
    const { totalElements } = response.data.data;

    return { skus, totalElements };
  }

  static async getStockBreaks(
    pagination: number,
    filters: {
      articleId?: string;
      branchOfficeId?: string;
      minStock?: string;
      maxStock?: string;
    }
  ) {
    const { minStock, maxStock, articleId, branchOfficeId } = filters;
    const response = await client.get(
      `/api/repositionOrders/queryStockout?size=10&page=${pagination}${
        minStock ? `&minStock=${minStock}` : ""
      }${maxStock ? `&maxStock=${maxStock}` : ""}${
        articleId ? `&articleId=${articleId}` : ""
      }${branchOfficeId ? `&branchOfficeId=${branchOfficeId}` : ""}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const data = response.data.data.content;
    const { totalElements } = response.data.data;

    return { data, totalElements };
  }

  static async getStockAdjustments(
    stockId: string,
    type: "deposits" | "branchOffices",
    pagination: number
  ) {
    const response = await client.get(
      `/api/${type}/stockAdjustments/${stockId}?size=10&page=${pagination}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const data = response.data.data.content;
    const { totalElements } = response.data.data;

    return { data, totalElements };
  }

  static async getArticlesWithStock(
    pagination: number,
    order = "asc",
    articlesId?: string | null,
    categoriesId?: string | null
  ) {
    const response = await client.get(
      `/api/articles/getAllWithStockSort?page=${pagination}&size=20&sortStock=${order}${
        articlesId ? "&articlesIds=" + articlesId : ""
      }${categoriesId ? "&categoriesIds=" + categoriesId : ""}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const articles = response.data.data.content;
    const { totalElements } = response.data.data;

    return { articles, totalElements };
  }

  static async getArticlesAndSkuWithStock(pagination: number, order = "asc") {
    const response = await client.get(
      `/api/articles/getAllWithStockSortAndSkuStock?page=${
        pagination - 1
      }&size=20&sortStock=${order}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const articles = response.data.data.content;
    const { totalElements } = response.data.data;

    return { articles, totalElements };
  }

  static async getAllArticles() {
    const response = await client.get(`/api/articles/getAll`, {
      headers: {
        Authorization: token,
      },
    });

    return response.data;
  }

  static async createArticle(
    article: any,
    suppliers?: any,
    attributes?: any,
    skus?: any
  ) {
    const {
      name,
      externalId,
      description,
      categoryId,
      brandId,
      cost,
      defaultPrice,
      minimumPrice,
      maximumPrice,
      minimumMargin,
      currencyId,
      roundingTypeId,
      enable,
      businessUnitsIds,
      discontinued,
      originType,
      seasons,
    } = article;

    const response = await client.post(
      "/api/articles/",
      {
        name,
        externalId,
        description,
        categoryId,
        brandId,
        cost,
        defaultPrice,
        minimumPrice,
        maximumPrice,
        minimumMargin,
        currencyId,
        roundingTypeId,
        enable,
        discontinued,
        skus,
        attributes,
        suppliers,
        originType,
        businessUnitsIds: businessUnitsIds ?? [],
        seasonsIds: seasons ?? [],
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async deleteArticle(articleId: number) {
    const response = await client.delete(`/api/articles/${articleId}`, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async getSuppliersByOrganization(
    organizationId: string,
    pagination: number
  ) {
    const response = await client.get(
      `/api/suppliers/search/findByOrganizationId?organizationId=${organizationId}&projection=supplierProjection&size=10&page=${
        pagination - 1
      }`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const suppliers = response.data._embedded;
    const { totalElements } = response.data.page;

    return { suppliers, totalElements };
  }

  static async deleteSupplier(supplierId: number) {
    const response = await client.delete(`/api/suppliers/${supplierId}`, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async createSupplier(
    name: string,
    externalId: string,
    website: string,
    catalogueLink: string,
    businessUnitsIds: any,
    supplierPaymentMethodsIds: any,
    contacts?: any,
    articles?: any
  ) {
    const response = await client.post(
      "/api/suppliers/",
      {
        name,
        externalId,
        website,
        catalogueLink,
        businessUnitsIds,
        supplierPaymentMethodsIds,
        contacts,
        articles,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async getArticleById(articleId: string) {
    const response = await client.get(`/api/articles/${articleId}`, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  }

  static async getSupplierById(supplierId: string) {
    const response = await client.get(`/api/suppliers/${supplierId}`, {
      headers: {
        Authorization: token,
      },
    });

    return response.data;
  }

  static async getAllSuppliers() {
    const response = await client.get(`/api/suppliers/getAll`, {
      headers: {
        Authorization: token,
      },
    });

    return response.data;
  }

  static async addArticleToSupplier(
    articleId: number,
    principal: string,
    supplierId: number,
    purchaseUnitId: number,
    commercialTerm: any
  ) {
    const response = await client.post(
      `/api/suppliers/${supplierId}/articles`,
      {
        articleId,
        principal,
        purchaseUnitId,
        commercialTerm,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async addContactToSupplier(
    supplierId: number,
    name: string,
    role: string,
    phoneNumber: string,
    email: string
  ) {
    const formData = new FormData();
    formData.append("name", name);
    if (role) formData.append("role", role);
    if (phoneNumber) formData.append("phoneNumber", phoneNumber);
    if (email) formData.append("email", email);

    const response = await client.post(
      `/api/suppliers/${supplierId}/contacts`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  }

  static async getAllAttributes() {
    const response = await client.get(`/api/attributes/getAll`, {
      headers: {
        Authorization: token,
      },
    });

    return response.data;
  }

  static async getAttributesFiltered(name: string) {
    const response = await client.get(
      `/api/attributes/query?attribute=${name}&page=0&size=10`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response.data;
  }

  static async getAttributes(
    organizationId: string,
    pagination: number,
    filters: { attribute?: string; value?: string; businessUnitId?: string }
  ) {
    const { attribute, value, businessUnitId } = filters;

    const response = await client.get(
      `/api/attributes/search/findByOrganizationId?organizationId=${organizationId}&projection=atributeProjection&size=10&page=${
        pagination - 1
      }${attribute ? `&attribute=${attribute}` : ""}${
        businessUnitId ? `&businessUnitId=${businessUnitId}` : ""
      }${value ? `&value=${value}` : ""}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const attributes = response.data._embedded;
    const { totalElements } = response.data.page;

    return { attributes, totalElements };
  }

  static async deleteAttribute(attributeId: number) {
    const response = await client.delete(`/api/attributes/${attributeId}`, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async createAttribute(
    name: string,
    businessUnitsIds: any,
    enums: any
  ) {
    const formData = new FormData();
    formData.append("attribute", name);
    formData.append("businessUnitsIds", businessUnitsIds);
    formData.append("enums", enums);
    formData.append("isEnum", "true");

    const response = await client.post("/api/attributes/", formData, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async editAttribute(
    attributeId: number,
    name: string,
    businessUnitsIds: any,
    enums: any
  ) {
    const formData = new FormData();
    formData.append("attribute", name);
    formData.append("businessUnitsIds", businessUnitsIds);
    formData.append("isEnum", "true");
    formData.append("enums", enums);

    const response = await client.put(
      `/api/attributes/${attributeId}`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async editSupplier(
    supplierId: string,
    name: string,
    externalId: string,
    website: string | null,
    catalogueLink: string | null,
    businessUnitsIds: any,
    supplierPaymentMethodIds: any
  ) {
    const formData = new FormData();
    formData.append("name", name);
    if (website) formData.append("website", website);
    if (externalId) formData.append("externalId", externalId);
    if (catalogueLink) formData.append("catalogueLink", catalogueLink);
    formData.append("businessUnitsIds", businessUnitsIds);
    formData.append("supplierPaymentMethodIds", supplierPaymentMethodIds);

    const response = await client.put(
      `/api/suppliers/${supplierId}`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async editArticle(article: {
    articleId: string;
    name: string;
    externalId: string;
    description: string;
    originType: string;
    categoryId: number;
    brandId: number;
    currencyId: number;
    roundingTypeId: number;
    cost: number;
    defaultPrice: number;
    minimumPrice: number;
    maximumPrice: number;
    minimumMargin: number;
    enable: boolean;
    discontinued: boolean;
    businessUnitsIds: any;
  }) {
    const {
      articleId,
      name,
      externalId,
      description,
      categoryId,
      brandId,
      currencyId,
      roundingTypeId,
      cost,
      defaultPrice,
      minimumPrice,
      maximumPrice,
      minimumMargin,
      enable,
      discontinued,
      businessUnitsIds,
      originType,
    } = article;

    const formData = new FormData();
    formData.append("name", name);
    if (externalId) formData.append("externalId", externalId);
    if (description) formData.append("description", description);
    if (originType) formData.append("originType", originType);
    if (categoryId) formData.append("categoryId", categoryId?.toString());
    if (brandId) formData.append("brandId", brandId.toString());
    if (currencyId) formData.append("currencyId", currencyId?.toString());
    if (roundingTypeId)
      formData.append("roundingTypeId", roundingTypeId?.toString());
    if (cost) formData.append("cost", cost.toString());
    if (defaultPrice) formData.append("defaultPrice", defaultPrice.toString());
    if (minimumPrice) formData.append("minimumPrice", minimumPrice.toString());
    if (maximumPrice) formData.append("maximumPrice", maximumPrice.toString());
    if (minimumMargin)
      formData.append("minimumMargin", minimumMargin.toString());
    formData.append("enable", enable.toString());
    formData.append("discontinued", discontinued.toString());
    formData.append("businessUnitsIds", businessUnitsIds);

    const response = await client.put(`/api/articles/${articleId}`, formData, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async addAttributeToArticle(
    articleId: number,
    attributeId: number,
    enumId: number
  ) {
    const formData = new FormData();
    formData.append("attributeId", attributeId.toString());
    formData.append("idEnum", enumId.toString());

    const response = await client.post(
      `/api/articles/${articleId}/attributeValue`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async addSeasonToArticle(articleId: number, seasonId: number) {
    const formData = new FormData();
    formData.append("seasonId", seasonId.toString());

    const response = await client.post(
      `/api/articles/${articleId}/addSeason`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async addPriceToArticle(
    articleId: number,
    branchOfficeId: number,
    price: string,
    date: any
  ) {
    const formData = new FormData();
    formData.append("branchOfficeId", branchOfficeId.toString());
    formData.append("price", price);
    formData.append("date", date);

    const response = await client.post(
      `/api/articles/${articleId}/addPrice`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async addAttributeToSku(
    skuId: number,
    attributeId: number,
    enumId: number
  ) {
    const formData = new FormData();
    formData.append("attributeId", attributeId.toString());
    formData.append("idEnum", enumId.toString());

    const response = await client.post(
      `/api/articles/sku/${skuId}/attributeValue`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async deleteAttributeFromArticle(articleAttributeId: number) {
    const response = await client.delete(
      `/api/articles/attributeValue/${articleAttributeId}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async deleteSeasonFromArticle(articleSeasonId: number) {
    const response = await client.delete(
      `/api/articles/removeSeason/${articleSeasonId}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async deleteSkuAttributeFromArticle(skuAttributeId: number) {
    const response = await client.delete(
      `/api/articles/sku/attributeValue/${skuAttributeId}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async editAttributeFromArticle(
    articleAttributeId: number,
    value: string
  ) {
    const formData = new FormData();
    formData.append("value", value);

    const response = await client.put(
      `/api/articles/attributeValue/${articleAttributeId}`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async editSkuAttributeFromArticle(
    articleSkuAttributeId: number,
    value: string
  ) {
    const formData = new FormData();
    formData.append("value", value);

    const response = await client.put(
      `/api/articles/sku/attributeValue/${articleSkuAttributeId}`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async deleteContact(contactId: number) {
    const response = await client.delete(
      `/api/suppliers/contacts/${contactId}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async deleteArticleSupplier(articleSupplierId: number) {
    const response = await client.delete(
      `/api/suppliers/articles/${articleSupplierId}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async editContact(
    contactId: number,
    name: string,
    role: string,
    email: string,
    phoneNumber: string
  ) {
    const formData = new FormData();
    formData.append("name", name);
    if (role) formData.append("role", role);
    if (email) formData.append("email", email);
    if (phoneNumber) formData.append("phoneNumber", phoneNumber);

    const response = await client.put(
      `/api/suppliers/contacts/${contactId}`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async editArticleToSupplier(
    id: number,
    articleId: number,
    principal: string,
    purchaseUnitId: number,
    commercialTerm: any
  ) {
    const response = await client.put(
      `/api/suppliers/articles/${id}`,
      {
        articleId,
        principal,
        purchaseUnitId,
        commercialTerm,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  }

  static async createSkuInArticle(
    articleId: string,
    sku: any,
    attributes: any
  ) {
    const {
      name,
      externalId,
      enable,
      heightWithPackaging,
      lengthWithPackaging,
      widthWithPackaging,
      weightWithPackaging,
      height,
      length,
      width,
      weight,
      volumen,
      kit,
      salesUnit,
      storageUnit,
      minimumStock,
      shoeSize,
      clothSizeOp1,
      clothSizeOp2,
      colour,
      brand,
      cost,
      replenish,
    } = sku;

    const response = await client.post(
      `/api/articles/${articleId}/sku`,
      {
        name,
        externalId,
        enable,
        heightWithPackaging,
        lengthWithPackaging,
        widthWithPackaging,
        weightWithPackaging,
        height,
        length,
        width,
        weight,
        volumen,
        kit,
        salesUnit,
        storageUnit,
        minimumStock,
        shoeSize,
        clothSizeOp1,
        clothSizeOp2,
        colourId: colour,
        brand,
        attributes,
        cost,
        replenish,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  }

  static async deleteSkuFromArticle(articleSkuId: number) {
    const response = await client.delete(`/api/articles/sku/${articleSkuId}`, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async editSkuFromArticle(
    articleSkuId: number,
    name: string,
    enable: string,
    heightWithPackaging: number,
    lengthWithPackaging: number,
    widthWithPackaging: number,
    weightWithPackaging: number,
    height: number,
    length: number,
    width: number,
    weight: number,
    volumen: number,
    kit: boolean,
    salesUnit: string,
    storageUnit: string,
    shoeSize: number,
    clothSizeOp1: number,
    clothSizeOp2: string,
    colour: number,
    minimumStock: number,
    externalId: string,
    cost: number,
    replenish: boolean
  ) {
    console.log("editoo", replenish);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("enable", enable.toString());
    if (heightWithPackaging)
      formData.append("heightWithPackaging", heightWithPackaging.toString());
    if (lengthWithPackaging)
      formData.append("lengthWithPackaging", lengthWithPackaging.toString());
    if (widthWithPackaging)
      formData.append("widthWithPackaging", widthWithPackaging.toString());
    if (weightWithPackaging)
      formData.append("weightWithPackaging", weightWithPackaging.toString());
    if (height) formData.append("height", height.toString());
    if (length) formData.append("length", length.toString());
    if (width) formData.append("width", width.toString());
    if (weight) formData.append("weight", weight.toString());
    if (volumen) formData.append("volumen", volumen.toString());
    formData.append("kit", kit.toString());
    formData.append("replenish", replenish.toString());
    if (salesUnit) formData.append("salesUnit", salesUnit);
    if (storageUnit) formData.append("storageUnit", storageUnit);
    if (shoeSize) formData.append("shoeSize", shoeSize.toString());
    if (clothSizeOp1) formData.append("clothSizeOp1", clothSizeOp1.toString());
    if (clothSizeOp2) formData.append("clothSizeOp2", clothSizeOp2);
    if (colour) formData.append("colourId", colour.toString());
    if (externalId) formData.append("externalId", externalId);
    if (cost) formData.append("cost", cost.toString());
    if (minimumStock) formData.append("minimumStock", minimumStock.toString());

    const response = await client.put(
      `/api/articles/sku/${articleSkuId}`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async editSupplierToArticle(
    id: number,
    supplierId: number,
    principal: string,
    purchaseUnitId: number,
    commercialTerm: any
  ) {
    const response = await client.put(
      `/api/articles/suppliers/${id}`,
      {
        supplierId,
        principal,
        purchaseUnitId,
        commercialTerm,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  }

  static async getBusinessUnitById(businessUnitId: string) {
    const response = await client.get(`/api/businessUnits/${businessUnitId}`, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  }

  static async getPricingTypes(organizationId: string, pagination: number) {
    const response = await client.get(
      `/api/pricingTypes/search/findByOrganizationId?organizationId=${organizationId}&projection=pricingTypeProjection&size=10&page=${
        pagination - 1
      }`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const pricingTypes = response.data._embedded;
    const { totalElements } = response.data.page;

    return { pricingTypes, totalElements };
  }

  static async createPricingType(code: string, description: string) {
    const formData = new FormData();
    formData.append("code", code);
    if (description) formData.append("description", description);

    const response = await client.post("/api/pricingTypes/", formData, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async deletePricingType(pricingTypeId: number) {
    const response = await client.delete(`/api/pricingTypes/${pricingTypeId}`, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async editPricingType(
    pricingTypeId: number,
    code: string,
    description: string
  ) {
    const formData = new FormData();
    formData.append("code", code);
    if (description) formData.append("description", description);

    const response = await client.put(
      `/api/pricingTypes/${pricingTypeId}`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async getPromotionTypes(organizationId: string, pagination: number) {
    const response = await client.get(
      `/api/promotionTypes/search/findByOrganizationId?organizationId=${organizationId}&projection=promotionTypeProjection&size=10&page=${
        pagination - 1
      }`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const promotionTypes = response.data._embedded;
    const { totalElements } = response.data.page;

    return { promotionTypes, totalElements };
  }

  static async createPromotionType(code: string, name: string) {
    const formData = new FormData();
    formData.append("code", code);
    if (name) formData.append("name", name);

    const response = await client.post("/api/promotionTypes/", formData, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async deletePromotionType(promotionTypeId: number) {
    const response = await client.delete(
      `/api/promotionTypes/${promotionTypeId}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async editPromotionType(
    promotionTypeId: number,
    code: string,
    name: string
  ) {
    const formData = new FormData();
    formData.append("code", code);
    if (name) formData.append("name", name);

    const response = await client.put(
      `/api/promotionTypes/${promotionTypeId}`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async deleteBranchOffice(branchOfficeId: number) {
    const response = await client.delete(
      `/api/branchOffices/${branchOfficeId}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async getBranchOfficeById(branchOfficeId: string) {
    const response = await client.get(`/api/branchOffices/${branchOfficeId}`, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  }

  static async getBranchOfficeSkusById(
    branchOfficeId: string,
    pagination: number,
    organizationId: string,
    skuName: string
  ) {
    const response = await client.get(
      `/api/skuBranchOffices/search/findByBranchOfficeIdAndBranchOfficeOrganizationIdAndSkuNameContaining?branchOfficeId=${branchOfficeId}&organizationId=${organizationId}&page=${pagination}&size=10&skuName=${skuName}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const skus = response.data._embedded.skuBranchOffices;
    const { totalElements } = response.data.page;
    return { skus, totalElements };
  }

  static async getDepositsSkusById(
    depositId: string,
    pagination: number,
    organizationId: string,
    skuName: string
  ) {
    const response = await client.get(
      `/api/skuBranchOffices/search/findByDepositIdAndDepositOrganizationIdAndSkuNameContaining?depositId=${depositId}&organizationId=${organizationId}&page=${pagination}&size=10&skuName=${skuName}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const skus = response.data._embedded.skuBranchOffices;
    const { totalElements } = response.data.page;
    return { skus, totalElements };
  }

  static async editBranchOffice(branchOffice: any) {
    const response = await client.put(
      `/api/branchOffices/${branchOffice.id}`,
      branchOffice,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async getAllBranchOfficesTypes() {
    const response = await client.get(`/api/branchOffices/branchOfficeTypes`, {
      headers: {
        Authorization: token,
      },
    });

    return response.data;
  }

  static async createBranchOffice(branchOffice: any) {
    const response = await client.post(`/api/branchOffices/`, branchOffice, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async getAcceptedPaymentMethods(
    organizationId: string,
    pagination: number,
    businessUnitId?: string
  ) {
    const response = await client.get(
      `/api/acceptedPaymentMethod/search/findByOrganizationIdAndBusinessUnitId?organizationId=${organizationId}&projection=acceptedPaymentMethodProjection&size=10&page=${
        pagination - 1
      }${businessUnitId ? `&businessUnitId=${businessUnitId}` : ""}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const acceptedPaymentMethods = response.data._embedded;
    const { totalElements } = response.data.page;

    return { acceptedPaymentMethods, totalElements };
  }

  static async getAcceptedPaymentMethodById(paymentMethodId: string) {
    const response = await client.get(
      `/api/acceptedPaymentMethod/${paymentMethodId}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data;
  }

  static async getAllAcceptedPaymentMethods() {
    const response = await client.get(`/api/acceptedPaymentMethod/getAll`, {
      headers: {
        Authorization: token,
      },
    });

    return response.data;
  }

  static async getAcceptedPaymentMethodsTypes() {
    const response = await client.get(`/api/acceptedPaymentMethod/types`, {
      headers: {
        Authorization: token,
      },
    });
    const acceptedPaymentMethodsTypes = response.data;

    return acceptedPaymentMethodsTypes;
  }

  static async getPaymentMethodsByBusinessUnitId(businessUnitId: number) {
    const response = await client.get(
      `/api/acceptedPaymentMethod/getByBussinesUnitId/${businessUnitId}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    const acceptedPaymentMethods = response.data;

    return acceptedPaymentMethods;
  }

  static async createAcceptedPaymentMethod(acceptedPaymentMethod: {
    name: string;
    externalId: string;
    typeId: number;
    feeRangeMin: number;
    feeRangeMax: number;
    businessUnitsIds: any;
  }) {
    const response = await client.post(
      "/api/acceptedPaymentMethod/",
      acceptedPaymentMethod,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async editAcceptedPaymentMethod(
    acceptedPaymentMethodId: number,
    acceptedPaymentMethod: {
      name: string;
      externalId: string;
      typeId: number;
      feeRangeMin: number;
      feeRangeMax: number;
      businessUnitsIds: any;
    }
  ) {
    const response = await client.put(
      `/api/acceptedPaymentMethod/${acceptedPaymentMethodId}`,
      acceptedPaymentMethod,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async deleteAcceptedPaymentMethod(acceptedPaymentMethodId: number) {
    const response = await client.delete(
      `/api/acceptedPaymentMethod/${acceptedPaymentMethodId}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async deleteAcceptedPaymentMethodFromBusinessUnit(
    acceptedPaymentMethodId: number,
    businessUnitId: number
  ) {
    const response = await client.delete(
      `/api/businessUnits/${businessUnitId}/removeAcceptedPaymentMethod/${acceptedPaymentMethodId}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async addAcceptedPaymentMethodToBusinessUnit(
    businessUnitId: number,
    acceptedPaymentMethodId: number
  ) {
    const response = await client.post(
      `/api/businessUnits/${businessUnitId}/addAcceptedPaymentMethod/${acceptedPaymentMethodId}`,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async getCompetitors(organizationId: string, pagination: number) {
    const response = await client.get(
      `/api/competitors/search/findByOrganizationId?organizationId=${organizationId}&projection=competitorProjection&size=10&page=${
        pagination - 1
      }`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const competitors = response.data._embedded;
    const { totalElements } = response.data.page;

    return { competitors, totalElements };
  }

  static async getCompetitorById(competitorId: string) {
    const response = await client.get(`/api/competitors/${competitorId}`, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  }

  static async deleteCompetitor(competitorId: number) {
    const response = await client.delete(`/api/competitors/${competitorId}`, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async createCompetitor(
    name: string,
    externalId: string,
    website: string,
    articlesIds: any
  ) {
    const formData = new FormData();
    formData.append("name", name);
    if (externalId) formData.append("externalId", externalId);
    if (website) formData.append("website", website);
    formData.append("articlesIds", articlesIds);

    const response = await client.post("/api/competitors/", formData, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async editCompetitor(
    competitorId: string,
    name: string,
    externalId: string,
    website: string,
    articleIds: any
  ) {
    const formData = new FormData();
    formData.append("name", name);
    if (externalId) formData.append("externalId", externalId);
    if (website) formData.append("website", website);
    formData.append("articlesIds", articleIds);

    const response = await client.put(
      `/api/competitors/${competitorId}`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async createSalePoint(
    branchOfficeId: number,
    name: string,
    acceptedPaymentMethodsIds: any
  ) {
    const response = await client.post(
      "/api/branchOffices/salePoint",
      { branchOfficeId, name, acceptedPaymentMethodsIds },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async deleteSalePoint(salePointId: number) {
    const response = await client.delete(
      `/api/branchOffices/salePoint/${salePointId}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async editSalePoint(
    salePointId: number,
    name: string,
    acceptedPaymentMethodsIds: any
  ) {
    const response = await client.put(
      `/api/branchOffices/salePoint/${salePointId}`,
      { name, acceptedPaymentMethodsIds },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async getDeposits(
    organizationId: string,
    pagination: number,
    businessUnitId: string,
    filters: { name: string; externalId: string }
  ) {
    const { name, externalId } = filters;
    const response = await client.get(
      `/api/deposits/search/findByOrganizationIdAndBusinessUnitId?organizationId=${organizationId}&businessUnitId=${businessUnitId}&projection=depositProjection&size=10&page=${pagination}${
        name ? `&name=${name}` : ""
      }${externalId ? `&externalId=${externalId}` : ""}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const deposits = response.data._embedded;
    const { totalElements } = response.data.page;

    return { deposits, totalElements };
  }

  static async createDeposit(deposit: {
    name: string;
    capacity: number;
    externalId: string;
    branchOfficesIds: any;
    address: string;
    businessUnitsIds: any;
    categoriesIds: any;
    capacityInUnits: number;
    capacityDispatchUnits: number;
    dispatchFrequency: number;
    capacityReception: number;
    storageCostPerUnit: number;
  }) {
    const response = await client.post(
      "/api/deposits/",
      {
        ...deposit,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async deleteDeposit(depositId: number) {
    const response = await client.delete(`/api/deposits/${depositId}`, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async editDeposit(
    depositId: number,
    deposit: {
      name: string;
      capacity: number;
      externalId: string;
      branchOfficesIds: any;
      address: string;
      businessUnitsIds: any;
      categoriesIds: any;
      capacityInUnits: number;
      capacityDispatchUnits: number;
      dispatchFrequency: number;
      capacityReception: number;
      storageCostPerUnit: number;
    }
  ) {
    const response = await client.put(
      `/api/deposits/${depositId}`,
      {
        ...deposit,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async getAllBranchOffices(organizationId: string) {
    const response = await client.get(
      `/api/branchOffices/search/findByOrganizationId?organizationId=${organizationId}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data._embedded;
  }

  static async getAllBranchOfficesByBu(businessUnitIds: any) {
    let queryParam = "";
    for (let i = 0; i < businessUnitIds.length; i++) {
      queryParam += `businessUnitIds=${businessUnitIds[i]}&`;
    }

    const response = await client.get(
      `/api/branchOffices/getByBusinessUnitIds?${queryParam}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data.data;
  }

  static async getBranchOffices(organizationId: string, pagination: number) {
    const response = await client.get(
      `/api/branchOffices/search/findByOrganizationIdPage?organizationId=${organizationId}&projection=branchOfficeProjectionIdName&size=10&page=${
        pagination - 1
      }`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const branchOffices = response.data._embedded;
    const { totalElements } = response.data.page;

    return { branchOffices, totalElements };
  }

  static async getDepositById(depositId: string) {
    const response = await client.get(`/api/deposits/${depositId}`, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  }

  static async deleteArticleStock(artcileStockId: number, type: string) {
    const response = await client.delete(
      `/api/${type}/deleteArticle/${artcileStockId}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async addArticleStock(
    stock: number,
    skuId: number,
    type: string,
    placeId: number
  ) {
    const formData = new FormData();
    formData.append("stock", stock.toString());

    const response = await client.post(
      `/api/${type}/${placeId}/addSku/${skuId}`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async editArticleStock(
    articleStockId: number,
    stock: string,
    type: string
  ) {
    const formData = new FormData();
    formData.append("stock", stock);

    const response = await client.put(
      `/api/${type}/editStock/${articleStockId}`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async getPurchaseOrdersByOrganization(
    pagination: number,
    supplierId?: number | null,
    status?: string | null,
    articlesId?: string | null,
    startDate?: string | null,
    endDate?: string | null
  ) {
    const response = await client.get(
      `/api/purchaseOrders/queryRequests?&size=10&page=${pagination}${
        supplierId ? "&supplierId=" + supplierId : ""
      }${status ? "&status=" + status : ""}${
        articlesId ? "&articlesIds=" + articlesId : ""
      }${startDate ? "&startEstimatedDeliveryDate=" + startDate : ""}${
        endDate ? "&endEstimatedDeliveryDate=" + endDate : ""
      }`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    const purchaseOrders = response.data.data.content;
    const { totalElements } = response.data.data;

    return { purchaseOrders, totalElements };
  }

  static async createPurchaseOrder(
    body: any,
    preview: boolean,
    businessUnitId?: string
  ) {
    const response = await client.post(
      `/api/purchaseOrders/${preview ? "preview" : ""}${businessUnitId ?? ""}`,
      body,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async getPurchaseOrderStatics() {
    const response = await client.get(`/api/purchaseOrders/statistics`, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async getAllDeposits() {
    const response = await client.get(`/api/deposits/getAll`, {
      headers: {
        Authorization: token,
      },
    });
    return response;
  }

  static async deletePurchaseOrder(purchaseOrderId: number) {
    const response = await client.delete(
      `/api/purchaseOrders/${purchaseOrderId}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async getPurchaseOrderById(purchaseOrderId: string) {
    const response = await client.get(
      `/api/purchaseOrders/${purchaseOrderId}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response.data;
  }

  static async markReceivedOrder(body: any) {
    const response = await client.post(
      `/api/purchaseOrders/markReceived`,
      body,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async markReceivedRepositionOrder(body: any) {
    const response = await client.post(
      `/api/repositionOrders/markReceived`,
      body,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async markSentArticlesRepositionOrder(
    repositionOrderSkuIds: any,
    arriveDate: string
  ) {
    const formData = new FormData();
    formData.append("estimatedDate", arriveDate);
    formData.append("repositionOrderSkuId", repositionOrderSkuIds);
    const response = await client.post(
      `/api/repositionOrders/markAsSent`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async editPurchaseOrderStatus(
    purchaseOrderId: number,
    status: string,
    notCompletedReason?: string
  ) {
    const formData = new FormData();
    formData.append("status", status);
    if (notCompletedReason)
      formData.append("notCompletedReason", notCompletedReason);

    const response = await client.put(
      `/api/purchaseOrders/changeStatus/${purchaseOrderId}`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async getRepositionOrdersByOrganization(query: {
    pagination: number;
    status?: string | null;
    startCreatedDate?: string | null;
    endCreatedDate?: string | null;
    depositTo?: number | null;
    branchOfficeTo?: number | null;
    depositFrom?: number | null;
    branchOfficeFrom?: number | null;
    orderBy?: string;
    businessUnitId?: string;
    articlesIds?: number[];
    skuId?: string | null;
  }) {
    const {
      pagination,
      status,
      startCreatedDate,
      endCreatedDate,
      depositTo,
      branchOfficeTo,
      depositFrom,
      branchOfficeFrom,
      orderBy,
      businessUnitId,
      skuId,
      articlesIds,
    } = query;

    const response = await client.get(
      `/api/repositionOrders/queryRequests?&size=10&page=${pagination}${
        status ? "&status=" + status : ""
      }${startCreatedDate ? "&startCreatedDate=" + startCreatedDate : ""}${
        endCreatedDate ? "&endCreatedDate=" + endCreatedDate : ""
      }${depositTo ? "&depositToId=" + depositTo : ""}${
        branchOfficeTo ? "&branchOfficeToId=" + branchOfficeTo : ""
      }${depositFrom ? "&depositFromId=" + depositFrom : ""}
      ${branchOfficeFrom ? "&branchOfficeFromId=" + branchOfficeFrom : ""}${
        businessUnitId ? "&businessUnitId=" + businessUnitId : ""
      }${articlesIds ? "&articlesIds=" + articlesIds : ""}${
        skuId ? "&skuId=" + skuId : ""
      }
      ${orderBy ? "&orderBy=" + orderBy : ""}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    const repositionOrders = response.data.data.content;
    const { totalElements } = response.data.data;

    return { repositionOrders, totalElements };
  }

  static async deleteRepositionOrder(repositionOrderId: number) {
    const response = await client.delete(
      `/api/repositionOrders/${repositionOrderId}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async editRepositionOrderStatus(
    repositionOrderId: number,
    status: string
  ) {
    const formData = new FormData();
    formData.append("status", status);

    const response = await client.put(
      `/api/repositionOrders/changeStatus/${repositionOrderId}`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async getRepositionOrderStatics() {
    const response = await client.get(`/api/repositionOrders/statistics`, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async getRepositionOrderById(repositionOrderId: string) {
    const response = await client.get(
      `/api/repositionOrders/${repositionOrderId}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response.data;
  }

  static async createRepositionOrder(
    body: any,
    preview: boolean,
    businessUnitId?: string
  ) {
    const response = await client.post(
      `/api/repositionOrders/${preview ? "preview" : ""}${
        businessUnitId ?? ""
      }`,
      body,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async createRepositionOrderDraft(
    orders: {
      skuId: number;
      branchOfficeOrDepositFrom: string;
      branchOfficeOrDepositFromId: number;
      branchOfficeOrDepositTo: string;
      branchOfficeOrDepositToId: number;
      quantity: number;
    }[],
    businessUnitId: string
  ) {
    const response = await client.post(
      `/api/repositionOrders/draft/${businessUnitId}/${orders[0].branchOfficeOrDepositToId}`,
      orders,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async createPurchaseOrderDraft(
    orders: {
      skuId: number;
      branchOfficeOrDeposit: string;
      branchOfficeOrDepositId: number;
      supplierId: string;
      quantity: number;
    }[],
    businessUnitId: string
  ) {
    const response = await client.post(
      `/api/purchaseOrders/draft/${businessUnitId}`,
      orders,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async getRepositionOrderDraft(businessUnitId: string) {
    const response = await client.get(
      `/api/repositionOrders/draft/${businessUnitId}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response.data;
  }
  static async getPurchaseOrderDraft(businessUnitId: string) {
    const response = await client.get(
      `/api/purchaseOrders/draft/${businessUnitId}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response.data;
  }

  static async getClients(organizationId: string, pagination: number) {
    const response = await client.get(
      `/api/clients/search/findByOrganizationId?organizationId=${organizationId}&projection=clientProjection&size=10&page=${
        pagination - 1
      }`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const clients = response.data._embedded;
    const { totalElements } = response.data.page;

    return { clients, totalElements };
  }

  static async deleteClient(clientId: number) {
    const response = await client.delete(`/api/clients/${clientId}`, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async createClient(clientInfo: {
    name: string;
    lastname: string;
    birthdate: string;
    telephone: string;
    cellphone: string;
    documentType: string;
    documentNumber: string;
    country: string;
    city: string;
    locality: string;
    address: string;
    email: string;
    sex: string;
    fidelity: string;
  }) {
    const {
      name,
      lastname,
      birthdate,
      telephone,
      cellphone,
      documentType,
      documentNumber,
      country,
      city,
      locality,
      address,
      email,
      sex,
      fidelity,
    } = clientInfo;
    const formData = new FormData();
    formData.append("name", name);
    if (lastname) formData.append("lastname", lastname);
    if (birthdate) formData.append("birthdate", birthdate);
    if (telephone) formData.append("telephone", telephone);
    if (cellphone) formData.append("cellphone", cellphone);
    if (documentType) formData.append("idTypeId", documentType);
    if (documentNumber) formData.append("idNumber", documentNumber);
    if (country) formData.append("countryId", country);
    if (city) formData.append("stateId", city);
    if (locality) formData.append("locality", locality);
    if (address) formData.append("address", address);
    if (email) formData.append("email", email);
    if (sex) formData.append("sex", sex);
    if (fidelity) formData.append("fidelity", fidelity);

    const response = await client.post("/api/clients/", formData, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async editClient(clientInfo: {
    id: number;
    name: string;
    lastname: string;
    birthdate: string;
    telephone: string;
    cellphone: string;
    documentType: string;
    documentNumber: string;
    country: string;
    city: string;
    locality: string;
    address: string;
    email: string;
    sex: string;
    fidelity: string;
  }) {
    const {
      id,
      name,
      lastname,
      birthdate,
      telephone,
      cellphone,
      documentType,
      documentNumber,
      country,
      city,
      locality,
      address,
      email,
      sex,
      fidelity,
    } = clientInfo;
    const formData = new FormData();
    formData.append("name", name);
    if (lastname) formData.append("lastname", lastname);
    if (birthdate) formData.append("birthdate", birthdate);
    if (telephone) formData.append("telephone", telephone);
    if (cellphone) formData.append("cellphone", cellphone);
    if (documentType) formData.append("idTypeId", documentType);
    if (documentNumber) formData.append("idNumber", documentNumber);
    if (country) formData.append("countryId", country);
    if (city) formData.append("stateId", city);
    if (locality) formData.append("locality", locality);
    if (address) formData.append("address", address);
    if (email) formData.append("email", email);
    if (sex) formData.append("sex", sex);
    if (fidelity) formData.append("fidelity", fidelity);

    const response = await client.put(`/api/clients/${id}`, formData, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async getClientById(clientId: string) {
    const response = await client.get(`/api/clients/${clientId}`, {
      headers: {
        Authorization: token,
      },
    });

    return response.data;
  }

  static async getAllClients() {
    const response = await client.get(`/api/clients/getAll`, {
      headers: {
        Authorization: token,
      },
    });

    return response.data;
  }

  static async getClientSegments(organizationId: string, pagination: number) {
    const response = await client.get(
      `/api/clients/clientSegmentsWithSize?page=${pagination}&size=10`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    const clientSegments = response.data.data.content;
    const { totalElements } = response.data.data;

    return { clientSegments, totalElements };
  }

  static async deleteClientSegment(clientSegmentId: number) {
    const response = await client.delete(
      `/api/clients/segments/${clientSegmentId}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async createClientSegment(
    name: string,
    description: string,
    minAge: number,
    maxAge: number,
    sex: string,
    countryIds: any,
    stateIds: any,
    articlesIds: any,
    brandsIds: any,
    categoriesIds: any,
    branchOfficesIds: any
  ) {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    if (minAge) formData.append("minAge", minAge.toString());
    if (maxAge) formData.append("maxAge", maxAge.toString());
    formData.append("countryIds", countryIds);
    formData.append("stateIds", stateIds);
    formData.append("articlesIds", articlesIds);
    formData.append("brandsIds", brandsIds);
    formData.append("categoriesIds", categoriesIds);
    formData.append("branchOfficesIds", branchOfficesIds);
    if (sex) formData.append("sex", sex);
    // formData.append("type", "MANUAL");

    const response = await client.post("/api/clients/segments/", formData, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async editClientSegment(
    id: number,
    name: string,
    description: string,
    minAge: number,
    maxAge: number,
    sex: string,
    countryIds: any,
    stateIds: any,
    articlesIds: any,
    brandsIds: any,
    categoriesIds: any,
    branchOfficesIds: any
  ) {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    if (minAge) formData.append("minAge", minAge?.toString());
    if (maxAge) formData.append("maxAge", maxAge?.toString());
    if (sex) formData.append("sex", sex);
    formData.append("countryIds", countryIds);
    formData.append("stateIds", stateIds);
    formData.append("articlesIds", articlesIds);
    formData.append("brandsIds", brandsIds);
    formData.append("categoriesIds", categoriesIds);
    formData.append("branchOfficesIds", branchOfficesIds);

    const response = await client.put(`/api/clients/segments/${id}`, formData, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async cloneClientSegment(clientSegmentId: number) {
    const formData = new FormData();
    formData.append("copyText", "copia");

    const response = await client.post(
      `/api/clients/segments/clone/${clientSegmentId}`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async getClientSegmentById(clientSegmentId: string) {
    const response = await client.get(
      `/api/clients/segments/${clientSegmentId}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response.data;
  }

  static async getClientsBySegmentId(
    clientSegmentId: string,
    pagination: number
  ) {
    const response = await client.get(
      `/api/clients/bySegment/${clientSegmentId}?page=${pagination}&size=10`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response.data;
  }

  static async addClientsToClientSegment(
    clientSegmentId: number,
    clientIds: any
  ) {
    const formData = new FormData();
    formData.append("clientIds", clientIds);

    const response = await client.put(
      `/api/clients/segments/${clientSegmentId}/addClient`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  }

  static async deleteClientFromClientSegment(
    clientSegmentId: number,
    clientId: any
  ) {
    const formData = new FormData();
    formData.append("clientId", clientId);

    const response = await client.put(
      `/api/clients/segments/${clientSegmentId}/deleteClient`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  }

  static async getPromotions(organizationId: string, pagination: number) {
    const response = await client.get(
      `/api/promotions/search/findByOrganizationId?organizationId=${organizationId}&projection=promotionProjection&size=10&page=${
        pagination - 1
      }`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const promotions = response.data._embedded;
    const { totalElements } = response.data.page;

    return { promotions, totalElements };
  }

  static async getPromotionsNoDate(organizationId: string, pagination: number) {
    const response = await client.get(
      `/api/promotions/search/findStartDateIsNull?organizationId=${organizationId}&projection=promotionProjection&size=10&page=${
        pagination - 1
      }`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const promotions = response.data._embedded;
    const { totalElements } = response.data.page;

    return { promotions, totalElements };
  }

  static async getPromotionsByMonth(month: string) {
    const response = await client.get(
      `/api/promotions/monthByPromotion/${month}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data;
  }

  static async deletePromotion(promotionId: number) {
    const response = await client.delete(`/api/promotions/${promotionId}`, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async getPromotionById(promotionId: string) {
    const response = await client.get(`/api/promotions/${promotionId}`, {
      headers: {
        Authorization: token,
      },
    });

    return response.data;
  }

  static async createPromotionBuyTogether(
    name: string,
    description: string,
    recurrence: any,
    startDate: any,
    endDate: any,
    discountFirstList: string,
    discountSecondList: string,
    skuIdFirstList: any,
    skuIdSecondList: any,
    branchOfficeIds: any,
    clientSegmentIds: any,
    categoryIds: any,
    brandIds: any,
    acceptedPaymentMethodIds: any,
    bines: any,
    binMin: number,
    binMax: number,
    minimumTicket: number,
    maximumTicket: number,
    quantityApplications: number,
    quantityPerArticle: number,
    quantityPerBranchOffice: number,
    quantityPerClient: number,
    accumulateOtherPromotions: boolean,
    distributeLines: boolean
  ) {
    const body = {
      promotion: {
        name,
        description,
        recurrence,
        startDate,
        endDate,
        branchOfficeIds,
        clientSegmentIds,
        categoryIds,
        brandIds,
        acceptedPaymentMethodIds,
        bines,
        binMin,
        binMax,
        minimumTicket,
        maximumTicket,
        quantityApplications,
        quantityPerArticle,
        quantityPerBranchOffice,
        quantityPerClient,
        accumulateOtherPromotions,
        distributeLines,
        type: "BUY_TOGETHER",
      },
      discountFirstList,
      discountSecondList,
      skuIdFirstList,
      skuIdSecondList,
    };

    const response = await client.post("/api/promotions/buyTogether", body, {
      headers: {
        Authorization: token,
      },
    });
    return response;
  }

  static async createPromotionMxN(
    name: string,
    description: string,
    recurrence: any,
    startDate: any,
    endDate: any,
    skuIds: any,
    quantityForPromotion: string,
    quantityWithDiscount: string,
    discountPercent: string,
    allProducts: boolean,
    highPrice: boolean,
    forTotalArticles: boolean,
    branchOfficeIds: any,
    clientSegmentIds: any,
    categoryIds: any,
    brandIds: any,
    acceptedPaymentMethodIds: any,
    bines: any,
    binMin: number,
    binMax: number,
    minimumTicket: number,
    maximumTicket: number,
    quantityApplications: number,
    quantityPerArticle: number,
    quantityPerBranchOffice: number,
    quantityPerClient: number,
    accumulateOtherPromotions: boolean,
    distributeLines: boolean
  ) {
    const body = {
      promotion: {
        name,
        description,
        recurrence,
        startDate,
        endDate,
        branchOfficeIds,
        clientSegmentIds,
        categoryIds,
        brandIds,
        acceptedPaymentMethodIds,
        bines,
        binMin,
        binMax,
        minimumTicket,
        maximumTicket,
        quantityApplications,
        quantityPerArticle,
        quantityPerBranchOffice,
        quantityPerClient,
        accumulateOtherPromotions,
        distributeLines,
        type: "BUY_TOGETHER",
      },
      quantityForPromotion,
      quantityWithDiscount,
      discountPercent,
      allProducts,
      highPrice,
      forTotalArticles,
      skuIds,
    };

    const response = await client.post("/api/promotions/mxn", body, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async createPromotionRegular(promoInfo: {
    name: string;
    description: string;
    recurrence: any;
    startDate: any;
    endDate: any;
    regularPromotionType: string;
    branchOfficeIds: any;
    clientSegmentIds: any;
    categoryIds: any;
    brandIds: any;
    acceptedPaymentMethodIds: any;
    bines: any;
    binMin: number;
    binMax: number;
    skuIds: any;
    minimumTicket: number;
    maximumTicket: number;
    quantityApplications: number;
    quantityPerArticle: number;
    quantityPerBranchOffice: number;
    quantityPerClient: number;
    accumulateOtherPromotions: boolean;
    distributeLines: boolean;
    discountValue?: string;
    discountPercentage?: any;
    giftId?: string;
  }) {
    const {
      name,
      description,
      recurrence,
      startDate,
      endDate,
      regularPromotionType,
      branchOfficeIds,
      clientSegmentIds,
      categoryIds,
      brandIds,
      acceptedPaymentMethodIds,
      bines,
      binMin,
      binMax,
      skuIds,
      minimumTicket,
      maximumTicket,
      quantityApplications,
      quantityPerArticle,
      quantityPerBranchOffice,
      quantityPerClient,
      accumulateOtherPromotions,
      distributeLines,
      discountValue,
      discountPercentage,
      giftId,
    } = promoInfo;

    const body = {
      promotion: {
        name,
        description,
        recurrence,
        startDate,
        endDate,
        branchOfficeIds,
        clientSegmentIds,
        categoryIds,
        brandIds,
        acceptedPaymentMethodIds,
        bines,
        binMin,
        binMax,
        minimumTicket,
        maximumTicket,
        quantityApplications,
        quantityPerArticle,
        quantityPerBranchOffice,
        quantityPerClient,
        accumulateOtherPromotions,
        distributeLines,
      },
      skuIds,
      regularPromotionType,
      giftId: giftId ?? null,
      discountPercentage: discountPercentage ?? null,
      discountValue: discountValue ?? null,
    };

    const response = await client.post("/api/promotions/regular", body, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async createPromotionBogo(
    name: string,
    description: string,
    recurrence: any,
    startDate: any,
    endDate: any,
    skuIds: any,
    discountType: string,
    giftId: string,
    branchOfficeIds: any,
    clientSegmentIds: any,
    categoryIds: any,
    brandIds: any,
    acceptedPaymentMethodIds: any,
    bines: any,
    binMin: number,
    binMax: number,
    minimumTicket: number,
    maximumTicket: number,
    quantityApplications: number,
    quantityPerArticle: number,
    quantityPerBranchOffice: number,
    quantityPerClient: number,
    accumulateOtherPromotions: boolean,
    distributeLines: boolean
  ) {
    const body = {
      promotion: {
        name,
        description,
        recurrence,
        startDate,
        endDate,
        branchOfficeIds,
        clientSegmentIds,
        categoryIds,
        brandIds,
        acceptedPaymentMethodIds,
        bines,
        binMin,
        binMax,
        minimumTicket,
        maximumTicket,
        quantityApplications,
        quantityPerArticle,
        quantityPerBranchOffice,
        quantityPerClient,
        accumulateOtherPromotions,
        distributeLines,
        type: "BOGO",
      },
      type: discountType,
      skuIds,
      giftId,
    };
    const response = await client.post("/api/promotions/buyOneGetOne", body, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async createPromotionProgressive(
    name: string,
    description: string,
    recurrence: any,
    startDate: any,
    endDate: any,
    skuIds: any,
    lines: any,
    branchOfficeIds: any,
    clientSegmentIds: any,
    categoryIds: any,
    brandIds: any,
    acceptedPaymentMethodIds: any,
    bines: any,
    binMin: number,
    binMax: number,
    minimumTicket: number,
    maximumTicket: number,
    quantityApplications: number,
    quantityPerArticle: number,
    quantityPerBranchOffice: number,
    quantityPerClient: number,
    accumulateOtherPromotions: boolean,
    distributeLines: boolean
  ) {
    const body = {
      promotion: {
        name,
        description,
        recurrence,
        startDate,
        endDate,
        branchOfficeIds,
        clientSegmentIds,
        categoryIds,
        brandIds,
        acceptedPaymentMethodIds,
        bines,
        binMin,
        binMax,
        minimumTicket,
        maximumTicket,
        quantityApplications,
        quantityPerArticle,
        quantityPerBranchOffice,
        quantityPerClient,
        accumulateOtherPromotions,
        distributeLines,
      },
      skuIds,
      lines,
    };
    const response = await client.post(
      "/api/promotions/progressiveDiscount",
      body,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async editPromotionRegular(
    id: number,
    promo: {
      promotion: {
        name: string;
        description: string;
        recurrence: any;
        startDate: any;
        endDate: any;
        branchOfficeIds: any;
        clientSegmentIds: any;
        categoryIds: any;
        brandIds: any;
        acceptedPaymentMethodIds: any;
        bines: any;
        binMin: number;
        binMax: number;
        minimumTicket: number;
        maximumTicket: number;
        quantityApplications: number;
        quantityPerArticle: number;
        quantityPerBranchOffice: number;
        quantityPerClient: number;
        accumulateOtherPromotions: boolean;
        distributeLines: boolean;
      };
      discountValue?: string;
      discountPercentage?: any;
      giftId?: string;
      regularPromotionType: string;
      skuIds: any;
    }
  ) {
    const response = await client.put(`/api/promotions/regular/${id}`, promo, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async editPromotionBuyTogether(
    id: number,
    promo: {
      promotion: {
        name: string;
        description: string;
        recurrence: any;
        startDate: any;
        endDate: any;
        branchOfficeIds: any;
        clientSegmentIds: any;
        categoryIds: any;
        brandIds: any;
        acceptedPaymentMethodIds: any;
        bines: any;
        binMin: number;
        binMax: number;
        minimumTicket: number;
        maximumTicket: number;
        quantityApplications: number;
        quantityPerArticle: number;
        quantityPerBranchOffice: number;
        quantityPerClient: number;
        accumulateOtherPromotions: boolean;
        distributeLines: boolean;
      };
      discountFirstList: number;
      discountSecondList: number;
      skuIdFirstList: any;
      skuIdSecondList: any;
    }
  ) {
    const response = await client.put(
      `/api/promotions/buyTogether/${id}`,
      promo,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async editPromotionMxN(
    id: number,
    promo: {
      promotion: {
        name: string;
        description: string;
        recurrence: any;
        startDate: any;
        endDate: any;
        branchOfficeIds: any;
        clientSegmentIds: any;
        categoryIds: any;
        brandIds: any;
        acceptedPaymentMethodIds: any;
        bines: any;
        binMin: number;
        binMax: number;
        minimumTicket: number;
        maximumTicket: number;
        quantityApplications: number;
        quantityPerArticle: number;
        quantityPerBranchOffice: number;
        quantityPerClient: number;
        accumulateOtherPromotions: boolean;
        distributeLines: boolean;
      };
      discountPercent: number;
      quantityWithDiscount: number;
      quantityForPromotion: number;
      allProducts: boolean;
      forTotalArticles: boolean;
      highPrice: boolean;
      skuIds: any;
    }
  ) {
    const response = await client.put(`/api/promotions/mxn/${id}`, promo, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async editPromotionProgressive(
    id: number,
    promo: {
      promotion: {
        name: string;
        description: string;
        recurrence: any;
        startDate: any;
        endDate: any;
        branchOfficeIds: any;
        clientSegmentIds: any;
        categoryIds: any;
        brandIds: any;
        acceptedPaymentMethodIds: any;
        bines: any;
        binMin: number;
        binMax: number;
        minimumTicket: number;
        maximumTicket: number;
        quantityApplications: number;
        quantityPerArticle: number;
        quantityPerBranchOffice: number;
        quantityPerClient: number;
        accumulateOtherPromotions: boolean;
        distributeLines: boolean;
      };
      lines: any;
      skuIds: any;
    }
  ) {
    const response = await client.put(
      `/api/promotions/progressiveDiscount/${id}`,
      promo,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async editPromotionBogo(
    id: number,
    promo: {
      promotion: {
        name: string;
        description: string;
        recurrence: any;
        startDate: any;
        endDate: any;
        branchOfficeIds: any;
        clientSegmentIds: any;
        categoryIds: any;
        brandIds: any;
        acceptedPaymentMethodIds: any;
        bines: any;
        binMin: number;
        binMax: number;
        minimumTicket: number;
        maximumTicket: number;
        quantityApplications: number;
        quantityPerArticle: number;
        quantityPerBranchOffice: number;
        quantityPerClient: number;
        accumulateOtherPromotions: boolean;
        distributeLines: boolean;
      };
      type: string;
      skuIds: any;
    }
  ) {
    const response = await client.put(
      `/api/promotions/buyOneGetOne/${id}`,
      promo,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async getBrands(
    organizationId: string,
    pagination: number,
    filters: { name: string; externalId: string }
  ) {
    const { name, externalId } = filters;

    const response = await client.get(
      `/api/brands/search/findByOrganizationId?organizationId=${organizationId}&projection=brandProjection&size=10&page=${
        pagination - 1
      }${name ? `&name=${name}` : ""}${
        externalId ? `&externalId=${externalId}` : ""
      }`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const brands = response.data._embedded;
    const { totalElements } = response.data.page;

    return { brands, totalElements };
  }

  static async deleteBrand(brandId: number) {
    const response = await client.delete(`/api/brands/${brandId}`, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async createBrand(
    name: string,
    externalId: string,
    description: string
  ) {
    const formData = new FormData();
    formData.append("name", name);
    if (externalId) formData.append("externalId", externalId);
    formData.append("description", description);

    const response = await client.post("/api/brands/", formData, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async editBrand(
    brandId: number,
    name: string,
    externalId: string,
    description: string
  ) {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("externalId", externalId);
    formData.append("description", description);

    const response = await client.put(`/api/brands/${brandId}`, formData, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async getAllIdTypes() {
    const response = await client.get(
      `/api/idTypes/search/getAll?projection=idTypeProjection`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response.data._embedded;
  }

  static async getAllSexTypes() {
    const response = await client.get(`/api/types/sex`, {
      headers: {
        Authorization: token,
      },
    });

    return response.data;
  }

  static async getAllCountries() {
    const response = await client.get(
      `/api/countries/search/getAll?projection=countryProjection`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response.data._embedded;
  }

  static async getAllCountriesWithExistingClients(organizationId: string) {
    const response = await client.get(
      `/api/countries/search/findCountriesWithClientsByOrganization?organizationId=${organizationId}&projection=countryProjection`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response.data._embedded;
  }

  static async getAllCitiesWithExistingClients(organizationId: string) {
    const response = await client.get(
      `/api/states/search/findStaesWithClientsByOrganization?organizationId=${organizationId}&projection=stateProjection`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response.data._embedded;
  }

  static async getAllStatesByCountry(countryId: number) {
    const response = await client.get(
      `/api/states/search/findByCountryId?countryId=${countryId}&projection=stateWithoutProjection`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response.data._embedded;
  }

  static async getTickets(organizationId: string, pagination: number) {
    const response = await client.get(
      `/api/tickets/search/findByOrganizationId?organizationId=${organizationId}&projection=ticketProjection&size=10&page=${
        pagination - 1
      }`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const tickets = response.data._embedded;
    const { totalElements } = response.data.page;

    return { tickets, totalElements };
  }

  static async getTicketById(ticketId: string) {
    const response = await client.get(`/api/tickets/${ticketId}`, {
      headers: {
        Authorization: token,
      },
    });

    return response.data;
  }

  static async deleteTicket(ticketId: number) {
    const response = await client.delete(`/api/tickets/${ticketId}`, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async getAllTicketDocumentTypes() {
    const response = await client.get(`/api/types/ticketDocumentTypes`, {
      headers: {
        Authorization: token,
      },
    });

    return response.data;
  }

  static async createTicket(ticketInfo: any) {
    const response = await client.post("/api/tickets", ticketInfo, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async schedulePromotion(id: number, startDate: any, endDate: any) {
    const formData = new FormData();
    formData.append("startDate", startDate);
    if (endDate) formData.append("endDate", endDate);
    const response = await client.post(
      `/api/promotions/schedule/${id}`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async cancelPromotion(id: number) {
    const response = await client.post(
      `/api/promotions/cancel/${id}`,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async getAllRecurrencesTypes() {
    const response = await client.get(`/api/types/recurrenceTypes`, {
      headers: {
        Authorization: token,
      },
    });

    return response.data;
  }

  static async getPricings(organizationId: string, pagination: number) {
    const response = await client.get(
      `/api/pricings/search/findByOrganizationId?organizationId=${organizationId}&projection=pricingProjection&size=10&page=${
        pagination - 1
      }`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const pricings = response.data._embedded;
    const { totalElements } = response.data.page;

    return { pricings, totalElements };
  }

  static async deletePricing(pricingId: number) {
    const response = await client.delete(`/api/pricings/${pricingId}`, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async getPricingsByMonth(month: string) {
    const response = await client.get(
      `/api/pricings/monthByPricing/${month}`,
      // `/api/promotions/monthByPromotion/2022-05-11`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data;
  }

  static async schedulePricing(id: number, startDate: any, endDate: any) {
    const formData = new FormData();
    formData.append("startDate", startDate);
    if (endDate) formData.append("endDate", endDate);
    const response = await client.post(
      `/api/pricings/schedule/${id}`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async getAllPricingTypes() {
    const response = await client.get(`/api/pricingTypes/getAll`, {
      headers: {
        Authorization: token,
      },
    });

    return response.data;
  }

  static async getAllEndPricingTypes() {
    const response = await client.get(`/api/types/endPricingTypes`, {
      headers: {
        Authorization: token,
      },
    });

    return response.data;
  }

  static async createPricing(pricing: any) {
    const response = await client.post("/api/pricings/", pricing, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async getPricingsNoDate(organizationId: string, pagination: number) {
    const response = await client.get(
      `/api/pricings/search/findStartDateIsNull?organizationId=${organizationId}&projection=pricingProjection&size=10&page=${
        pagination - 1
      }`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const pricings = response.data._embedded;
    const { totalElements } = response.data.page;

    return { pricings, totalElements };
  }

  static async getPricingById(pricingId: string) {
    const response = await client.get(`/api/pricings/${pricingId}`, {
      headers: {
        Authorization: token,
      },
    });

    return response.data;
  }

  static async getPricingTimeLineById(pricingId: string) {
    const response = await client.get(
      `/api/pricings/${pricingId}/changesOfPrice`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response.data;
  }

  static async editPricing(id: number, pricing: any) {
    const response = await client.put(`/api/pricings/${id}`, pricing, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async getSupplierPaymentMethods(
    organizationId: string,
    pagination: number
  ) {
    const response = await client.get(
      `/api/supplierPaymentMethod/search/findByOrganization?organizationId=${organizationId}&projection=supplierPaymentMethodProjection&size=10&page=${
        pagination - 1
      }`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const supplierPaymentMethods = response.data._embedded;
    const { totalElements } = response.data.page;

    return { supplierPaymentMethods, totalElements };
  }

  static async createSupplierPaymentMethod(paymentMethod: {
    name: string;
    paymentDeadlineDays: number;
    typeId?: number;
  }) {
    const { name, paymentDeadlineDays, typeId } = paymentMethod;
    const response = await client.post(
      "/api/supplierPaymentMethod/",
      { name, paymentDeadlineDays, typeId },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async deleteSupplierPaymentMethod(id: number) {
    const response = await client.delete(`/api/supplierPaymentMethod/${id}`, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async editSupplierPaymentMethod(
    id: number,
    name: string,
    paymentDeadlineDays: number,
    typeId?: number
  ) {
    const response = await client.put(
      `/api/supplierPaymentMethod/${id}`,
      { name, paymentDeadlineDays, typeId },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  }

  static async getAllSupplierPaymentMethods() {
    const response = await client.get(`/api/supplierPaymentMethod/getAll`, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  }

  static async getPurchaseUnits(organizationId: string, pagination: number) {
    const response = await client.get(
      `/api/purchaseUnits/search/findByOrganization?organizationId=${organizationId}&projection=purchaseUnitProjection&size=10&page=${
        pagination - 1
      }`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const purchaseUnits = response.data._embedded;
    const { totalElements } = response.data.page;
    return { purchaseUnits, totalElements };
  }

  static async createPurchaseUnit(
    purchaseUnit: string,
    conversionFactor: string
  ) {
    const response = await client.post(
      "/api/purchaseUnits/",
      { purchaseUnit, conversionFactor },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async deletePurchaseUnit(purchaseUnitId: number) {
    const response = await client.delete(
      `/api/purchaseUnits/${purchaseUnitId}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async editPurchaseUnit(
    purchaseUnitId: number,
    purchaseUnit: string,
    conversionFactor: string
  ) {
    const response = await client.put(
      `/api/purchaseUnits/${purchaseUnitId}`,
      { purchaseUnit, conversionFactor },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async discontinueArticle(articleId: number) {
    const response = await client.put(
      `/api/articles/toggleDiscontinued/${articleId}`,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async discontinueBranchOfficeArticle(
    branchOfficeId: number,
    articleId: number
  ) {
    const response = await client.put(
      `/api/branchOffices/${branchOfficeId}/toggleUnsupply/${articleId}`,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }
  static async getExcelTemplate(type: string) {
    const response = await client.get(`/api/${type}/excelTemplate`, {
      responseType: "arraybuffer",
      headers: {
        Authorization: token,
        ContentType: "blob",
      },
    });
    return response;
  }

  static async processExcelTemplate(type: string, file: any) {
    const formData = new FormData();
    formData.append("file", file);

    const response = await client.post(
      `/api/${type}/processExcelTemplate`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  }

  static async getNotifications(pagination: number) {
    const response = await client.get(
      `/api/notifications/getByUser?size=10&page=${pagination - 1}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const notifications = response.data.data.content;
    const { totalElements } = response.data.data;
    return { notifications, totalElements };
  }

  static async readNotification(notificationId: number) {
    const formData = new FormData();

    const response = await client.put(
      `/api/notifications/read/${notificationId}`,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async unreadNotification(notificationId: number) {
    const formData = new FormData();

    const response = await client.put(
      `/api/notifications/unread/${notificationId}`,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async getSeasons(pagination: number, organizationId: string) {
    const response = await client.get(
      `/api/seasons/search/findByOrganizationId?organizationId=${organizationId}&projection=seasonsProjection&page=${pagination}&size=20`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const { seasons } = response.data._embedded;
    const { totalElements } = response.data.page;
    return { seasons, totalElements };
  }

  static async getSeasonsFiltered(name: string) {
    const response = await client.get(
      `/api/seasons/query?name=${name}&page=0&size=10`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response.data;
  }

  static async getSeasonById(seasonId: string) {
    const response = await client.get(`/api/seasons/${seasonId}`, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  }

  static async editSeason(
    seasonId: string,
    name: string,
    startingDate: string,
    endingDate: string
  ) {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("startingDate", startingDate);
    formData.append("endingDate", endingDate);

    const response = await client.put(`/api/seasons/${seasonId}`, formData, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async createSeason(
    name: string,
    startingDate: string,
    endingDate: string
  ) {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("startingDate", startingDate);
    formData.append("endingDate", endingDate);

    const response = await client.post("/api/seasons/", formData, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async deleteSeason(id: number) {
    const response = await client.delete(`/api/seasons/${id}`, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async getCommercialDates(pagination: number, organizationId: string) {
    const response = await client.get(
      `/api/commercialDates/search/findByOrganizationId?organizationId=${organizationId}&projection=commercialDateProjection&page=${pagination}&size=20`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    const { commercialDates } = response.data._embedded;
    const { totalElements } = response.data.page;
    return { commercialDates, totalElements };
  }

  static async createCommercialDates(
    name: string,
    startingDate: string,
    endingDate: string
  ) {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("startingDate", startingDate);
    formData.append("endingDate", endingDate);

    const response = await client.post("/api/commercialDates/", formData, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async getCommercialDateById(commercialDateId: string) {
    const response = await client.get(
      `/api/commercialDates/${commercialDateId}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data;
  }

  static async editCommercialDate(
    commercialDateId: string,
    name: string,
    startingDate: string,
    endingDate: string
  ) {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("startingDate", startingDate);
    formData.append("endingDate", endingDate);

    const response = await client.put(
      `/api/commercialDates/${commercialDateId}`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async deleteCommercialDate(id: number) {
    const response = await client.delete(`/api/commercialDates/${id}`, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async getStockMovements(
    organizationId: string,
    filters: {
      pagination: number;
      businessUnitId: string;
      startDate?: string | null;
      endDate?: string | null;
      articleId?: string | null;
      type?: string | null;
      branchOfficeId?: string;
      depositId?: string;
    }
  ) {
    const {
      pagination,
      businessUnitId,
      startDate,
      endDate,
      articleId,
      type,
      depositId,
      branchOfficeId,
    } = filters;

    const response = await client.get(
      `/api/stockMovements/search/filterStockMovement?organizationId=${organizationId}&businessUnitId=${businessUnitId}&size=10&page=${pagination}${
        startDate ? "&startDate=" + startDate + "T00:00:00" : ""
      }${endDate ? "&endDate=" + endDate + "T23:59:59" : ""}${
        articleId ? "&articleId=" + articleId : ""
      }${type ? "&type=" + type : ""}${
        branchOfficeId ? "&branchOfficeId=" + branchOfficeId : ""
      }${depositId ? "&depositId=" + depositId : ""}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const stockMovements = response.data._embedded.stockMovements;
    const { totalElements } = response.data.page;

    return { stockMovements, totalElements };
  }

  static async getMovementStockTypes() {
    const response = await client.get(`/api/stockMovements/types`, {
      headers: {
        Authorization: token,
      },
    });

    return response.data;
  }

  static async createStockMovements(movement: {
    skuBranchOfficeId?: number;
    skuDepositId?: number;
    stock: number;
    reason: string;
    date: string;
    type: string;
  }) {
    const response = await client.post(
      "/api/stockMovements/",
      { ...movement },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async createColour(colour: string) {
    const response = await client.post(
      "/api/colours/",
      { name: colour },
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async getColours(
    organizationId: string,
    pagination: number,
    filters: { name: string }
  ) {
    const { name } = filters;

    const response = await client.get(
      `/api/colours/search/findByOrganizationId?organizationId=${organizationId}&size=10&page=${
        pagination - 1
      }${name ? `&name=${name}` : ""}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    const colours = response.data._embedded.colours;
    const { totalElements } = response.data.page;
    return { colours, totalElements };
  }

  static async getColoursByName(name: string, organizationId: string) {
    const response = await client.get(
      `/api/colours/search/findByNameContainingAndOrganizationId?organizationId=${organizationId}&projection=colourProjection&name=${name}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    const { colours } = response.data._embedded;
    const { totalElements } = response.data.page;

    return { colours, totalElements };
  }

  static async deleteColour(id: number) {
    const response = await client.delete(`/api/colours/${id}`, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async editColour(id: number, name: string) {
    const formData = new FormData();
    formData.append("name", name);
    const response = await client.put(`/api/colours/${id}`, formData, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async getClusters(organizationId: string, pagination: number) {
    const response = await client.get(
      `/api/clusters/search/findByOrganizationId?organizationId=${organizationId}&size=10&page=${
        pagination - 1
      }`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    const clusters = response.data._embedded.clusters;
    const { totalElements } = response.data.page;
    return { clusters, totalElements };
  }

  static async editCluster(
    id: number,
    cluster: {
      name: string;
    }
  ) {
    const formData = new FormData();
    formData.append("name", cluster.name);
    const response = await client.put(`/api/clusters/${id}`, formData, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async addDepositToCluster(clusterId: number, depositId: number) {
    const formData = new FormData();
    formData.append("depositId", depositId.toString());

    const response = await client.post(
      `/api/clusters/${clusterId}/addDeposit`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async addBranchOfficeToCluster(
    clusterId: number,
    branchOfficeId: number
  ) {
    const formData = new FormData();
    formData.append("branchOfficeId", branchOfficeId.toString());

    const response = await client.post(
      `/api/clusters/${clusterId}/addBranchOffice`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }
  static async deletePlaceFromCluster(placeId: number) {
    const response = await client.delete(
      `/api/clusters/removeBranchOfficeOrDeposit/${placeId}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response;
  }

  static async createCluster(cluster: {
    name: string;
    depositsIds: any;
    branchOfficesIds: any;
  }) {
    const { name, depositsIds, branchOfficesIds } = cluster;
    const formData = new FormData();
    formData.append("name", name);
    if (depositsIds) formData.append("depositsIds", depositsIds);
    if (branchOfficesIds) formData.append("branchOfficesIds", branchOfficesIds);
    const response = await client.post("/api/clusters/", formData, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async deleteCluster(id: number) {
    const response = await client.delete(`/api/clusters/${id}`, {
      headers: {
        Authorization: token,
      },
    });

    return response;
  }

  static async getClusterById(id: string) {
    const response = await client.get(`/api/clusters/${id}`, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  }
}

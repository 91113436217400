import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import NotFound from "../notfound/NotFound";
import {
  editDeposit,
  getDepositById,
  getAllBranchOfficesByBu,
  getCategoriesFiltered,
  processExcelTemplate,
} from "../../api/Services";
import { BusinessUniteContext } from "../../components/laoyut/Layout";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";
import ArticleStock from "../../components/articlestock/ArticleStock";

import {
  Form,
  message,
  Select,
  Button,
  Input,
  Spin,
  TreeSelect,
  InputNumber,
  Modal,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import styles from "./ViewDeposit.module.scss";
import { handleDownloadTemplate } from "../../helpers/Helper";
import ImportModal from "../../components/importmodal/ImportModal";

const ViewDeposit = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    contextValue: businessUnitSelected,
    updateContextValue: setBusinessUnitSelected,
  } = useContext(BusinessUniteContext);

  const [loading, setLoading] = useState(true);
  const [deposit, setDeposit] = useState<any>();
  const [categories, setCategories] = useState<any>();
  const [downloadingTemplate, setDownloadingTemplate] = useState(false);
  const [isImportModalVisible, setIsImportModalVisible] = useState(false);
  const [importErrors, setImportErrors] = useState<any>(null);

  const [editForm] = Form.useForm();

  const canEdit = hasPermission(privilegesEnum.WRITE_DEPOSITS);

  const { id } = useParams();

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_DEPOSITS)) {
      handleFetchDeposit();
    }
  }, []);

  useEffect(() => {
    if (
      deposit &&
      !deposit.businessUnits
        .map((businessUnit: any) => businessUnit.id)
        .includes(businessUnitSelected) &&
      businessUnitSelected &&
      !loading
    ) {
      navigate("/admin/deposits");
    }
  }, [businessUnitSelected]);

  const getOptionsCategories = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      const parentsString = item?.parents
        ? item.parents.filter(Boolean).join(" - ")
        : "";
      const categoryName = item?.name || "";

      const categoryTree = parentsString
        ? `${parentsString} - ${categoryName}`
        : categoryName;

      options.push(
        <Select.Option value={item.id} key={item.id}>
          {categoryTree}
        </Select.Option>
      );
    });

    return options;
  };
  const getOptionsTree = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push({
        title: item.businessUnit.name,
        value: item.businessUnit.name,
        key: item.businessUnit.name,
        children: item.branchOffices.map((bu: any) => ({
          title: bu.name,
          value: bu.id,
          key: bu.id,
        })),
      });
    });

    return options;
  };

  const handleSearchCategories = async (value: string) => {
    if (value.length > 2) {
      const response = await getCategoriesFiltered(value);
      setCategories(response);
    } else setCategories([]);
  };
  const handleFetchDeposit = async () => {
    let response = await getDepositById(id!);
    if (response.businessUnits.length > 0) {
      const branchOffices = await getAllBranchOfficesByBu(
        response.businessUnits.map((bu: any) => bu.id)
      );
      response.branchOfficesOptions = branchOffices;
    }
    setCategories(response.categories);
    setDeposit(response);
    setBusinessUnitSelected(response.businessUnits[0].id);
    setLoading(false);
  };

  const handleEditDeposit = async () => {
    await editForm.validateFields().then(async (data) => {
      setLoading(true);
      const id = deposit.id;
      const {
        name,
        externalId,
        capacity,
        branchOfficesIds,
        address,
        categoriesIds,
        capacityInUnits,
        capacityDispatchUnits,
        dispatchFrequency,
        capacityReception,
        storageCostPerUnit,
      } = data;
      let response = await editDeposit(id, {
        name,
        capacity,
        externalId,
        branchOfficesIds,
        address,
        businessUnitsIds: [businessUnitSelected] ?? [],
        categoriesIds: categoriesIds ?? [],
        capacityInUnits,
        capacityDispatchUnits,
        dispatchFrequency,
        capacityReception,
        storageCostPerUnit,
      });

      if (response) {
        message.success(t("Common-EditSuccessMessage"));
        handleFetchDeposit();
      } else message.error(t("Common-EditErrorMessage"));
      setLoading(false);
    });
  };

  const handleCancelButton = () => {
    editForm.resetFields();
  };

  const showImportModal = () => {
    setIsImportModalVisible(true);
    setImportErrors("");
  };

  const handleCancelImportModal = () => {
    setIsImportModalVisible(false);
  };

  const excelButtons = (
    <>
      <Button
        onClick={showImportModal}
        className={styles.importButton}
        type="primary"
      >
        {t("Common-Import")}
      </Button>
      <Button
        onClick={() =>
          handleDownloadTemplate("deposits/stocks", setDownloadingTemplate)
        }
        loading={downloadingTemplate}
        className={styles.importButton}
        type="primary"
      >
        {t("Common-DownloadTemplate")}
      </Button>
    </>
  );

  const handleImportFile = async (file: any) => {
    if (file) {
      const response = await processExcelTemplate("branchOffices/stocks", file);
      if (response.success) {
        setImportErrors(null);
        setIsImportModalVisible(false);
        message.success(
          t("Common-ImportSuccess", { quantity: response.data.added })
        );
        handleFetchDeposit();
      } else {
        if (response.data?.errors) setImportErrors(response.data.errors);
        else message.error(t("Error-CommonError"));
      }
    }
  };

  const editedBranchOffices: number[] = [];
  deposit?.branchOffices?.forEach((branchOffice: any) =>
    editedBranchOffices.push(branchOffice.id)
  );

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : !deposit ? (
    <NotFound />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-Deposit")}</h1>
        {hasPermission(privilegesEnum.WRITE_DEPOSITS) && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              onClick={handleEditDeposit}
              className={styles.saveButton}
              type="primary"
            >
              {t("Common-Save")}
            </Button>
            <Button
              onClick={handleCancelButton}
              className={styles.cancelButton}
              type="primary"
            >
              {t("Common-Cancel")}
            </Button>
          </div>
        )}
      </div>

      <div className={styles.contentWrapper}>
        <div className={styles.depositInfoWrapper}>
          <Form
            name="viewdeposit"
            layout={"vertical"}
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.newForm}
            form={editForm}
          >
            <Form.Item
              label={t("Common-Name")}
              name="name"
              initialValue={deposit?.name}
              className={styles.formItem}
              rules={[
                {
                  required: true,
                  message: t("Common-NameNotEntered"),
                },
              ]}
            >
              <Input disabled={!canEdit} placeholder={t("Common-Name")} />
            </Form.Item>

            <Form.Item
              label={t("Common-CapacityStorageInmt3")}
              name="capacity"
              initialValue={deposit?.capacity}
              className={styles.formItem}
              rules={[
                {
                  required: true,
                  message: t("Common-CapacityStorageInmt3"),
                },
              ]}
            >
              <InputNumber
                disabled={!canEdit}
                placeholder={t("Common-CapacityStorageInmt3")}
                addonAfter={t("Common-mtcube")}
                className={styles.inputNumber}
              />
            </Form.Item>

            <Form.Item
              initialValue={deposit?.capacityInUnits}
              className={styles.formItem}
              label={t("Common-CapacityStorageInUnits")}
              name="capacityInUnits"
            >
              <InputNumber
                disabled={!canEdit}
                placeholder={t("Common-CapacityStorageInUnits")}
                className={styles.inputNumber}
              />
            </Form.Item>
            <Form.Item
              initialValue={deposit?.capacityDispatchUnits}
              className={styles.formItem}
              label={t("Common-CapacityDispatchUnits")}
              name="capacityDispatchUnits"
            >
              <InputNumber
                disabled={!canEdit}
                placeholder={t("Common-CapacityDispatchUnits")}
                className={styles.inputNumber}
              />
            </Form.Item>
            <Form.Item
              initialValue={deposit?.capacityReception}
              className={styles.formItem}
              label={t("Common-CapacityReception")}
              name="capacityReception"
            >
              <InputNumber
                disabled={!canEdit}
                placeholder={t("Common-CapacityReception")}
                className={styles.inputNumber}
              />
            </Form.Item>
            <Form.Item
              initialValue={deposit?.storageCostPerUnit}
              className={styles.formItem}
              label={t("Common-StorageCostPerUnit")}
              name="storageCostPerUnit"
            >
              <InputNumber
                disabled={!canEdit}
                placeholder={t("Common-StorageCostPerUnit")}
                className={styles.inputNumber}
              />
            </Form.Item>
            <Form.Item
              initialValue={deposit?.dispatchFrequency}
              className={styles.formItem}
              label={t("Common-DispatchFrequency")}
              name="dispatchFrequency"
            >
              <InputNumber
                disabled={!canEdit}
                min={1}
                max={7}
                placeholder={t("Common-DispatchFrequency")}
                className={styles.inputNumber}
              />
            </Form.Item>

            <Form.Item
              label={t("Common-ExternalId")}
              name="externalId"
              initialValue={deposit?.externalId}
              className={styles.formItem}
            >
              <Input placeholder={t("Common-ExternalId")} />
            </Form.Item>

            <Form.Item
              label={t("Common-Address")}
              name="address"
              initialValue={deposit?.address}
              className={styles.formItem}
            >
              <Input placeholder={t("Common-Address")} />
            </Form.Item>

            <Form.Item
              className={styles.formItem}
              label={t("Common-BranchOffices")}
              name="branchOfficesIds"
              initialValue={editedBranchOffices}
            >
              <TreeSelect
                allowClear
                placeholder={t("Common-BranchOffices")}
                treeCheckable={true}
                defaultValue={editedBranchOffices}
                treeData={getOptionsTree(deposit?.branchOfficesOptions)}
              />
            </Form.Item>

            <Form.Item
              label={t("Common-Categories")}
              name="categoriesIds"
              className={styles.formItem}
              initialValue={deposit?.categories?.map((cat: any) => cat.id)}
            >
              <Select
                allowClear
                placeholder={t("Common-SearchByName")}
                onSearch={handleSearchCategories}
                showSearch
                mode="multiple"
                optionFilterProp="children"
                defaultValue={deposit?.categories?.map((cat: any) => cat.id)}
                filterOption={(input: string, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {getOptionsCategories(categories)}
              </Select>
            </Form.Item>
          </Form>
        </div>
        <div className={styles.tableWrapper}></div>
        <ArticleStock
          data={deposit.skus}
          header={excelButtons}
          fetchData={handleFetchDeposit}
          type={"deposits"}
          placeId={parseInt(id!)}
          searchByName={false}
        />
      </div>
      <Modal
        title={t("Common-ImportEntity", {
          entity: t("Common-Stock"),
        })}
        visible={isImportModalVisible}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancelImportModal}
      >
        <ImportModal onOk={handleImportFile} errors={importErrors} />
      </Modal>
    </div>
  );
};

export default ViewDeposit;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  createPromotionBuyTogether,
  createPromotionRegular,
  createPromotionMxN,
  createPromotionBogo,
  createPromotionProgressive,
  getAllRecurrencesTypes,
  getAllAcceptedPaymentMethods,
  getCategoriesFiltered,
  getBranchOfficesFiltered,
  getClientSegmentsFiltered,
  getBrandsFiltered,
  getSkusFiltered,
} from "../../api/Services";
import AddSkuModal from "../../components/addskumodal/AddSkuModal";

import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";
import {
  Form,
  message,
  Space,
  Select,
  Button,
  Input,
  Switch,
  DatePicker,
  Checkbox,
  InputNumber,
  Divider,
  Typography,
  TimePicker,
} from "antd";

import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import styles from "./NewPromotion.module.scss";

const NewPromotion = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [withRecurrence, setWithRecurrence] = useState(false);
  const [articles, setArticles] = useState<any[]>([]);
  const [branchOffices, setBranchOffices] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [brands, setBrands] = useState<any[]>([]);
  const [recurrenceTypes, setRecurrenceTypes] = useState<any[]>([]);
  const [acceptedPaymentMethods, setAcceptedPaymentMethods] = useState<any[]>(
    []
  );
  const [clientSegments, setClientSegments] = useState<any[]>([]);
  const [items, setItems] = useState<string[]>([]);
  const [bin, setBin] = useState("");
  const [skusSelected, setSkusSelected] = useState<any[]>([]);
  const [skusSecondSelected, setSkusSecondSelected] = useState<any[]>([]);

  const [newPromotionForm] = Form.useForm();
  const [conditionsForm] = Form.useForm();
  const [restrictionsForm] = Form.useForm();
  const [extraForm] = Form.useForm();

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_PROMOTIONS)) {
      handleFetchRecurrenceTypes();
      handleFetchAcceptedPaymentMethods();
    }
  }, []);

  const handleSearchCategories = async (value: string) => {
    if (value.length > 2) {
      const response = await getCategoriesFiltered(value);
      setCategories(response);
    } else setCategories([]);
  };

  const handleSearchSkus = async (value: string) => {
    if (value.length > 2) {
      const response = await getSkusFiltered(0, { skuName: value });
      setArticles(response.skus);
    } else setArticles([]);
  };

  const handleSearchBranchOffices = async (value: string) => {
    if (value.length > 1) {
      const response = await getBranchOfficesFiltered(0, { name: value });
      setBranchOffices(response.branchOffices);
    } else setBranchOffices([]);
  };

  const handleSearchClientSegments = async (value: string) => {
    if (value.length > 2) {
      const response = await getClientSegmentsFiltered(value);
      setClientSegments(response);
    } else setClientSegments([]);
  };

  const handleSearchBrands = async (value: string) => {
    if (value.length > 2) {
      const response = await getBrandsFiltered(value);
      setBrands(response);
    } else setBrands([]);
  };

  const handleFetchRecurrenceTypes = async () => {
    const response = await getAllRecurrencesTypes();
    setRecurrenceTypes(response);
    setLoading(false);
  };

  const handleFetchAcceptedPaymentMethods = async () => {
    const response = await getAllAcceptedPaymentMethods();
    setAcceptedPaymentMethods(response);
  };

  const getOptions = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });

    return options;
  };

  const getOptionsCategories = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      const parentsString = item?.parents
          ? item.parents.filter(Boolean).join(" - ")
          : "";
      const categoryName = item?.name || "";

      const categoryTree = parentsString
          ? `${parentsString} - ${categoryName}`
          : categoryName;

      options.push(
          <Select.Option value={item.id} key={item.id}>
            {categoryTree}
          </Select.Option>
      );
    });

    return options;
  };

  const getRecurrenceOptions = () => {
    const options: any[] = [];

    recurrenceTypes.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.value} key={item.value}>
          {item.description_es}
        </Select.Option>
      );
    });

    return options;
  };

  const onBinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBin(event.target.value);
  };

  const addItem = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (bin.length === 6) {
      setItems([...items, bin]);
      setBin("");
    } else {
      message.error(t("Common-BinMinLength"));
    }
  };

  const handleAddSku = (skusToAdd: any) => {
    const newSkus: any[] = [];
    skusToAdd.forEach((skuToAdd: any) => {
      if (
        skusSelected.find((article: any) => article.id === skuToAdd.id) ===
        undefined
      )
        newSkus.push(skuToAdd);
    });
    setSkusSelected([...skusSelected, ...newSkus]);
  };

  const handleRemoveSkus = (skuRemove: any) => {
    const skusRemoved = skusSelected.filter((sku) => sku.id !== skuRemove.id);

    setSkusSelected(skusRemoved);
  };

  const handleAddSkuSecond = (skusToAdd: any) => {
    const newSkus: any[] = [];
    skusToAdd.forEach((skuToAdd: any) => {
      if (
        skusSecondSelected.find(
          (article: any) => article.id === skuToAdd.id
        ) === undefined
      )
        newSkus.push(skuToAdd);
    });
    setSkusSecondSelected([...skusSecondSelected, ...newSkus]);
  };

  const handleRemoveSkusSecond = (skuRemove: any) => {
    const skusRemoved = skusSecondSelected.filter(
      (sku) => sku.id !== skuRemove.id
    );

    setSkusSecondSelected(skusRemoved);
  };

  const handleCreate = async () => {
    await newPromotionForm.validateFields();

    setLoading(true);

    const data = newPromotionForm.getFieldsValue();
    const conditions = conditionsForm.getFieldsValue();
    const restrictions = restrictionsForm.getFieldsValue();
    const extras = extraForm.getFieldsValue();
    let response;

    const {
      name,
      description,
      recurrenceType,
      date,
      type,
      recurrenceDays,
      dayMonthly,
      dayAnually,
      period,
      repeatInterval,
    } = data;

    const startTime = period ? period[0].format("HH:mm") : null;
    const endTime = period ? period[1].format("HH:mm") : null;
    const recurrence: {
      type: any;
      dayAnually: any;
      dayMonthly: any;
      monday?: boolean;
      tuesday?: boolean;
      wednesday?: boolean;
      thursday?: boolean;
      friday?: boolean;
      saturday?: boolean;
      sunday?: boolean;
      repeatInterval?: number;
      startTime?: any;
      endTime?: any;
    } | null =
      recurrenceType && withRecurrence
        ? {
            type: recurrenceType,
            dayAnually: dayAnually ? dayAnually.format("YYYY-MM-DD") : null,
            dayMonthly,
            startTime,
            endTime,
            repeatInterval,
          }
        : null;

    if (recurrence)
      recurrenceDays?.forEach(
        (
          element:
            | "monday"
            | "tuesday"
            | "wednesday"
            | "thursday"
            | "friday"
            | "saturday"
            | "sunday"
        ) => (recurrence[element] = true)
      );

    const {
      branchOfficeIds,
      clientSegmentIds,
      categoryIds,
      brandIds,
      acceptedPaymentMethodIds,
      bines,
      binMin,
      binMax,
    } = conditions;

    const {
      quantityApplications,
      quantityPerArticle,
      quantityPerBranchOffice,
      quantityPerClient,
      minimumTicket,
      maximumTicket,
    } = restrictions;

    const { accumulateOtherPromotions, distributeLines } = extras;

    const startDate = date ? date[0].format("YYYY-MM-DDTHH:mm:ss") : null;
    const endDate = date ? date[1].format("YYYY-MM-DDTHH:mm:ss") : null;

    let promoInfo: any = {
      name,
      description,
      recurrence,
      startDate,
      endDate,
      type,
      branchOfficeIds: branchOfficeIds ?? [],
      clientSegmentIds: clientSegmentIds ?? [],
      categoryIds: categoryIds ?? [],
      brandIds: brandIds ?? [],
      acceptedPaymentMethodIds: acceptedPaymentMethodIds ?? [],
      bines: bines ?? [],
      binMin,
      binMax,
      minimumTicket,
      maximumTicket,
      quantityApplications,
      quantityPerArticle,
      quantityPerBranchOffice,
      quantityPerClient,
      accumulateOtherPromotions,
      distributeLines,
    };

    switch (type) {
      case "regular": {
        const { regularPromotionType } = data;
        const skuList = skusSelected.map((sku) => sku.id);

        promoInfo = {
          ...promoInfo,
          skuIds: skuList,
          regularPromotionType,
        };

        switch (regularPromotionType) {
          case "GIFT": {
            const { giftId } = data;
            promoInfo = { ...promoInfo, giftId: giftId };
            break;
          }
          case "PERCENTAGE_SENT":
          case "PERCENTAGE": {
            const { discountPercentage } = data;
            promoInfo = {
              ...promoInfo,
              discountPercentage: discountPercentage,
            };

            break;
          }
          case "MAX_PRICE_PER_ARTICLE":
          case "NOMINAL_SENT":
          case "NOMINAL": {
            const { discountValue } = data;
            promoInfo = { ...promoInfo, discountValue: discountValue };
            break;
          }
        }
        response = await createPromotionRegular(promoInfo);
        break;
      }
      case "buytogether": {
        const { discountFirstList, discountSecondList } = data;

        const skusFirstList = skusSelected.map((sku) => sku.id);
        const skusSecondList = skusSecondSelected.map((sku) => sku.id);

        response = await createPromotionBuyTogether(
          name,
          description,
          recurrence,
          startDate,
          endDate,
          discountFirstList,
          discountSecondList,
          skusFirstList,
          skusSecondList,
          branchOfficeIds ?? [],
          clientSegmentIds ?? [],
          categoryIds ?? [],
          brandIds ?? [],
          acceptedPaymentMethodIds ?? [],
          bines,
          binMin,
          binMax,
          minimumTicket,
          maximumTicket,
          quantityApplications,
          quantityPerArticle,
          quantityPerBranchOffice,
          quantityPerClient,
          accumulateOtherPromotions,
          distributeLines
        );
        break;
      }
      case "mxn": {
        const {
          quantityForPromotion,
          quantityWithDiscount,
          discountPercent,
          allProducts,
          highPrice,
          forTotalArticles,
        } = data;

        const skuList = skusSelected.map((sku) => sku.id);

        response = await createPromotionMxN(
          name,
          description,
          recurrence,
          startDate,
          endDate,
          skuList,
          quantityForPromotion,
          quantityWithDiscount,
          discountPercent,
          allProducts,
          highPrice,
          forTotalArticles,
          branchOfficeIds ?? [],
          clientSegmentIds ?? [],
          categoryIds ?? [],
          brandIds ?? [],
          acceptedPaymentMethodIds ?? [],
          bines,
          binMin,
          binMax,
          minimumTicket,
          maximumTicket,
          quantityApplications,
          quantityPerArticle,
          quantityPerBranchOffice,
          quantityPerClient,
          accumulateOtherPromotions,
          distributeLines
        );
        break;
      }
      case "progressive": {
        const { lines } = data;

        const skuList = skusSelected.map((sku) => sku.id);

        response = await createPromotionProgressive(
          name,
          description,
          recurrence,
          startDate,
          endDate,
          skuList,
          lines,
          branchOfficeIds ?? [],
          clientSegmentIds ?? [],
          categoryIds ?? [],
          brandIds ?? [],
          acceptedPaymentMethodIds ?? [],
          bines,
          binMin,
          binMax,
          minimumTicket,
          maximumTicket,
          quantityApplications,
          quantityPerArticle,
          quantityPerBranchOffice,
          quantityPerClient,
          accumulateOtherPromotions,
          distributeLines
        );
        break;
      }
      case "bogo": {
        const { discountType, bogoGiftId } = data;

        const skuList = skusSelected.map((sku) => sku.id);
        response = await createPromotionBogo(
          name,
          description,
          recurrence,
          startDate,
          endDate,
          skuList,
          discountType,
          bogoGiftId ?? null,
          branchOfficeIds ?? [],
          clientSegmentIds ?? [],
          categoryIds ?? [],
          brandIds ?? [],
          acceptedPaymentMethodIds ?? [],
          bines,
          binMin,
          binMax,
          minimumTicket,
          maximumTicket,
          quantityApplications,
          quantityPerArticle,
          quantityPerBranchOffice,
          quantityPerClient,
          accumulateOtherPromotions,
          distributeLines
        );
        break;
      }
      default:
        break;
    }

    if (response) {
      message.success(t("Common-CreateSuccessMessage"));
      newPromotionForm.resetFields();
      window.location.href = "/promotions";
    } else message.error(t("Common-ErrorMessage"));
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-NewPromotion")}</h1>
        <Button
          onClick={handleCreate}
          className={styles.saveButton}
          type="primary"
        >
          {t("Common-Add")}
        </Button>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.formWrapper}>
          <h1 className={styles.subTitle}>{`${t("Common-Promotion")} `}</h1>
          <Form
            name="newPromotion"
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.newForm}
            form={newPromotionForm}
          >
            <Form.Item
              label={t("Common-Name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("Common-Name"),
                },
              ]}
            >
              <Input placeholder={t("Common-Name")} />
            </Form.Item>
            <Form.Item label={t("Common-Description")} name="description">
              <Input placeholder={t("Common-Description")} />
            </Form.Item>
            <Form.Item label={t("Common-Date")} name="date">
              <DatePicker.RangePicker
                showTime={{ format: "HH:mm" }}
                format="YYYY-MM-DD HH:mm"
                allowEmpty={[false, true]}
                className={styles.dateSelector}
                // onChange={onChange}
                // onOk={onOk}
              />
            </Form.Item>
            <Form.Item
              initialValue={false}
              label={t("Common-EstablishRecurrence")}
              name="stablishrecurrence"
              rules={[
                {
                  required: true,
                  message: t("Common-EstablishRecurrence"),
                },
              ]}
            >
              <Switch onChange={() => setWithRecurrence(!withRecurrence)} />
            </Form.Item>
            {withRecurrence && (
              <>
                <Form.Item
                  label={t("Common-Recurrence")}
                  name="recurrenceType"
                  rules={[
                    {
                      required: true,
                      message: t("Common-Recurrence"),
                    },
                  ]}
                >
                  <Select
                    allowClear
                    placeholder={t("Common-Recurrence")}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input: string, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {getRecurrenceOptions()}
                  </Select>
                </Form.Item>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.recurrenceType !== currentValues.recurrenceType
                  }
                >
                  {({ getFieldValue }) => {
                    switch (getFieldValue("recurrenceType")) {
                      case "WEEKLY":
                        return (
                          <Form.Item
                            name="recurrenceDays"
                            label={t("Common-On")}
                            rules={[
                              { required: true, message: t("Common-On") },
                            ]}
                          >
                            <Checkbox.Group
                              options={[
                                { label: t("Common-Monday"), value: "monday" },
                                {
                                  label: t("Common-Tuesday"),
                                  value: "tuesday",
                                },
                                {
                                  label: t("Common-Wednesday"),
                                  value: "wednesday",
                                },
                                {
                                  label: t("Common-Thursday"),
                                  value: "thursday",
                                },
                                { label: t("Common-Friday"), value: "friday" },
                                {
                                  label: t("Common-Saturday"),
                                  value: "saturday",
                                },
                                { label: t("Common-Sunday"), value: "sunday" },
                              ]}
                            />
                          </Form.Item>
                        );
                      case "MONTHLY":
                        return (
                          <Form.Item
                            label={t("Common-Day")}
                            name="dayMonthly"
                            rules={[
                              {
                                required: true,
                                message: t("Common-Day"),
                              },
                            ]}
                          >
                            <InputNumber
                              min="1"
                              max="31"
                              className={styles.inputNumber}
                              placeholder={t("Common-Day")}
                            />
                          </Form.Item>
                        );
                      case "ANUALLY":
                        return (
                          <Form.Item
                            label={t("Common-Day")}
                            name="dayAnually"
                            rules={[
                              {
                                required: true,
                                message: t("Common-Day"),
                              },
                            ]}
                          >
                            <DatePicker
                              className={styles.dateSelector}
                              placeholder={t("Common-Date")}
                              // onChange={handleChangeDate}
                            />
                          </Form.Item>
                        );
                    }
                  }}
                </Form.Item>
                <Form.Item
                  label={t("Common-Interval")}
                  name="repeatInterval"
                  rules={[
                    {
                      required: true,
                      message: t("Common-Interval"),
                    },
                  ]}
                >
                  <InputNumber
                    min={1}
                    className={styles.inputNumber}
                    placeholder={t("Common-Interval")}
                  />
                </Form.Item>
                <Form.Item name="period" label={t("Common-Period")}>
                  <TimePicker.RangePicker
                    format="HH:mm"
                    className={styles.dateSelector}
                  />
                </Form.Item>
              </>
            )}
            <Form.Item
              label={t("Common-Type")}
              name="type"
              rules={[
                {
                  required: true,
                  message: t("Common-Type"),
                },
              ]}
            >
              <Select
                placeholder={t("Common-Type")}
                allowClear
                onChange={() => {
                  setSkusSelected([]);
                  setSkusSecondSelected([]);
                }}
              >
                <Select.Option value="buytogether">
                  Comprar juntos
                </Select.Option>
                <Select.Option value="bogo">Buy one get one</Select.Option>
                <Select.Option value="progressive">Progresivo</Select.Option>
                <Select.Option value="mxn">MxN</Select.Option>
                <Select.Option value="regular">Regular</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.type !== currentValues.type
              }
            >
              {({ getFieldValue }) => {
                switch (getFieldValue("type")) {
                  case "regular":
                    return (
                      <>
                        <AddSkuModal
                          title={t("Common-Skus")}
                          selectedArticles={skusSelected}
                          onAddArticle={handleAddSku}
                          onRemoveArticle={handleRemoveSkus}
                        />
                        <Form.Item
                          label={t("Common-DiscountType")}
                          name="regularPromotionType"
                          rules={[
                            {
                              required: true,
                              message: t("Common-DiscountType"),
                            },
                          ]}
                        >
                          <Select
                            placeholder="Tipo"
                            // onChange={onGenderChange}
                            allowClear
                          >
                            <Select.Option value="NOMINAL">
                              Nominal
                            </Select.Option>
                            <Select.Option value="NOMINAL_SENT">
                              Envio nominal
                            </Select.Option>
                            <Select.Option value="PERCENTAGE">
                              Porcentual
                            </Select.Option>
                            <Select.Option value="PERCENTAGE_SENT">
                              Porcentual envio
                            </Select.Option>
                            <Select.Option value="MAX_PRICE_PER_ARTICLE">
                              Precio maximo de articulo
                            </Select.Option>
                            <Select.Option value="FREE_SHIPPING">
                              Envio gratis
                            </Select.Option>
                            <Select.Option value="GIFT">Regalo</Select.Option>
                            <Select.Option value="NOMINAL_DISCOUNT">
                              Descuento nominal
                            </Select.Option>
                            <Select.Option value="PRICE_TABLE">
                              Tabla de precios
                            </Select.Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, currentValues) =>
                            prevValues.regularPromotionType !==
                            currentValues.regularPromotionType
                          }
                        >
                          {({ getFieldValue }) => {
                            switch (getFieldValue("regularPromotionType")) {
                              case "GIFT":
                                return (
                                  <Form.Item
                                    name="giftId"
                                    label={t("Common-Sku")}
                                    rules={[{ required: true }]}
                                  >
                                    <Select
                                      allowClear
                                      showSearch
                                      onSearch={handleSearchSkus}
                                      placeholder={t("Common-SearchByName")}
                                      filterOption={(
                                        input: string,
                                        option: any
                                      ) =>
                                        option.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                    >
                                      {getOptions(articles)}
                                    </Select>
                                  </Form.Item>
                                );
                              case "PERCENTAGE_SENT":
                              case "PERCENTAGE":
                                return (
                                  <Form.Item
                                    label={t("Common-DiscountPercent")}
                                    name="discountPercentage"
                                    rules={[
                                      {
                                        required: true,
                                        message: t("Common-DiscountPercent"),
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder={t("Common-DiscountPercent")}
                                    />
                                  </Form.Item>
                                );
                              case "MAX_PRICE_PER_ARTICLE":
                              case "NOMINAL_SENT":
                              case "NOMINAL":
                                return (
                                  <Form.Item
                                    label={t("Common-DiscountValue")}
                                    name="discountValue"
                                    rules={[
                                      {
                                        required: true,
                                        message: t("Common-DiscountValue"),
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder={t("Common-DiscountValue")}
                                    />
                                  </Form.Item>
                                );
                            }
                          }}
                        </Form.Item>
                      </>
                    );
                  case "bogo":
                    return (
                      <>
                        <AddSkuModal
                          title={t("Common-Skus")}
                          selectedArticles={skusSelected}
                          onAddArticle={handleAddSku}
                          onRemoveArticle={handleRemoveSkus}
                        />
                        <Form.Item
                          label={t("Common-DiscountType")}
                          name="discountType"
                          rules={[
                            {
                              required: true,
                              message: t("Common-DiscountType"),
                            },
                          ]}
                        >
                          <Select
                            placeholder="Tipo"
                            // onChange={onGenderChange}
                            allowClear
                          >
                            <Select.Option value="GIFT">
                              {t("Common-Gift")}
                            </Select.Option>
                            <Select.Option value="FREE_SHIPPING">
                              {t("Common-FreeShipping")}
                            </Select.Option>
                            <Select.Option value="MAX_PRICE_PER_ARTICLE">
                              {t("Common-MaxPricePerArticle")}
                            </Select.Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          noStyle
                          shouldUpdate={(prevValues, currentValues) =>
                            prevValues.discountType !==
                            currentValues.discountType
                          }
                        >
                          {({ getFieldValue }) =>
                            getFieldValue("discountType") === "GIFT" ? (
                              <Form.Item
                                name="bogoGiftId"
                                label={t("Common-Sku")}
                                rules={[{ required: true }]}
                              >
                                <Select
                                  allowClear
                                  showSearch
                                  onSearch={handleSearchSkus}
                                  placeholder={t("Common-SearchByName")}
                                  filterOption={(input: string, option: any) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {getOptions(articles)}
                                </Select>
                              </Form.Item>
                            ) : null
                          }
                        </Form.Item>
                      </>
                    );
                  case "mxn":
                    return (
                      <>
                        <AddSkuModal
                          title={t("Common-Skus")}
                          selectedArticles={skusSelected}
                          onAddArticle={handleAddSku}
                          onRemoveArticle={handleRemoveSkus}
                        />
                        <div className={styles.formItemsWrapper}>
                          <Form.Item
                            label={t("Common-QuantityForPromotion")}
                            name="quantityForPromotion"
                            rules={[
                              {
                                required: true,
                                message: t("Common-QuantityForPromotion"),
                              },
                            ]}
                          >
                            <InputNumber
                              min={0}
                              className={styles.inputNumber}
                              placeholder={t("Common-QuantityForPromotion")}
                            />
                          </Form.Item>
                          <Form.Item
                            label={t("Common-QuantityWithDiscount")}
                            name="quantityWithDiscount"
                            rules={[
                              {
                                required: true,
                                message: t("Common-QuantityWithDiscount"),
                              },
                            ]}
                          >
                            <InputNumber
                              min={0}
                              className={styles.inputNumber}
                              placeholder={t("Common-QuantityWithDiscount")}
                            />
                          </Form.Item>
                          <Form.Item
                            label={t("Common-DiscountPercent")}
                            name="discountPercent"
                            rules={[
                              {
                                required: true,
                                message: t("Common-DiscountPercent"),
                              },
                            ]}
                          >
                            <InputNumber
                              min={0}
                              className={styles.inputNumber}
                              placeholder={t("Common-DiscountPercent")}
                            />
                          </Form.Item>
                        </div>
                        <div className={styles.formItemsWrapper}>
                          <Form.Item
                            initialValue={false}
                            label={t("Common-AllProducts")}
                            name="allProducts"
                            rules={[
                              {
                                required: true,
                                message: t("Common-AllProducts"),
                              },
                            ]}
                          >
                            <Switch />
                          </Form.Item>
                          <Form.Item
                            initialValue={false}
                            label={t("Common-HighPrice")}
                            name="highPrice"
                            rules={[
                              {
                                required: true,
                                message: t("Common-HighPrice"),
                              },
                            ]}
                          >
                            <Switch />
                          </Form.Item>
                          <Form.Item
                            initialValue={false}
                            label={t("Common-ForTotalArticles")}
                            name="forTotalArticles"
                            rules={[
                              {
                                required: true,
                                message: t("Common-ForTotalArticles"),
                              },
                            ]}
                          >
                            <Switch />
                          </Form.Item>
                        </div>
                      </>
                    );
                  case "progressive":
                    return (
                      <>
                        <AddSkuModal
                          title={t("Common-Skus")}
                          selectedArticles={skusSelected}
                          onAddArticle={handleAddSku}
                          onRemoveArticle={handleRemoveSkus}
                        />
                        <h2 className={styles.subTitle}>{t("Common-Lines")}</h2>
                        <Form.List name="lines">
                          {(fields, { add, remove }, { errors }) => (
                            <>
                              {fields.map(({ key, name, ...restField }) => (
                                <Space
                                  key={key}
                                  className={styles.contactSpace}
                                  align="baseline"
                                >
                                  <Form.Item
                                    {...restField}
                                    label={t("Common-Quantity")}
                                    name={[name, "quantity"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: t("Common-Quantity"),
                                      },
                                    ]}
                                  >
                                    <Input placeholder={t("Common-Quantity")} />
                                  </Form.Item>
                                  <Form.Item
                                    {...restField}
                                    label={t("Common-DiscountPercent")}
                                    name={[name, "discount"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: t("Common-DiscountPercent"),
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder={t("Common-DiscountPercent")}
                                    />
                                  </Form.Item>
                                  <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                  />
                                </Space>
                              ))}
                              <Form.Item>
                                <Button
                                  type="primary"
                                  className={styles.actionsButton}
                                  onClick={() => add()}
                                  icon={<PlusOutlined />}
                                />
                              </Form.Item>
                            </>
                          )}
                        </Form.List>
                      </>
                    );
                  case "buytogether":
                    return (
                      <>
                        <div>
                          <AddSkuModal
                            title={t("Common-Skus")}
                            selectedArticles={skusSelected}
                            onAddArticle={handleAddSku}
                            onRemoveArticle={handleRemoveSkus}
                          />
                          <Form.Item
                            label={t("Common-DiscountPercent")}
                            name="discountFirstList"
                            className={styles.buyTogetherItems}
                            rules={[
                              {
                                required: true,
                                message: t("Common-DiscountPercent"),
                              },
                            ]}
                          >
                            <InputNumber
                              min={0}
                              className={styles.inputNumber}
                              placeholder={t("Common-DiscountPercent")}
                            />
                          </Form.Item>
                        </div>
                        <div>
                          <AddSkuModal
                            title={t("Common-Skus")}
                            selectedArticles={skusSecondSelected}
                            onAddArticle={handleAddSkuSecond}
                            onRemoveArticle={handleRemoveSkusSecond}
                          />
                          <Form.Item
                            label={t("Common-DiscountPercent")}
                            name="discountSecondList"
                            className={styles.buyTogetherItems}
                            rules={[
                              {
                                required: true,
                                message: t("Common-DiscountPercent"),
                              },
                            ]}
                          >
                            <InputNumber
                              min={0}
                              className={styles.inputNumber}
                              placeholder={t("Common-DiscountPercent")}
                            />
                          </Form.Item>
                        </div>
                      </>
                    );
                }
              }}
            </Form.Item>
          </Form>
        </div>
        <div className={styles.formWrapper}>
          <h1 className={styles.subTitle}>{`${t("Common-Conditions")} `}</h1>
          <Form
            name="conditionForm"
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.newForm}
            form={conditionsForm}
          >
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-BranchOffices")}
                name="branchOfficeIds"
                className={styles.doubleItemLine}
              >
                <Select
                  mode="multiple"
                  allowClear
                  onSearch={handleSearchBranchOffices}
                  placeholder={t("Common-SearchByName")}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {getOptions(branchOffices)}
                </Select>
              </Form.Item>
              <Form.Item
                label={t("Common-ClientSegments")}
                name="clientSegmentIds"
                className={styles.doubleItemLine}
              >
                <Select
                  mode="multiple"
                  allowClear
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSearch={handleSearchClientSegments}
                  placeholder={t("Common-SearchByName")}
                >
                  {getOptions(clientSegments)}
                </Select>
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-Categories")}
                name="categoryIds"
                className={styles.doubleItemLine}
              >
                <Select
                  mode="multiple"
                  allowClear
                  showSearch
                  onSearch={handleSearchCategories}
                  placeholder={t("Common-SearchByName")}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {getOptionsCategories(categories)}
                </Select>
              </Form.Item>
              <Form.Item
                label={t("Common-Brands")}
                name="brandIds"
                className={styles.doubleItemLine}
              >
                <Select
                  mode="multiple"
                  allowClear
                  placeholder={t("Common-SearchByName")}
                  onSearch={handleSearchBrands}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {getOptions(brands)}
                </Select>
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-AcceptedPaymentMethods")}
                name="acceptedPaymentMethodIds"
                className={styles.doubleItemLine}
              >
                <Select
                  mode="multiple"
                  allowClear
                  placeholder={t("Common-AcceptedPaymentMethods")}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {getOptions(acceptedPaymentMethods)}
                </Select>
              </Form.Item>
              <Form.Item
                className={styles.doubleItemLine}
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.acceptedPaymentMethodIds !==
                  currentValues.acceptedPaymentMethodIds
                }
              >
                {({ getFieldValue }) => {
                  if (
                    getFieldValue("acceptedPaymentMethodIds") !== undefined &&
                    getFieldValue("acceptedPaymentMethodIds")?.length > 0
                  ) {
                    return (
                      <div className={styles.binesWrapper}>
                        <Form.Item
                          label={t("Common-Bin")}
                          name="bines"
                          className={styles.binesLine}
                        >
                          <Select
                            // style={{ width: 300 }}
                            placeholder={t("Common-EnterABin")}
                            mode="multiple"
                            defaultValue={items}
                            dropdownRender={(menu) => (
                              <>
                                {menu}
                                <Divider style={{ margin: "8px 0" }} />
                                <Space
                                  align="center"
                                  style={{ padding: "0 8px 4px" }}
                                >
                                  <Input
                                    placeholder={"Ej:542104"}
                                    type="number"
                                    maxLength={6}
                                    minLength={6}
                                    value={bin}
                                    onChange={onBinChange}
                                  />
                                  <Typography.Link
                                    onClick={addItem}
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    <PlusOutlined />
                                  </Typography.Link>
                                </Space>
                              </>
                            )}
                          >
                            {items.map((item) => (
                              <Select.Option value={item} key={item}>
                                {item}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label={t("Common-BinRangeMin")}
                          name="binMin"
                          className={styles.binesLine}
                        >
                          <InputNumber
                            min={0}
                            // className={styles.inputNumber}
                            placeholder={t("Common-BinRangeMin")}
                          />
                        </Form.Item>
                        <Form.Item
                          label={t("Common-BinRangeMax")}
                          name="binMax"
                          className={styles.binesLine}
                        >
                          <InputNumber
                            min={0}
                            // className={styles.inputNumber}
                            placeholder={t("Common-BinRangeMax")}
                          />
                        </Form.Item>
                      </div>
                    );
                  }
                }}
              </Form.Item>
            </div>
          </Form>
        </div>
        <div className={styles.formWrapper}>
          <h1 className={styles.subTitle}>{`${t("Common-Restrictions")} `}</h1>
          <Form
            name="restrictionsForm"
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.newForm}
            form={restrictionsForm}
          >
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-QuantityApplications")}
                name="quantityApplications"
                className={styles.doubleItemLine}
              >
                <InputNumber
                  min={0}
                  className={styles.inputNumber}
                  placeholder={t("Common-QuantityApplications")}
                />
              </Form.Item>
              <Form.Item
                label={t("Common-QuantityPerBranchOffice")}
                name="quantityPerBranchOffice"
                className={styles.doubleItemLine}
              >
                <InputNumber
                  min={0}
                  className={styles.inputNumber}
                  placeholder={t("Common-QuantityPerBranchOffice")}
                />
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-QuantityPerClient")}
                name="quantityPerClient"
                className={styles.doubleItemLine}
              >
                <InputNumber
                  min={0}
                  className={styles.inputNumber}
                  placeholder={t("Common-QuantityPerClient")}
                />
              </Form.Item>
              <Form.Item
                label={t("Common-QuantityPerArticle")}
                name="quantityPerArticle"
                className={styles.doubleItemLine}
              >
                <InputNumber
                  min={0}
                  className={styles.inputNumber}
                  placeholder={t("Common-QuantityPerArticle")}
                />
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-MinimumTicket")}
                name="minimumTicket"
                className={styles.doubleItemLine}
              >
                <InputNumber
                  min={0}
                  className={styles.inputNumber}
                  placeholder={t("Common-MinimumTicket")}
                />
              </Form.Item>
              <Form.Item
                label={t("Common-MaximumTicket")}
                name="maximumTicket"
                className={styles.doubleItemLine}
              >
                <InputNumber
                  min={0}
                  className={styles.inputNumber}
                  placeholder={t("Common-MaximumTicket")}
                />
              </Form.Item>
            </div>
          </Form>
        </div>
        <div className={styles.formWrapper}>
          <h1 className={styles.subTitle}>{`${t("Common-MoreOptions")} `}</h1>
          <Form
            name="extraForm"
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.newForm}
            form={extraForm}
          >
            <Form.Item
              initialValue={false}
              label={t("Common-AccumulateOtherPromos")}
              name="accumulateOtherPromotions"
              rules={[
                {
                  required: true,
                  message: t("Common-AccumulateOtherPromos"),
                },
              ]}
            >
              <Switch />
            </Form.Item>
            <Form.Item
              initialValue={false}
              label={t("Common-DistributeLines")}
              name="distributeLines"
              rules={[
                {
                  required: true,
                  message: t("Common-DistributeLines"),
                },
              ]}
            >
              <Switch />
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};
export default NewPromotion;

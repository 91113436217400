import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  getAllEndPricingTypes,
  getAllPricingTypes,
  createPricing,
  getCategoriesFiltered,
  getBranchOfficesFiltered,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";
import AddArticleModal from "../../components/addarticlemodal/AddArticleModal";

import {
  Form,
  message,
  Select,
  Button,
  Input,
  Switch,
  DatePicker,
  InputNumber,
} from "antd";

import styles from "./NewPricing.module.scss";

const NewPricing = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [branchOffices, setBranchOffices] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [pricingTypes, setPricingTypes] = useState<any[]>([]);
  const [endPricingTypes, setEndPricingTypes] = useState<any[]>([]);
  const [articlesSelected, setArticlesSelected] = useState<any[]>([]);

  const [newPricingForm] = Form.useForm();
  const [conditionsForm] = Form.useForm();
  const [behaviourForm] = Form.useForm();

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_PRICINGS)) {
      handleFetchPricingTypes();
      handleFetchEndPricingTypes();
    }
  }, []);

  const handleSearchCategories = async (value: string) => {
    if (value.length > 2) {
      const response = await getCategoriesFiltered(value);
      setCategories(response);
    } else setCategories([]);
  };

  const handleSearchBranchOffices = async (value: string) => {
    if (value.length > 1) {
      const response = await getBranchOfficesFiltered(0, { name: value });
      setBranchOffices(response.branchOffices);
    } else setBranchOffices([]);
  };

  const handleFetchEndPricingTypes = async () => {
    const response = await getAllEndPricingTypes();
    setEndPricingTypes(response);
  };

  const handleFetchPricingTypes = async () => {
    const response = await getAllPricingTypes();
    setPricingTypes(response);
  };

  const getOptions = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });

    return options;
  };

  const getOptionsCategories = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      const parentsString = item?.parents
          ? item.parents.filter(Boolean).join(" - ")
          : "";
      const categoryName = item?.name || "";

      const categoryTree = parentsString
          ? `${parentsString} - ${categoryName}`
          : categoryName;

      options.push(
          <Select.Option value={item.id} key={item.id}>
            {categoryTree}
          </Select.Option>
      );
    });

    return options;
  };

  const getOptionsDescription = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.description}
        </Select.Option>
      );
    });

    return options;
  };

  const getOptionsEndTypes = (items: any) => {
    const options: any[] = [];
    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.value} key={item.value}>
          {item.description_es}
        </Select.Option>
      );
    });

    return options;
  };

  const handleCreate = async () => {
    await newPricingForm.validateFields();
    await conditionsForm.validateFields();
    await behaviourForm.validateFields();

    setLoading(true);

    const data = newPricingForm.getFieldsValue();
    const conditions = conditionsForm.getFieldsValue();
    const behaviour = behaviourForm.getFieldsValue();

    const { name, pricingTypeId } = data;

    const { branchOfficeIds, categoryIds, value, percent, allConditions } =
      conditions;
    const articlesIds = articlesSelected.map((article) => article.id);

    const { date, endPrice, newPrice } = behaviour;

    const startDate = date ? date[0]?.format("YYYY-MM-DDTHH:mm:ss") : null;
    const endDate = date ? date[1]?.format("YYYY-MM-DDTHH:mm:ss") : null;

    let pricingInfo: any = {
      name,
      pricingTypeId,
      startDate,
      endDate,
      value,
      percent: percent ?? false,
      allConditions,
      endPrice,
      newPrice,
      branchOfficeIds: branchOfficeIds ?? [],
      articlesIds: articlesIds ?? [],
      categoryIds: categoryIds ?? [],
    };

    const response = await createPricing(pricingInfo);

    if (response) {
      message.success(t("Common-CreateSuccessMessage"));
      newPricingForm.resetFields();
      navigate("/pricings");
    } else message.error(t("Common-ErrorMessage"));

    setLoading(false);
  };

  const handleAddArticle = (articlesToAdd: any) => {
    const newArticles: any[] = [];
    articlesToAdd.forEach((articleToAdd: any) => {
      if (
        articlesSelected.find(
          (article: any) => article.id === articleToAdd.id
        ) === undefined
      )
        newArticles.push(articleToAdd);
    });
    setArticlesSelected([...articlesSelected, ...newArticles]);
  };

  const handleRemoveArticle = (articleRemove: any) => {
    const articlesWithoutRemoved = articlesSelected.filter(
      (article) => article.id !== articleRemove.id
    );

    setArticlesSelected(articlesWithoutRemoved);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-NewPricing")}</h1>
        <Button
          onClick={handleCreate}
          className={styles.saveButton}
          type="primary"
          loading={loading}
        >
          {t("Common-Add")}
        </Button>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.formWrapper}>
          <h1 className={styles.subTitle}>{`${t("Common-PricingEvent")} `}</h1>
          <Form
            name="newPricing"
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.newForm}
            form={newPricingForm}
          >
            <Form.Item
              label={t("Common-Name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("Common-Name"),
                },
              ]}
            >
              <Input placeholder={t("Common-Name")} />
            </Form.Item>
            <Form.Item
              label={t("Common-PricingType")}
              name="pricingTypeId"
              className={styles.doubleItemLine}
              rules={[
                {
                  required: true,
                  message: t("Common-PricingType"),
                },
              ]}
            >
              <Select
                allowClear
                placeholder={t("Common-PricingType")}
                filterOption={(input: string, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {getOptionsDescription(pricingTypes)}
              </Select>
            </Form.Item>
          </Form>
        </div>
        <div className={styles.formWrapper}>
          <h1 className={styles.subTitle}>{`${t("Common-Conditions")} `}</h1>
          <Form
            name="conditionForm"
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.newForm}
            form={conditionsForm}
          >
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-Percentage")}
                name="percent"
                className={styles.doubleItemLine}
              >
                <Switch defaultChecked={false} />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: t("Common-Value"),
                  },
                ]}
                label={t("Common-Value")}
                name="value"
                className={styles.doubleItemLine}
              >
                <InputNumber
                  className={styles.inputNumber}
                  placeholder={t("Common-Value")}
                />
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-BranchOffices")}
                name="branchOfficeIds"
                className={styles.doubleItemLine}
              >
                <Select
                  mode="multiple"
                  allowClear
                  onSearch={handleSearchBranchOffices}
                  placeholder={t("Common-SearchByName")}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {getOptions(branchOffices)}
                </Select>
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-Categories")}
                name="categoryIds"
                className={styles.doubleItemLine}
              >
                <Select
                  mode="multiple"
                  allowClear
                  onSearch={handleSearchCategories}
                  placeholder={t("Common-SearchByName")}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {getOptionsCategories(categories)}
                </Select>
              </Form.Item>
              <Form.Item
                label={t("Common-ApplyAllTogether")}
                name="allConditions"
                initialValue={false}
                className={styles.doubleItemLine}
              >
                <Switch defaultChecked={false} />
              </Form.Item>
            </div>
            <AddArticleModal
              selectedArticles={articlesSelected}
              onAddArticle={handleAddArticle}
              onRemoveArticle={handleRemoveArticle}
            />
          </Form>
        </div>
        <div className={styles.formWrapper}>
          <h1 className={styles.subTitle}>{`${t("Common-Behaviour")} `}</h1>
          <Form
            name="behaviourForm"
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.newForm}
            form={behaviourForm}
          >
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-Type")}
                name="endPrice"
                className={styles.doubleItemLine}
                rules={[
                  {
                    required: true,
                    message: t("Common-Type"),
                  },
                ]}
              >
                <Select
                  allowClear
                  placeholder={t("Common-Type")}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {getOptionsEndTypes(endPricingTypes)}
                </Select>
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.endPrice !== currentValues.endPrice
                }
              >
                {({ getFieldValue }) => {
                  switch (getFieldValue("endPrice")) {
                    case "NEW_PRICE":
                      return (
                        <Form.Item
                          label={t("Common-NewPrice")}
                          name="newPrice"
                          className={styles.doubleItemLine}
                          rules={[
                            {
                              required: true,
                              message: t("Common-NewPrice"),
                            },
                          ]}
                        >
                          <Input placeholder={t("Common-NewPrice")} />
                        </Form.Item>
                      );
                  }
                }}
              </Form.Item>

              {/*<Form.Item*/}
              {/*  label={t("Common-NewPrice")}*/}
              {/*  name="newPrice"*/}
              {/*  className={styles.doubleItemLine}*/}
              {/*>*/}
              {/*  <Input placeholder={t("Common-NewPrice")} />*/}
              {/*</Form.Item>*/}
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item label={t("Common-Date")} name="date">
                <DatePicker.RangePicker
                  showTime={{ format: "HH:mm" }}
                  format="YYYY-MM-DD HH:mm"
                  allowEmpty={[false, true]}
                  className={styles.dateSelector}
                  // onChange={onChange}
                  // onOk={onOk}
                />
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};
export default NewPricing;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Form, Input, Select, Spin, Switch } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import styles from "./NewArticleGroupMockup.module.scss";
import { getArticlesFiltered } from "../../../api/Services";

const NewArticleGroupMockup = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [loadingArticles, setLoadingArticles] = useState(false);
  const [articleOptions, setArticleOptions] = useState<any[]>([]);

  const [form] = Form.useForm();

  useEffect(() => {
    handleSearchArticles("");
  }, []);

  const handleSearchArticles = async (value: string) => {
    setLoadingArticles(true);
    const response = await getArticlesFiltered(0, { articleName: value });
    const options = response.articles.map((item: any) => ({
      value: item.id,
      text: item.name,
    }));
    setArticleOptions(options);
    setLoadingArticles(false);
  };

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-NewArticleGroup")}</h1>
        <Button
          // onClick={handleCreateCategory}
          className={styles.saveButton}
          type="primary"
        >
          {t("Common-Add")}
        </Button>
      </div>
      <div className={styles.formWrapper}>
        <Form
          name="articleGroup"
          layout="vertical"
          initialValues={{ remember: true }}
          autoComplete="off"
          className={styles.newForm}
        >
          <Form.Item
            label={t("Common-Name")}
            name="name"
            rules={[
              {
                required: true,
                message: t("Common-Name"),
              },
            ]}
          >
            <Input placeholder={t("Common-Name")} />
          </Form.Item>
          <Form.Item
            label={t("Common-Description")}
            name="description"
            rules={[
              {
                required: true,
                message: t("Common-Description"),
              },
            ]}
          >
            <Input placeholder={t("Common-Description")} />
          </Form.Item>
          <Form.Item
            initialValue={false}
            label={t("Common-Active")}
            name="enable"
            rules={[
              {
                required: true,
                message: t("Common-Active"),
              },
            ]}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label={t("Common-Articles")}
            name="articles"
            rules={[
              {
                required: true,
                message: t("Common-Articles"),
              },
            ]}
          >
            <Select
              showSearch
              mode="multiple"
              allowClear
              placeholder={t("Common-SearchArticleTextPlaceholder")}
              loading={loadingArticles}
              className={styles.filterSelect}
              showArrow={false}
              filterOption={false}
              onSearch={handleSearchArticles}
              options={(articleOptions || []).map((d: any) => ({
                value: d.value,
                label: d.text,
              }))}
            />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
export default NewArticleGroupMockup;

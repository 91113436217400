import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  deleteClientSegment,
  getClientSegments,
  cloneClientSegment,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";

import { Table, Button, message, Space, Modal } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  PlusOutlined,
  CopyOutlined,
} from "@ant-design/icons";

import styles from "./ClientSegments.module.scss";

const ClientSegmentsmockup = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalSize, setTotalSize] = useState(0);
  const [actualPage, setActualPage] = useState(0);

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_CLIENTS)) {
      // handleFetchClientSegments();
    }
  }, []);

  const handleFetchClientSegments = async (pagination = actualPage) => {
    const response = await getClientSegments(pagination);
    const allSegments = response.clientSegments;
    const segmentsWithKey: any[] = [];

    allSegments.forEach((segment: any) => {
      segment.key = segment.client.id;
      segmentsWithKey.push(segment);
    });
    setData(segmentsWithKey);
    setTotalSize(response.totalElements);
    setLoading(false);
  };

  const showDeleteConfirm = (clientSegmentId: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDeleteSegment(clientSegmentId);
      },
    });
  };

  const handleDeleteSegment = async (clientSegmentId: number) => {
    setLoading(true);
    const deleteStatus = await deleteClientSegment(clientSegmentId);
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));
    await handleFetchClientSegments();
    setLoading(false);
  };

  const handlePagination = (page: number) => {
    handleFetchClientSegments(page - 1);
    setActualPage(page);
  };

  const cloneSegment = async (segmentId: number) => {
    setLoading(true);
    const response = await cloneClientSegment(segmentId);
    if (response.success) {
      navigate(`/promotions/clientsegments/${response.segment.id}/view`);
    } else message.success(t("Common-ErrorMessage"));
  };

  const columns = [
    {
      title: t("Common-Name"),
      key: "name",
      render: (segment: any) => segment.client.name,
    },
    {
      title: t("Common-Description"),
      key: "description",
      render: (segment: any) => segment.client.description,
    },
    {
      title: t("Common-Quantity"),
      dataIndex: "size",
    },
    {
      title: t("Common-TicketAverage"),
    },
    {
      title: t("Common-TransactionsAverage"),
    },
    {
      title: t("Common-SpentAverage"),
    },
    {
      title: t("Common-Status"),
    },
    {
      title: t("Common-CreatedDate"),
    },
    {
      title: t("Common-Origin"),
    },
    {
      title: t("Common-Analysis"),
    },
    // {
    //   title: t("Common-Type"),
    //   key: "type",
    //   render: (segment: any) => segment.client.type.description_es,
    // },
    // {
    //   title: t("Common-MinAge"),
    //   key: "minAge",
    //   render: (segment: any) => segment.client.minAge,
    // },
    // {
    //   title: t("Common-MaxAge"),
    //   key: "maxAge",
    //   render: (segment: any) => segment.client.maxAge,
    // },
    // {
    //   title: t("Common-Sex"),
    //   key: "sex",
    //   render: (segment: any) => segment.client.sex?.description_es,
    // },
    // {
    //   title: t("Common-Clients"),
    //   dataIndex: "size",
    //   key: "clients",
    // },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (segment: any) => (
        <Space size="middle">
          {hasPermission(privilegesEnum.WRITE_CLIENTS) ? (
            <>
              <CopyOutlined
                href={`/promotions/clientsegments/${segment.client.id}/view`}
                onClick={() => {
                  cloneSegment(segment.client.id);
                }}
              />
              <EditOutlined
                href={`/promotions/clientsegments/${segment.client.id}/view`}
                onClick={() => {
                  window.location.href = `/promotions/clientsegments/${segment.client.id}/view`;
                }}
              />
            </>
          ) : (
            <EyeOutlined
              href={`/clientsegments/${segment.client.id}/view`}
              onClick={() => {
                window.location.href = `/promotions/clientsegments/${segment.client.id}/view`;
              }}
            />
          )}
          {hasPermission(privilegesEnum.DELETE_CLIENTS) && (
            <DeleteOutlined
              onClick={() => {
                showDeleteConfirm(segment.client.id);
              }}
            />
          )}
        </Space>
      ),
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-ClientSegment")}</h1>
        {hasPermission(privilegesEnum.WRITE_CLIENTS) && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              href="/promotions/clientsegments/create"
              className={styles.newButton}
              type="primary"
              icon={<PlusOutlined />}
            />
            <Button
              // onClick={handleCancelButton}
              className={styles.importButton}
              type="primary"
            >
              {t("Common-Import")}
            </Button>
          </div>
        )}
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.tableWrapper}>
          <Table
            size="small"
            className={styles.table}
            loading={loading}
            columns={columns}
            dataSource={data}
            pagination={{
              total: totalSize,
              showSizeChanger: false,
              onChange: handlePagination,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ClientSegmentsmockup;

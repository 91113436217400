import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    createClientSegment,
    getAllCitiesWithExistingClients,
    getAllCountriesWithExistingClients,
    getAllSexTypes,
    getBranchOfficesFiltered,
    getBrandsFiltered,
    getCategoriesFiltered,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";

import {
    Form,
    message,
    Select,
    Button,
    Input,
    InputNumber,
    Spin,
    Switch,
    Table,
    Upload,
    Menu,
    Typography,
    DatePicker,
} from "antd";

import styles from "./NewClientSegmentmockup.module.scss";
import {
    DeleteOutlined,
    EyeOutlined,
    LoadingOutlined,
    UploadOutlined,
} from "@ant-design/icons";
import AddArticleModal from "../../components/addarticlemodal/AddArticleModal";

const NewClientSegmentmockup = () => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [countries, setCountries] = useState<any[]>([]);
    const [cities, setCities] = useState<any[]>([]);
    const [sexTypes, setSexTypes] = useState<any[]>([]);
    const [branchOffices, setBranchOffices] = useState<any[]>([]);
    const [categories, setCategories] = useState<any[]>([]);
    const [brands, setBrands] = useState<any[]>([]);
    const [articlesSelected, setArticlesSelected] = useState<any[]>([]);

    const [form] = Form.useForm();

    useEffect(() => {
        if (hasPermission(privilegesEnum.WRITE_CLIENTS)) {
            handleFetchCountries();
            handleFetchSexTypes();
            handleFetchCities();
        }
    }, []);

    const handleAddArticle = (articlesToAdd: any) => {
        const newArticles: any[] = [];
        articlesToAdd.forEach((articleToAdd: any) => {
            if (
                articlesSelected.find(
                    (article: any) => article.id === articleToAdd.id
                ) === undefined
            )
                newArticles.push(articleToAdd);
        });
        setArticlesSelected([...articlesSelected, ...newArticles]);
    };

    const handleRemoveArticle = (articleRemove: any) => {
        const articlesWithoutRemoved = articlesSelected.filter(
            (article) => article.id !== articleRemove.id
        );

        setArticlesSelected(articlesWithoutRemoved);
    };

    const handleFetchCountries: () => any = async () => {
        const response = await getAllCountriesWithExistingClients();

        setCountries(response);
    };

    const handleFetchCities: () => any = async () => {
        const response = await getAllCitiesWithExistingClients();

        setCities(response);
    };

    const handleFetchSexTypes = async () => {
        const response = await getAllSexTypes();
        setSexTypes(response);
    };

    const handleSearchCategories = async (value: string) => {
        if (value.length > 2) {
            const response = await getCategoriesFiltered(value);
            setCategories(response);
        } else setCategories([]);
    };

    const handleSearchBranchOffices = async (value: string) => {
        if (value.length > 1) {
            const response = await getBranchOfficesFiltered(0, { name: value });
            setBranchOffices(response.branchOffices);
        } else setBranchOffices([]);
    };

    const handleSearchBrands = async (value: string) => {
        if (value.length > 2) {
            const response = await getBrandsFiltered(value);
            setBrands(response);
        } else setBrands([]);
    };

    const getSexTypesOptions = (items: any) => {
        const options: any[] = [];

        items?.forEach(function (item: any) {
            options.push(
                <Select.Option value={item.value} key={item.value}>
                    {item.description_es}
                </Select.Option>
            );
        });
        return options;
    };

    const getCountriesOptions = (items: any) => {
        const options: any[] = [];

        items?.forEach(function (item: any) {
            options.push(
                <Select.Option value={item.id} key={item.id}>
                    {item.name}
                </Select.Option>
            );
        });
        return options;
    };

    const getOptions = (items: any) => {
        const options: any[] = [];

        items?.forEach(function (item: any) {
            options.push(
                <Select.Option value={item.id} key={item.id}>
                    {item.name}
                </Select.Option>
            );
        });

        return options;
    };
    const getOptionsCategories = (items: any) => {
        const options: any[] = [];

        items?.forEach(function (item: any) {
            const parentsString = item?.parents
                ? item.parents.filter(Boolean).join(" - ")
                : "";
            const categoryName = item?.name || "";

            const categoryTree = parentsString
                ? `${parentsString} - ${categoryName}`
                : categoryName;

            options.push(
                <Select.Option value={item.id} key={item.id}>
                    {categoryTree}
                </Select.Option>
            );
        });

        return options;
    };

    const handleCreateClientSegment = async () => {
        setLoading(true);

        const data = form.getFieldsValue();
        const articleList = articlesSelected.map((article) => article.id);

        let response = await createClientSegment(
            data.name,
            data.description ?? "",
            data.minAge,
            data.maxAge,
            data.sex,
            data.countryIds ?? [],
            data.stateIds ?? [],
            articleList ?? [],
            data.brandsIds ?? [],
            data.categoriesIds ?? [],
            data.branchOfficesIds ?? []
        );
        if (response) {
            message.success(t("Common-CreateSuccessMessage"));
            form.resetFields();
            window.location.href = "/promotions/clientsegments";
        } else message.error(t("Common-CreateErrorMessage"));

        setLoading(false);
    };

    const clientColumns = [
        {
            title: t("Common-Identifier"),
            key: "identifier",
        },
        {
            title: t("Common-BirthDate"),
            key: "birthdate",
        },
        {
            title: t("Common-RegisterDate"),
            key: "registerDate",
        },
        {
            title: t("Common-Level"),
            key: "level",
        },
        {
            title: t("Common-Segments"),
            key: "segments",
        },
        {
            title: t("Common-TotalAccumulationUnits"),
            key: "totalAccumulationUnits",
        },
    ];

    return loading ? (
        <Spin
            indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
            delay={500}
        />
    ) : (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <h1 className={styles.title}>{t("Common-NewClientSegment")}</h1>
                <Button
                    onClick={handleCreateClientSegment}
                    className={styles.saveButton}
                    type="primary"
                >
                    {t("Common-Add")}
                </Button>
            </div>
            <div className={styles.contentWrapper}>
                <div className={styles.newWrapper}>
                    <Form
                        name="newSegment"
                        layout="vertical"
                        initialValues={{ remember: true }}
                        onFinish={handleCreateClientSegment}
                        form={form}
                        autoComplete="off"
                        className={styles.formNew}
                    >
                        <Form.Item
                            label={t("Common-Name")}
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: t("Common-NameNotEntered"),
                                },
                            ]}
                        >
                            <Input placeholder={t("Common-Name")} />
                        </Form.Item>
                        <Form.Item label={t("Common-Description")} name="description">
                            <Input placeholder={t("Common-Description")} />
                        </Form.Item>
                        <Form.Item
                            initialValue={true}
                            label={t("Common-Active")}
                            name="enable"
                        >
                            <Switch />
                            <Form.Item
                                label={t("Common-Type")}
                                name="type"
                                rules={[
                                    {
                                        required: true,
                                        message: t("Common-Type"),
                                    },
                                ]}
                            >
                                <Select placeholder={t("Common-Type")} allowClear>
                                    <Select.Option value="static">Estático</Select.Option>
                                    <Select.Option value="dynamic">Dinámico</Select.Option>
                                </Select>
                            </Form.Item>
                        </Form.Item>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                                prevValues.type !== currentValues.type
                            }
                        >
                            {({ getFieldValue }) => {
                                switch (getFieldValue("type")) {
                                    case "dynamic":
                                        return (
                                            <>
                                                <Form.Item
                                                    className={styles.newItem}
                                                    label={t("Common-Sex")}
                                                    name="sex"
                                                >
                                                    <Select placeholder={t("Common-Sex")} allowClear>
                                                        {getSexTypesOptions(sexTypes)}
                                                    </Select>
                                                </Form.Item>
                                                <div className={styles.formItemsWrapper}>
                                                    <Form.Item
                                                        className={styles.doubleItemLine}
                                                        label={t("Common-MinAge")}
                                                        name="minAge"
                                                    >
                                                        <InputNumber
                                                            className={styles.inputNumber}
                                                            min={0}
                                                            placeholder={t("Common-MinAge")}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        className={styles.doubleItemLine}
                                                        label={t("Common-MaxAge")}
                                                        name="maxAge"
                                                    >
                                                        <InputNumber
                                                            className={styles.inputNumber}
                                                            min={0}
                                                            placeholder={t("Common-MaxAge")}
                                                        />
                                                    </Form.Item>
                                                </div>
                                                <div className={styles.formItemsWrapper}>
                                                    <Form.Item
                                                        label={t("Common-Country")}
                                                        name="countryIds"
                                                        className={styles.doubleItemLine}
                                                    >
                                                        <Select
                                                            mode="multiple"
                                                            allowClear
                                                            placeholder={t("Common-SelectOneOrMore")}
                                                            // onChange={handleChange}
                                                            className={styles.multipleSelect}
                                                            filterOption={(input: string, option: any) =>
                                                                option.children
                                                                    .toLowerCase()
                                                                    .indexOf(input.toLowerCase()) >= 0
                                                            }
                                                        >
                                                            {getCountriesOptions(countries)}
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item
                                                        label={t("Common-City")}
                                                        name="stateIds"
                                                        className={styles.doubleItemLine}
                                                    >
                                                        <Select
                                                            mode="multiple"
                                                            allowClear
                                                            placeholder={t("Common-SelectOneOrMore")}
                                                            // onChange={handleChange}
                                                            className={styles.multipleSelect}
                                                            filterOption={(input: string, option: any) =>
                                                                option.children
                                                                    .toLowerCase()
                                                                    .indexOf(input.toLowerCase()) >= 0
                                                            }
                                                        >
                                                            {getCountriesOptions(cities)}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                                <div className={styles.formItemsWrapper}>
                                                    <Form.Item
                                                        label={t("Common-Brands")}
                                                        name="brandsIds"
                                                        className={styles.doubleItemLine}
                                                    >
                                                        <Select
                                                            mode="multiple"
                                                            allowClear
                                                            placeholder={t("Common-SearchByName")}
                                                            onSearch={handleSearchBrands}
                                                            filterOption={(input: string, option: any) =>
                                                                option.children
                                                                    .toLowerCase()
                                                                    .indexOf(input.toLowerCase()) >= 0
                                                            }
                                                        >
                                                            {getOptions(brands)}
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item
                                                        label={t("Common-Categories")}
                                                        name="categoriesIds"
                                                        className={styles.doubleItemLine}
                                                    >
                                                        <Select
                                                            mode="multiple"
                                                            allowClear
                                                            onSearch={handleSearchCategories}
                                                            placeholder={t("Common-SearchByName")}
                                                            filterOption={(input: string, option: any) =>
                                                                option.children
                                                                    .toLowerCase()
                                                                    .indexOf(input.toLowerCase()) >= 0
                                                            }
                                                        >
                                                            {getOptionsCategories(categories)}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                                <div className={styles.formItemsWrapper}>
                                                    <Form.Item
                                                        label={t("Common-BranchOffices")}
                                                        name="branchOfficesIds"
                                                        className={styles.doubleItemLine}
                                                    >
                                                        <Select
                                                            mode="multiple"
                                                            allowClear
                                                            onSearch={handleSearchBranchOffices}
                                                            placeholder={t("Common-SearchByName")}
                                                            filterOption={(input: string, option: any) =>
                                                                option.children
                                                                    .toLowerCase()
                                                                    .indexOf(input.toLowerCase()) >= 0
                                                            }
                                                        >
                                                            {getOptions(branchOffices)}
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item
                                                        className={styles.doubleItemLine}
                                                        label={t("Common-TicketAverage")}
                                                        name="maxAge"
                                                    >
                                                        <InputNumber
                                                            className={styles.inputNumber}
                                                            min={0}
                                                            placeholder={t("Common-TicketAverage")}
                                                        />
                                                    </Form.Item>
                                                </div>
                                                <div className={styles.formItemsWrapper}>
                                                    <Form.Item
                                                        className={styles.doubleItemLine}
                                                        label={"Total gasto"}
                                                        name=""
                                                    >
                                                        <InputNumber
                                                            className={styles.inputNumber}
                                                            min={0}
                                                            placeholder={t("Total gasto")}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        className={styles.doubleItemLine}
                                                        label={t("Common-Level")}
                                                        name="level"
                                                    >
                                                        <Input
                                                            className={styles.inputNumber}
                                                            min={0}
                                                            placeholder={t("Common-Level")}
                                                        />
                                                    </Form.Item>
                                                </div>
                                                <div className={styles.formItemsWrapper}>
                                                    <Form.Item
                                                        className={styles.doubleItemLine}
                                                        label={"Número de compras en un período de tiempo"}
                                                        name=""
                                                    >
                                                        <InputNumber
                                                            className={styles.inputNumber}
                                                            min={0}
                                                            placeholder={t(
                                                                "Número de compras en un período de tiempo"
                                                            )}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        className={styles.doubleItemLine}
                                                        label={"Compras en los últimos X días"}
                                                        name=""
                                                    >
                                                        <Input
                                                            className={styles.inputNumber}
                                                            min={0}
                                                            placeholder={"Compras en los últimos X días"}
                                                        />
                                                    </Form.Item>
                                                </div>

                                                <div className={styles.formItemsWrapper}>
                                                    <Form.Item
                                                        className={styles.doubleItemLine}
                                                        label={"Compras desde"}
                                                        name=""
                                                    >
                                                        <DatePicker
                                                            className={styles.inputNumber}
                                                            placeholder={t("Compras hasta")}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        className={styles.doubleItemLine}
                                                        label={"Compras hasta"}
                                                        name=""
                                                    >
                                                        <DatePicker
                                                            className={styles.inputNumber}
                                                            placeholder={"Compras hasta"}
                                                        />
                                                    </Form.Item>
                                                </div>
                                                <div className={styles.formItemsWrapper}>
                                                    <Form.Item
                                                        className={styles.doubleItemLine}
                                                        label={"Fecha registro desde"}
                                                        name=""
                                                    >
                                                        <DatePicker
                                                            className={styles.inputNumber}
                                                            placeholder={t("Fecha registro desde")}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        className={styles.doubleItemLine}
                                                        label={"Fecha registro hasta"}
                                                        name=""
                                                    >
                                                        <DatePicker
                                                            className={styles.inputNumber}
                                                            placeholder={"Fecha registro hasta"}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </>
                                        );
                                    case "static":
                                        return (
                                            <>
                                                <Form.Item label={"Listado de clientes"} name="csv">
                                                    <Upload>
                                                        <Button icon={<UploadOutlined />}>
                                                            Click para subir archivo
                                                        </Button>
                                                    </Upload>
                                                </Form.Item>
                                                <Typography.Text className={styles.subtitle}>
                                                    {t("Common-Clients")}
                                                </Typography.Text>
                                                <div className={styles.filtersWrapper}>
                                                    <Input
                                                        placeholder={t("Common-Id")}
                                                        className={styles.filterInput}
                                                    />
                                                    <Input
                                                        placeholder={t("Common-Age")}
                                                        className={styles.filterInput}
                                                    />
                                                    <Select
                                                        className={styles.filterInput}
                                                        placeholder={t("Common-Sex")}
                                                        allowClear
                                                    >
                                                        {getSexTypesOptions(sexTypes)}
                                                    </Select>
                                                </div>
                                                <Table
                                                    size="small"
                                                    className={styles.table}
                                                    loading={loading}
                                                    columns={clientColumns}
                                                    // dataSource={data}
                                                    // pagination={{
                                                    //   total: totalSize,
                                                    //   showSizeChanger: false,
                                                    //   onChange: handlePagination,
                                                    // }}
                                                />
                                            </>
                                        );
                                }
                            }}
                        </Form.Item>
                        <AddArticleModal
                            selectedArticles={articlesSelected}
                            onAddArticle={handleAddArticle}
                            onRemoveArticle={handleRemoveArticle}
                        />
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default NewClientSegmentmockup;

import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Dropdown,
  Menu,
  Select,
  Badge,
  Typography,
  Divider,
  Modal,
  Input,
  message,
  Drawer,
  Button,
  Form,
} from "antd";
import {
  HomeOutlined,
  WalletOutlined,
  SettingOutlined,
  DollarCircleOutlined,
  ShoppingCartOutlined,
  FormOutlined,
  BellOutlined,
  UserOutlined,
} from "@ant-design/icons";
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { BusinessUniteContext, SelectedMenuOptions } from "../laoyut/Layout";
import {
  editUserPassword,
  editUserProfile,
  getBusinessUnitsByName,
  getNotifications,
  getOrganizations,
  getUser,
  loginSuperAdmin,
} from "../../api/Services";

import styles from "./NavBar.module.scss";
import { getSuperAdmin } from "../../api/Helper";
import NotificationItem from "../notificationitem/NotificationItem";

type NavBarProps = {
  onChangeMenu: (value: SelectedMenuOptions) => void;
  selectedMenu: SelectedMenuOptions;
};
const NavBar: FunctionComponent<NavBarProps> = ({
  onChangeMenu,
  selectedMenu,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [userForm] = Form.useForm();

  const [businessUnitsOptions, setBusinessUnitsOptions] = useState<any[]>([]);
  const [organizationOptions, setOrganizationOptions] = useState<any[]>([]);
  const [hasOneBusinessUnit, setHasOneBusinessUnit] = useState(false);
  const [loadingBusinessUnit, setLoadingBusinessUnit] = useState(false);
  const [user, setUser] = useState<any>();
  const [organizationSelected, setOrganizationSelected] = useState<any>(
    localStorage.getItem("organizationId")
      ? parseInt(localStorage.getItem("organizationId")!)
      : null
  );
  const [organizationNameSelected, setOrganizationNameSelected] = useState("");
  const [isChangePasswordModalVisible, setIsChangePasswordModalVisible] =
    useState(false);
  const [isMyProfileVisible, setIsMyProfileVisible] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [actualPassword, setActualPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isLoadingChangePassword, setIsLoadingChangePassword] = useState(false);
  const [isLoadingEditProfile, setIsLoadingEditProfile] = useState(false);

  const {
    contextValue: businessUnitSelected,
    updateContextValue: updateBusinessUnitSelected,
  } = useContext(BusinessUniteContext);

  useEffect(() => {
    const validateSuperAdmin = async () => {
      const isUserSuperAdmin = await getSuperAdmin();
      setIsSuperAdmin(isUserSuperAdmin);

      if (isUserSuperAdmin) {
        const organizations = await getOrganizations();
        const options = organizations.map((item: any) => ({
          value: item.id,
          text: item.name,
        }));
        if (organizationSelected && organizations) {
          const currentOrg = organizations.find(
            (org) => org.id == organizationSelected
          );
          setOrganizationNameSelected(currentOrg?.name ?? "");
        }

        setOrganizationOptions(options);
      }
    };

    validateSuperAdmin();
    handleFetchNotifications();
    handleFetchUser();
  }, []);

  const handleFetchNotifications = async (page = 1) => {
    const response = await getNotifications(page);
    setUnreadNotifications(
      response.notifications.filter((not: any) => not.status === "UNREAD")
        .length
    );
    setNotifications(response.notifications);
  };

  const handleFetchUser = async () => {
    const response = await getUser();
    if (response.success) {
      setUser(response.user);
    }
  };

  const handleLoginSuperAdmin = useCallback(
    async (organizationName: string) => {
      const response = await loginSuperAdmin(organizationName);
      if (response.status) {
        if (organizationName !== "admin") window.location.reload();
        else window.location.href = "/";
      }
    },
    []
  );

  useEffect(() => {
    if (businessUnitSelected === null) {
      const fetchData = async () => {
        setLoadingBusinessUnit(true);

        const response = await getBusinessUnitsByName("");
        const options = response.businessUnits.map((item: any) => ({
          value: item.id,
          text: item.name,
        }));
        setBusinessUnitsOptions(options);
        if (response.businessUnits[0] && !location.pathname.includes("view")) {
          updateBusinessUnitSelected(response.businessUnits[0].id);
        }
        if (response.businessUnits.length <= 1) setHasOneBusinessUnit(true);

        setLoadingBusinessUnit(false);
      };
      fetchData();
    }
  }, []);
  const handleSearchBusinessUnit = async (value: string) => {
    setLoadingBusinessUnit(true);
    const response = await getBusinessUnitsByName(value);
    const options = response.businessUnits.map((item: any) => ({
      value: item.id,
      text: item.name,
    }));
    setBusinessUnitsOptions(options);
    setLoadingBusinessUnit(false);
  };

  const handleSignOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("organizationId");
    localStorage.removeItem("privileges");
    window.location.reload();
  };

  const handleChangeMenu = (key: string) => {
    onChangeMenu(key as SelectedMenuOptions);
  };

  const handleChangePassword = async () => {
    if (newPassword.length < 8) {
      message.error(t("Common-PasswordLength"));
      return;
    }
    setIsLoadingChangePassword(true);

    const response = await editUserPassword(actualPassword, newPassword);

    if (response) {
      message.success(t("ChangePassword-SendSuccesMessage"));
    } else {
      message.error(t("ChangePassword-SendErrorMessage"));
    }

    setIsLoadingChangePassword(false);
  };

  const handleEditProfile = async () => {
    await userForm.validateFields();
    setIsLoadingEditProfile(true);

    const { name, lastname } = userForm.getFieldsValue();

    const response = await editUserProfile(name, lastname);

    if (response) {
      message.success(t("Common-EditSuccessMessage"));
    } else {
      message.error(t("Common-ErrorMessage"));
    }

    setIsLoadingEditProfile(false);
  };

  const profileMenu = (
    <div className={styles.notificationsWrapper}>
      <Menu mode="inline" className={styles.menu}>
        <Menu.Item key="profile" onClick={() => setIsMyProfileVisible(true)}>
          {t("Common-MyProfile")}
        </Menu.Item>
        <Menu.Item
          key="changepassword"
          onClick={() => setIsChangePasswordModalVisible(true)}
        >
          {t("Common-ChangePassword")}
        </Menu.Item>
        <Menu.Item key="logout" onClick={() => handleSignOut()}>
          {t("NavBar-Logout")}
        </Menu.Item>
      </Menu>
    </div>
  );

  const notificationMenu = (
    <div className={styles.notificationsWrapper}>
      <Typography.Title level={4} className={styles.notificationTitle}>
        Notificaciones
      </Typography.Title>
      <Divider className={styles.notificationSeparator} />
      <div>
        {notifications.map((notification: any) => (
          <NotificationItem
            key={notification.id}
            notification={{
              id: notification.id,
              title: notification.title,
              description: notification.text,
              readed: notification.status === "READ",
              date: notification.date,
            }}
            onUpdate={handleFetchNotifications}
          />
        ))}
      </div>
    </div>
  );

  const isAdminOrg = organizationNameSelected === "admin";

  return (
    <>
      <nav className={styles.navBar}>
        {isSuperAdmin && (
          <div className={styles.filterWrapper}>
            <Select
              showSearch
              defaultValue={organizationSelected ?? undefined}
              placeholder={"Selecciona una organizacion"}
              onSelect={(_, option) => {
                if (option) {
                  const organizationName = option.label?.toString() ?? "";
                  updateBusinessUnitSelected("");
                  handleLoginSuperAdmin(organizationName);
                  setOrganizationSelected(option.value);
                  setOrganizationNameSelected(organizationName);
                }
              }}
              loading={loadingBusinessUnit}
              className={styles.filterSelect}
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              value={organizationSelected ?? ""}
              options={(organizationOptions || []).map((d: any) => ({
                value: d.value,
                label: d.text,
              }))}
            />
          </div>
        )}
        {!hasOneBusinessUnit && !isAdminOrg && (
          <div className={styles.filterWrapper}>
            <Select
              showSearch
              defaultValue={businessUnitSelected ?? undefined}
              placeholder={t("Common-SearchBusinessUnitTextPlaceholder")}
              onSelect={(_, option) => {
                if (option) {
                  updateBusinessUnitSelected(option.value);
                }
              }}
              loading={loadingBusinessUnit}
              className={styles.filterSelect}
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              value={businessUnitSelected ?? ""}
              onDropdownVisibleChange={() => handleSearchBusinessUnit("")}
              onSearch={handleSearchBusinessUnit}
              options={(businessUnitsOptions || []).map((d: any) => ({
                value: d.value,
                label: d.text,
              }))}
            />
          </div>
        )}
        <div className={styles.menuWrapper}>
          <Menu
            mode="horizontal"
            className={styles.menu}
            onClick={(event) => handleChangeMenu(event.key)}
          >
            {!isAdminOrg && (
              <>
                <Menu.Item
                  className={`${styles.subMenu} ${
                    selectedMenu === SelectedMenuOptions.Dashboard &&
                    styles.selected
                  }`}
                  key="dashboard"
                  onClick={() => navigate("/")}
                  icon={<HomeOutlined />}
                >
                  {t("NavBar-Dashboard")}
                </Menu.Item>
                <Menu.Item
                  className={`${styles.subMenu} ${
                    selectedMenu === SelectedMenuOptions.Admin &&
                    styles.selected
                  }`}
                  key="admin"
                  onClick={() => navigate("/admin/businessrules")}
                  icon={<SettingOutlined />}
                >
                  {t("NavBar-Admin")}
                </Menu.Item>
                <Menu.Item
                  className={`${styles.subMenu} ${
                    selectedMenu === SelectedMenuOptions.Pricings &&
                    styles.selected
                  }`}
                  key="pricings"
                  onClick={() => navigate("/pricings")}
                  icon={<DollarCircleOutlined />}
                  title={t("NavBar-Pricings")}
                >
                  {t("NavBar-Pricings")}
                </Menu.Item>
                <Menu.Item
                  className={`${styles.subMenu} ${
                    selectedMenu === SelectedMenuOptions.Promotions &&
                    styles.selected
                  }`}
                  key="promotions"
                  onClick={() => navigate("/promotions")}
                  icon={<WalletOutlined />}
                  title={t("NavBar-Promotions")}
                >
                  {t("NavBar-Promotions")}
                </Menu.Item>
                <Menu.Item
                  className={`${styles.subMenu} ${
                    selectedMenu === SelectedMenuOptions.PurchaseOrders &&
                    styles.selected
                  }`}
                  key="purchaseorders"
                  onClick={() => navigate("/purchaseorders/dashboard")}
                  icon={<ShoppingCartOutlined />}
                  title={t("NavBar-Shopping")}
                >
                  {t("NavBar-Shopping")}
                </Menu.Item>
                <Menu.Item
                  className={`${styles.subMenu} ${
                    selectedMenu === SelectedMenuOptions.RepositionOrders &&
                    styles.selected
                  }`}
                  key="repositionorders"
                  onClick={() => navigate("/repositionorders/dashboard")}
                  icon={<FormOutlined />}
                  title={t("NavBar-Surtido")}
                >
                  {t("NavBar-Surtido")}
                </Menu.Item>
              </>
            )}
            {/*<Menu.Item*/}
            {/*  className={styles.subMenu}*/}
            {/*  key="logout"*/}
            {/*  onClick={() => handleSignOut()}*/}
            {/*  title={t("NavBar-Logout")}*/}
            {/*>*/}
            {/*  {"Logout"}*/}
            {/*</Menu.Item>*/}
          </Menu>
          <div className={styles.iconsWrapper}>
            <Dropdown overlay={profileMenu} placement="bottomRight">
              <UserOutlined className={styles.notificationIcon} />
            </Dropdown>
            <Dropdown overlay={notificationMenu} placement="bottomRight">
              <Badge count={unreadNotifications}>
                <BellOutlined className={styles.notificationIcon} />
              </Badge>
            </Dropdown>
          </div>
        </div>
      </nav>
      <Drawer
        visible={isMyProfileVisible}
        onClose={() => setIsMyProfileVisible(false)}
      >
        <div>
          <Typography.Title level={3}>{t("Common-MyProfile")}</Typography.Title>
          <Divider />
          <div className={styles.profileWrapper}>
            <Form
              name="userForm"
              initialValues={{ remember: true }}
              autoComplete="off"
              form={userForm}
              layout="vertical"
            >
              <Form.Item
                label={t("Common-Name")}
                name="name"
                initialValue={user?.name}
                rules={[
                  {
                    required: true,
                    message: t("Common-NameNotEntered"),
                  },
                ]}
              >
                <Input placeholder={t("Common-Name")} />
              </Form.Item>
              <Form.Item
                label={t("Common-LastName")}
                name="lastname"
                initialValue={user?.lastname}
                rules={[
                  {
                    required: true,
                    message: t("Common-LastName"),
                  },
                ]}
              >
                <Input placeholder={t("Common-LastName")} />
              </Form.Item>
              <Form.Item
                className={styles.contactItem}
                label={t("Common-Email")}
                name="email"
                rules={[
                  {
                    type: "email",
                  },
                ]}
                initialValue={user?.email}
              >
                <Input disabled />
              </Form.Item>
              <div className={styles.profileButtons}>
                <Form.Item>
                  <Button
                    onClick={() => {
                      userForm.resetFields();
                    }}
                    className={styles.cancelButton}
                    type="primary"
                  >
                    {t("Common-Cancel")}
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={handleEditProfile}
                    loading={isLoadingEditProfile}
                  >
                    {t("Common-Save")}
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      </Drawer>
      <Modal
        title={t("Common-ChangePassword")}
        visible={isChangePasswordModalVisible}
        okText={t("Common-Save")}
        okButtonProps={{
          disabled: !actualPassword || !newPassword,
          loading: isLoadingChangePassword,
        }}
        onCancel={() => setIsChangePasswordModalVisible(false)}
        onOk={handleChangePassword}
      >
        <Typography.Text>{t("Common-ActualPassword")}</Typography.Text>
        <Input.Password
          className={styles.passwordInput}
          placeholder={t("Common-Password")}
          type="password"
          onChange={(e) => setActualPassword(e.target.value)}
          minLength={8}
          required={true}
        />
        <Typography.Text>{t("Common-NewPassword")}</Typography.Text>
        <Input.Password
          className={styles.passwordInput}
          placeholder={t("Common-Password")}
          type="password"
          onChange={(e) => setNewPassword(e.target.value)}
          minLength={8}
          required={true}
        />
      </Modal>
    </>
  );
};

export default NavBar;

import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import {
  createArticle,
  getAttributesFiltered,
  getBrandsFiltered,
  getCategoriesFiltered,
  getColoursByName,
  getCurrencies,
  getPurchaseUnitsFiltered,
  getRoundingTypes,
  getSeasonsFiltered,
  getSuppliersFiltered,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";
import {
  Button,
  Cascader,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  Space,
  Spin,
  Switch,
  Table,
} from "antd";
import {
  LoadingOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { BusinessUniteContext } from "../../components/laoyut/Layout";

import styles from "./NewArticle.module.scss";

const NewArticle = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { contextValue: businessUnitSelected } =
    useContext(BusinessUniteContext);

  const [loading, setLoading] = useState(true);
  const [loadingColours, setLoadingColours] = useState(false);
  const [categories, setCategories] = useState<any[]>([]);
  const [brands, setBrands] = useState<any[]>([]);
  const [seasons, setSeasons] = useState<any[]>([]);
  const [skus, setSkus] = useState<any[]>([]);
  const [currencies, setCurrencies] = useState<any[]>([]);
  const [attributes, setAttributes] = useState<any[]>([]);
  const [suppliers, setSuppliers] = useState<any[]>([]);
  const [roundingTypes, setRoundingTypes] = useState<any[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [paymentMethodsSupplier, setPaymentMethodsSupplier] = useState([]);
  const [purchaseUnits, setPurchaseUnits] = useState<any[]>([]);
  const [attributeFilterValue, setAttributeFilterValue] = useState("");
  const [attributesSelected, setAttributesSelected] = useState<any[]>([]);
  const [colours, setColours] = useState<any[]>([]);

  const [newArticleForm] = Form.useForm();
  const [suppliersForm] = Form.useForm();
  const [skuForm] = Form.useForm();
  const [attributesSkuForm] = Form.useForm();
  const [attributesArticleForm] = Form.useForm();

  useEffect(() => {
    if (hasPermission(privilegesEnum.WRITE_ARTICLES)) {
      handleFetchCurrencies();
      handleFetchRoundingTypes();
      handleSearchColours("");
      handleSearchBrands("");
      handleSearchCategories("");
    }
  }, []);

  useEffect(() => {
    if (attributeFilterValue.length > 0) {
      handleFetchAttributes(attributeFilterValue);
    }
  }, [attributeFilterValue]);

  const handleSearchCategories = async (value: string) => {
    const response = await getCategoriesFiltered(value);
    setCategories(response);
  };

  const handleSearchSuppliers = async (value: string) => {
    if (value.length > 2) {
      const response = await getSuppliersFiltered(0, { name: value });
      setSuppliers(response.suppliers);
    } else setSuppliers([]);
  };

  const handleSearchBrands = async (value: string) => {
    const response = await getBrandsFiltered(value);
    setBrands(response);
  };

  const handleSearchPurchaseUnits = async (value: string) => {
    if (value.length > 2) {
      const response = await getPurchaseUnitsFiltered(value);
      setPurchaseUnits(response);
    } else setPurchaseUnits([]);
  };

  const handleFetchCurrencies = async () => {
    const response = await getCurrencies();
    setCurrencies(response);
    setLoading(false);
  };

  const handleFetchRoundingTypes = async () => {
    const response = await getRoundingTypes();
    setRoundingTypes(response);
    setLoading(false);
  };

  const handleFetchAttributes = async (name: string) => {
    const response = await getAttributesFiltered(name);
    setAttributes(response);
    setLoading(false);
  };

  const handleChangeAttribute = (value: any, selectedOptions: any) => {
    const attributeSelected = {
      disabled: selectedOptions[0].children.length === 0,
      value: selectedOptions[0].value,
      label: selectedOptions[0].label,
      children: selectedOptions[0].children?.map((possibleValue: any) => {
        return {
          value: possibleValue.value,
          label: possibleValue.label,
        };
      }),
    };

    setAttributesSelected([...attributesSelected, attributeSelected]);
    setAttributes([]);
  };

  //todo podria ser una funcion en el helper
  const getOptions = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });

    return options;
  };

  const getOptionsCategories = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      const parentsString = item?.parents
        ? item.parents.filter(Boolean).join(" - ")
        : "";
      const categoryName = item?.name || "";

      const categoryTree = parentsString
        ? `${parentsString} - ${categoryName}`
        : categoryName;

      options.push(
        <Select.Option value={item.id} key={item.id}>
          {categoryTree}
        </Select.Option>
      );
    });

    return options;
  };

  const getOptionsDescription = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.descriptionEs}
        </Select.Option>
      );
    });

    return options;
  };

  const validateAddSku = async () => {
    await skuForm.validateFields().then((skuValues) => {
      attributesSkuForm.validateFields().then((attributesValues) => {
        const attributesBody = attributesValues.attributes?.map(
          (attribute: any) => {
            return {
              attributeId: attribute.attributeId[0],
              attributeEnumId: attribute.attributeId[1],
              isEnum: true,
            };
          }
        );

        handleAddSku(skuValues, attributesBody);
      });
    });
  };

  const handleAddSku = async (sku: any, attributes: any) => {
    setLoading(true);
    const actualSkus = skus;

    sku.attributes = attributes ?? [];
    actualSkus.push(sku);
    await setSkus(actualSkus);

    skuForm.resetFields();
    attributesSkuForm.resetFields();
    setIsModalVisible(false);
    setLoading(false);
  };

  const handleValidateArticleFields = async () => {
    await newArticleForm.validateFields().then((article) => {
      suppliersForm.validateFields().then((suppliers) => {
        attributesArticleForm.validateFields().then((attributes) => {
          const attributesBody = attributes.attributes?.map(
            (attribute: any) => {
              return {
                attributeId: attribute.attributeId[0],
                attributeEnumId: attribute.attributeId[1],
                isEnum: true,
              };
            }
          );
          if (businessUnitSelected)
            article.businessUnitsIds = [businessUnitSelected];
          handleCreateArticle(article, suppliers.suppliers, attributesBody);
        });
      });
    });
  };

  const handleCreateArticle = async (
    article: any,
    suppliers: any,
    attributes: any
  ) => {
    setLoading(true);

    let suppliersApi;
    if (suppliers) {
      suppliersApi = suppliers.map((sup: any) => {
        return {
          supplierId: sup.supplierId,
          principal: sup.principal,
          commercialTerm: {
            deliveryTerm: sup.deliveryTerm,
            minimumOrder: sup.minimumOrder,
            supplierPaymentMethodId: sup.supplierPaymentMethodId,
            cost: sup.cost,
            agreedDiscounts: sup.agreedDiscounts,
          },
          purchaseUnitId: sup.purchaseUnit,
        };
      });
    }
    const response = await createArticle(
      article,
      suppliersApi ?? [],
      attributes ?? [],
      skus ?? []
    );

    if (response.success) {
      message.success(t("Common-CreateArticleSucessMessage"));
      newArticleForm.resetFields();
      suppliersForm.resetFields();
      attributesArticleForm.resetFields();
      setSkus([]);
      navigate(`/admin/articles/${response.article.id}/view`);
    } else message.error(t("Common-ErrorMessage"));

    setLoading(false);
  };

  const showNewModal = () => {
    setIsModalVisible(true);
  };

  const handleSearchColours = async (value: string) => {
    setLoadingColours(true);
    const response = await getColoursByName(value);
    setColours(response.colours);
    setLoadingColours(false);
  };

  const attributeOptions = () => {
    const concatedAttributes = attributes
      ?.map((attribute) => {
        return {
          disabled: attribute.enums.length === 0,
          value: attribute.id,
          label: attribute.attribute,
          children: attribute.enums?.map((possibleValue: any) => {
            return {
              value: possibleValue.id,
              label: possibleValue.value,
            };
          }),
        };
      })
      .concat(attributesSelected);

    return concatedAttributes.filter((obj, index) => {
      return (
        concatedAttributes.findIndex((o) => o.value === obj.value) === index
      );
    });
  };

  const changeSelectedSupplier = (value: any) => {
    const supplier = suppliers.find((supplier) => supplier.id === value);
    if (supplier) {
      setPaymentMethodsSupplier(supplier.paymentMethods);
    } else setPaymentMethodsSupplier([]);
  };

  const handleCancelNewModal = () => {
    skuForm.resetFields();
    setIsModalVisible(false);
  };

  const handleSearchSeasons = async (value: string) => {
    if (value.length > 2) {
      const response = await getSeasonsFiltered(value);
      setSeasons(response);
    } else setSeasons([]);
  };

  const getOptionsPurchaseUnit = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.purchaseUnit}
        </Select.Option>
      );
    });

    return options;
  };

  const skuColumns = [
    {
      title: t("Common-Name"),
      dataIndex: "name",
      key: "name",
    },
  ];
  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-NewArticle")}</h1>
        <Button
          onClick={handleValidateArticleFields}
          className={styles.saveButton}
          type="primary"
        >
          {t("Common-Add")}
        </Button>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.articleInfoWrapper}>
          <Form
            name="newArticle"
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.newForm}
            form={newArticleForm}
          >
            <Form.Item
              label={t("Common-Name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("Common-NameNotEntered"),
                },
              ]}
            >
              <Input placeholder={t("Common-Name")} />
            </Form.Item>
            <Form.Item label={t("Common-ExternalId")} name="externalId">
              <Input placeholder={t("Common-ExternalId")} />
            </Form.Item>
            <Form.Item label={t("Common-Description")} name="description">
              <Input placeholder={t("Common-Description")} />
            </Form.Item>
            <Form.Item
              label={t("Common-Category")}
              name="categoryId"
              rules={[
                {
                  required: true,
                  message: t("Common-Category"),
                },
              ]}
            >
              <Select
                allowClear
                filterOption={(input: string, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                showSearch
                style={{ width: "100%" }}
                onSearch={handleSearchCategories}
                placeholder={t("Common-SearchByName")}
              >
                {getOptionsCategories(categories)}
              </Select>
            </Form.Item>
            <Form.Item
              label={t("Common-Brand")}
              name="brandId"
              rules={[
                {
                  required: true,
                  message: t("Common-Brand"),
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                filterOption={(input: string, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                style={{ width: "100%" }}
                placeholder={t("Common-SearchByName")}
                onSearch={handleSearchBrands}
              >
                {getOptions(brands)}
              </Select>
            </Form.Item>
            <Form.Item label={t("Common-Seasons")} name="seasons">
              <Select
                mode="multiple"
                allowClear
                showSearch
                filterOption={(input: string, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                style={{ width: "100%" }}
                placeholder={t("Common-SearchByName")}
                onSearch={handleSearchSeasons}
              >
                {getOptions(seasons)}
              </Select>
            </Form.Item>
            <Form.Item label={t("Common-Currency")} name="currencyId">
              <Select allowClear style={{ width: "100%" }} placeholder="">
                {getOptions(currencies)}
              </Select>
            </Form.Item>
            <Form.Item label={t("Common-RoundingType")} name="roundingTypeId">
              <Select allowClear style={{ width: "100%" }} placeholder="">
                {getOptionsDescription(roundingTypes)}
              </Select>
            </Form.Item>
            <Form.Item label={t("Common-OriginType")} name="originType">
              <Select allowClear style={{ width: "100%" }} placeholder="">
                <Select.Option value="BUY_IN_SQUARE">
                  {t("Common-BuyInSquare")}
                </Select.Option>
                <Select.Option value="IMPORTED_ARTICLE">
                  {t("Common-ImportedArticle")}
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label={t("Common-Cost")} name="cost">
              <InputNumber
                type="number"
                className={styles.numberInput}
                min={0}
                placeholder={t("Common-Cost")}
              />
            </Form.Item>
            <Form.Item label={t("Common-DefaultPrice")} name="defaultPrice">
              <InputNumber
                type="number"
                className={styles.numberInput}
                min={0}
                placeholder={t("Common-DefaultPrice")}
              />
            </Form.Item>
            <Form.Item label={t("Common-MinimumPrice")} name="minimumPrice">
              <InputNumber
                type="number"
                className={styles.numberInput}
                min={0}
                placeholder={t("Common-MinimumPrice")}
              />
            </Form.Item>
            <Form.Item label={t("Common-MaximumPrice")} name="maximumPrice">
              <InputNumber
                type="number"
                className={styles.numberInput}
                min={0}
                placeholder={t("Common-MaximumPrice")}
              />
            </Form.Item>
            <Form.Item label={t("Common-MinimumMargin")} name="minimumMargin">
              <InputNumber
                type="number"
                className={styles.numberInput}
                min={0}
                placeholder={t("Common-MinimumMargin")}
              />
            </Form.Item>
            <Form.Item
              initialValue={false}
              label={t("Common-Active")}
              name="enable"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              initialValue={false}
              label={t("Common-Discontinued")}
              name="discontinued"
            >
              <Switch />
            </Form.Item>
          </Form>
        </div>
        <div className={styles.supplierWrapper}>
          <h1 className={styles.suppliersTitle}>{t("Common-Suppliers")}</h1>
          <div className={styles.suppliersFormWrapper}>
            <Form
              name="suppliers"
              layout="vertical"
              initialValues={{ remember: true }}
              autoComplete="off"
              className={styles.suppliersForm}
              form={suppliersForm}
            >
              <Form.List name="suppliers">
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        className={styles.supplierSpace}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          className={styles.supplierInfoItem}
                          label={t("Common-Supplier")}
                          name={[name, "supplierId"]}
                          rules={[
                            {
                              required: true,
                              message: t("Common-Supplier"),
                            },
                          ]}
                        >
                          <Select
                            className={styles.supplierInfoItem}
                            onChange={changeSelectedSupplier}
                            filterOption={(input: string, option: any) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder={t("Common-SearchByName")}
                            showSearch
                            onSearch={handleSearchSuppliers}
                          >
                            {getOptions(suppliers)}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          className={styles.supplierInfoItem}
                          label={t("Common-PaymentMethod")}
                          name={[name, "supplierPaymentMethodId"]}
                        >
                          <Select
                            className={styles.supplierInfoItem}
                            placeholder={t("NewSupplier-PaymentMethod")}
                          >
                            {getOptions(paymentMethodsSupplier)}
                          </Select>
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          className={styles.supplierInfoItem}
                          name={[name, "deliveryTerm"]}
                          label={t("Common-DeliveryTerm")}
                          rules={[
                            {
                              required: true,
                              message: t("Common-Supplier"),
                            },
                          ]}
                        >
                          <Input
                            className={styles.supplierInfoItem}
                            placeholder={t("Common-DeliveryTerm")}
                          />
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          label={t("NewSupplier-MinimumOrder")}
                          className={styles.supplierInfoItem}
                          name={[name, "minimumOrder"]}
                          rules={[
                            {
                              required: true,
                              message: t("NewSupplier-MinimumOrder"),
                            },
                          ]}
                        >
                          <Input
                            className={styles.supplierInfoItem}
                            placeholder={t("NewSupplier-MinimumOrder")}
                          />
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          label={t("NewSupplier-PurchaseUnit")}
                          className={styles.supplierInfoItem}
                          name={[name, "purchaseUnit"]}
                        >
                          <Select
                            allowClear
                            placeholder={t("Common-SearchByName")}
                            showSearch
                            onSearch={handleSearchPurchaseUnits}
                            optionFilterProp="children"
                            filterOption={(input: string, option: any) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {getOptionsPurchaseUnit(purchaseUnits)}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          label={t("Common-Cost")}
                          className={styles.supplierInfoItem}
                          name={[name, "cost"]}
                          rules={[
                            {
                              required: true,
                              message: t("Common-Cost"),
                            },
                          ]}
                        >
                          <Input
                            className={styles.supplierInfoItem}
                            placeholder={t("Common-Cost")}
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          label={t("Common-AgreedDiscounts")}
                          className={styles.supplierInfoItem}
                          name={[name, "agreedDiscounts"]}
                          rules={[
                            {
                              required: true,
                              message: t("Common-AgreedDiscounts"),
                            },
                          ]}
                        >
                          <Input
                            className={styles.supplierInfoItem}
                            placeholder={t("Common-AgreedDiscounts")}
                          />
                        </Form.Item>
                        <Form.Item
                          initialValue={false}
                          label={t("NewSupplier-Principal")}
                          {...restField}
                          name={[name, "principal"]}
                        >
                          <Switch />
                        </Form.Item>

                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="primary"
                        className={styles.actionsButton}
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                      />
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Form>
          </div>
        </div>

        <div className={styles.attributesWrapper}>
          <h1 className={styles.attributesTitle}>{t("Common-Attributes")}</h1>
          <div className={styles.attributesFormWrapper}>
            <Form
              name="attributesArticle"
              layout="vertical"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
              initialValues={{ remember: true }}
              autoComplete="off"
              className={styles.attributesForm}
              form={attributesArticleForm}
            >
              <Form.List name="attributes">
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space key={key} align="baseline">
                        <Form.Item
                          {...restField}
                          className={styles.skuInfoItem}
                          name={[name, "attributeId"]}
                          rules={[
                            {
                              required: true,
                              message: t("Common-Attribute"),
                            },
                          ]}
                        >
                          <Cascader
                            className={styles.cascader}
                            showSearch
                            onChange={handleChangeAttribute}
                            onSearch={(value) => setAttributeFilterValue(value)}
                            options={attributeOptions()}
                          />
                        </Form.Item>

                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="primary"
                        className={styles.actionsButton}
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                      />
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Form>
          </div>
        </div>
        <div className={styles.skusWrapper}>
          <h1 className={styles.skusTitle}>{t("Common-Skus")}</h1>
          <div className={styles.skuTableWrapper}>
            <Button
              type="primary"
              className={styles.addSkuButton}
              onClick={() => showNewModal()}
              icon={<PlusOutlined />}
            />
            {skus.length !== 0 && (
              <Table
                size="small"
                className={styles.skuTable}
                loading={loading}
                columns={skuColumns}
                dataSource={skus}
              />
            )}
          </div>
        </div>
      </div>
      <Modal
        title={t("Common-NewSku")}
        visible={isModalVisible}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancelNewModal}
        width={800}
      >
        <Form
          name="newSku"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
          className={styles.skusForm}
          form={skuForm}
        >
          <div className={styles.skusItemsWrapper}>
            <Form.Item
              className={styles.skusFormItem}
              label={t("Common-Name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("Common-NameNotEntered"),
                },
              ]}
            >
              <Input placeholder={t("Common-Name")} />
            </Form.Item>
            <Form.Item
              className={styles.skusFormItem}
              label={t("Common-ExternalId")}
              name="externalId"
            >
              <Input placeholder={t("Common-ExternalId")} />
            </Form.Item>
            <Form.Item
              className={styles.skusFormItem}
              label={t("Common-StorageUnit")}
              name="storageUnit"
            >
              <Input placeholder={t("Common-StorageUnit")} />
            </Form.Item>
          </div>
          <div className={styles.skusItemsWrapper}>
            <Form.Item
              className={styles.skusFormItem}
              label={t("Common-Height")}
              name="height"
            >
              <InputNumber
                type="number"
                className={styles.numberInput}
                min={0}
                placeholder={t("Common-Height")}
              />
            </Form.Item>

            <Form.Item
              className={styles.skusFormItem}
              label={t("Common-Length")}
              name="length"
            >
              <InputNumber
                type="number"
                className={styles.numberInput}
                min={0}
                placeholder={t("Common-Length")}
              />
            </Form.Item>
            <Form.Item
              className={styles.skusFormItem}
              label={t("Common-Width")}
              name="width"
            >
              <InputNumber
                type="number"
                className={styles.numberInput}
                min={0}
                placeholder={t("Common-Width")}
              />
            </Form.Item>
          </div>
          <div className={styles.skusItemsWrapper}>
            <Form.Item
              className={styles.skusFormItem}
              label={t("Common-Weight")}
              name="weight"
            >
              <InputNumber
                type="number"
                className={styles.numberInput}
                min={0}
                placeholder={t("Common-Weight")}
              />
            </Form.Item>
            <Form.Item
              className={styles.skusFormItem}
              label={t("Common-Volumen")}
              name="volumen"
            >
              <InputNumber
                type="number"
                className={styles.numberInput}
                min={0}
                placeholder={t("Common-Volumen")}
              />
            </Form.Item>

            <Form.Item
              className={styles.skusFormItem}
              label={t("Common-MinimumStock")}
              name="minimumStock"
            >
              <InputNumber
                type="number"
                className={styles.numberInput}
                min={0}
                placeholder={t("Common-MinimumStock")}
              />
            </Form.Item>
          </div>
          <div className={styles.skusItemsWrapper}>
            <Form.Item
              className={styles.skusFormItem}
              label={t("Common-HeightWithPackaging")}
              name="heightWithPackaging"
            >
              <InputNumber
                type="number"
                className={styles.numberInput}
                min={0}
                placeholder={t("Common-HeightWithPackaging")}
              />
            </Form.Item>

            <Form.Item
              className={styles.skusFormItem}
              label={t("Common-LengthWithPackaging")}
              name="lengthWithPackaging"
            >
              <InputNumber
                type="number"
                className={styles.numberInput}
                min={0}
                placeholder={t("Common-LengthWithPackaging")}
              />
            </Form.Item>
            <Form.Item
              className={styles.skusFormItem}
              label={t("Common-WidthWithPackaging")}
              name="widthWithPackaging"
            >
              <InputNumber
                type="number"
                className={styles.numberInput}
                min={0}
                placeholder={t("Common-WidthWithPackaging")}
              />
            </Form.Item>
          </div>
          <div className={styles.skusItemsWrapper}>
            <Form.Item
              className={styles.skusFormItem}
              label={t("Common-WeightWithPackaging")}
              name="weightWithPackaging"
            >
              <InputNumber
                type="number"
                className={styles.numberInput}
                min={0}
                placeholder={t("Common-WeightWithPackaging")}
              />
            </Form.Item>
            <Form.Item
              className={styles.skusFormItem}
              label={t("Common-Cost")}
              name="cost"
            >
              <InputNumber
                type="number"
                className={styles.numberInput}
                min={0}
                placeholder={t("Common-Cost")}
              />
            </Form.Item>
            <Form.Item
              className={styles.skusFormItem}
              label={t("Common-SalesUnit")}
              name="salesUnit"
            >
              <Input placeholder={t("Common-SalesUnit")} />
            </Form.Item>
          </div>
          <div className={styles.skusItemsWrapper}>
            <Form.Item
              className={styles.skusFormItem}
              label={t("Common-Color")}
              name="colourId"
            >
              <Select
                className={styles.type}
                placeholder={t("Common-SearchByName")}
                onSearch={handleSearchColours}
                allowClear
                filterOption={(input: string, option: any) =>
                  option.children?.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                loading={loadingColours}
                showSearch
                style={{ width: "100%", minWidth: "150px" }}
              >
                {getOptions(colours)}
              </Select>
            </Form.Item>
          </div>
          <div className={styles.skusItemsWrapper}>
            <Form.Item
              className={styles.skusFormItem}
              label={t("Common-ShoeSize")}
              name="shoeSize"
            >
              <InputNumber
                type="number"
                className={styles.numberInput}
                min={0}
                placeholder={t("Common-ShoeSize")}
              />
            </Form.Item>
            <Form.Item
              className={styles.skusFormItem}
              label={t("Common-ClothSizeOp1")}
              name="clothSizeOp1"
            >
              <InputNumber
                type="number"
                className={styles.numberInput}
                min={0}
                placeholder={t("Common-ClothSizeOp1")}
              />
            </Form.Item>
            <Form.Item
              className={styles.skusFormItem}
              label={t("Common-ClothSizeOp2")}
              name="clothSizeOp2"
            >
              <Input placeholder={t("Common-ClothSizeOp2")} />
            </Form.Item>
          </div>
          <div className={styles.skusItemsWrapper}>
            <Form.Item
              initialValue={false}
              label={t("Common-Replenish")}
              name="replenish"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              initialValue={false}
              label={t("Common-Active")}
              name="enable"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              initialValue={false}
              label={t("Common-IsKit")}
              name="kit"
            >
              <Switch />
            </Form.Item>
          </div>
        </Form>
        <h1 className={styles.skusTitle}>{t("Common-Attributes")}</h1>

        <Form
          name="attributesSku"
          layout="vertical"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 12 }}
          initialValues={{ remember: true }}
          autoComplete="off"
          className={styles.articleForm}
          form={attributesSkuForm}
        >
          <Form.List name="attributes">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} className={styles.skuSpace} align="baseline">
                    <Form.Item
                      {...restField}
                      className={styles.skuInfoItem}
                      name={[name, "attributeId"]}
                      rules={[
                        {
                          required: true,
                          message: t("Common-Attribute"),
                        },
                      ]}
                    >
                      <Cascader
                        className={styles.cascader}
                        showSearch
                        onChange={handleChangeAttribute}
                        onSearch={(value) => setAttributeFilterValue(value)}
                        options={attributeOptions()}
                      />
                    </Form.Item>

                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="primary"
                    className={styles.actionsButton}
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
        <Button
          className={styles.newSkuButton}
          type="primary"
          onClick={validateAddSku}
        >
          {t("Common-Add")}
        </Button>
      </Modal>
    </div>
  );
};

export default NewArticle;

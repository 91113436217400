import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Table, Button, Modal, Input, Select } from "antd";
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";

import styles from "./ArticleGroupsMockup.module.scss";
import { getArticlesFiltered } from "../../api/Services";

const ArticleGroupsMockup = () => {
  const { t } = useTranslation();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalSize, setTotalSize] = useState(0);
  const [actualPage, setActualPage] = useState(1);
  const [loadingArticles, setLoadingArticles] = useState(false);
  const [articleOptions, setArticleOptions] = useState<any[]>([]);

  const showDeleteConfirm = (itemId: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDelete(itemId);
      },
    });
  };

  useEffect(() => {
    handleSearchArticles("");
  }, []);

  const handleSearchArticles = async (value: string) => {
    setLoadingArticles(true);
    const response = await getArticlesFiltered(0, { articleName: value });
    const options = response.articles.map((item: any) => ({
      value: item.id,
      text: item.name,
    }));
    setArticleOptions(options);
    setLoadingArticles(false);
  };

  const handleDelete = async (ticketId: number) => {
    setLoading(true);
    // const deleteStatus = await deleteTicket(ticketId);
    // if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    // else message.success(t("Common-DeleteSuccessMessage"));
    setLoading(false);
  };

  const handlePagination = (page: number) => {
    // handleFetchTickets(page);
    setActualPage(page);
  };

  const columns = [
    {
      title: t("Common-Name"),
    },
    {
      title: t("Common-Description"),
    },
    {
      title: t("Common-Quantity"),
    },
    {
      title: t("Common-CreatedDate"),
    },
    {
      title: t("Common-Status"),
    },
    {
      title: t("Common-Origin"),
    },
    {
      title: t("Common-Analysis"),
    },
    {
      title: t("Common-Actions"),
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-ArticleGroups")}</h1>
        <div className={styles.actionButtonsWrapper}>
          <Button
            href="/pricings/articlegroups/create"
            className={styles.newButton}
            type="primary"
            icon={<PlusOutlined />}
          />
          <Button
            // onClick={handleCancelButton}
            className={styles.importButton}
            type="primary"
          >
            {t("Common-Import")}
          </Button>
        </div>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.tableWrapper}>
          <div className={styles.headerTableWrapper}>
            <div className={styles.filtersWrapper}>
              <div className={styles.filterWrapper}>
                <Input
                  placeholder={t("Common-SearchByName")}
                  className={styles.filterInput}
                />
              </div>
              <div className={styles.filterWrapper}>
                <Select
                  showSearch
                  allowClear
                  placeholder={t("Common-SearchArticleTextPlaceholder")}
                  loading={loadingArticles}
                  className={styles.filterSelect}
                  showArrow={false}
                  filterOption={false}
                  onSearch={handleSearchArticles}
                  options={(articleOptions || []).map((d: any) => ({
                    value: d.value,
                    label: d.text,
                  }))}
                />
              </div>
            </div>
          </div>

          <Table
            size="small"
            className={styles.table}
            loading={loading}
            columns={columns}
            dataSource={data}
            pagination={{
              total: totalSize,
              showSizeChanger: false,
              onChange: handlePagination,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ArticleGroupsMockup;

import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  getPricingById,
  getPricingTimeLineById,
  getAllEndPricingTypes,
  getAllPricingTypes,
  editPricing,
  getCategoriesFiltered,
  getBranchOfficesFiltered,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";
import NotFound from "../notfound/NotFound";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

import {
  Form,
  message,
  Select,
  Button,
  Input,
  Spin,
  DatePicker,
  Switch,
  Typography,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import styles from "./ViewPricing.module.scss";
import AddArticleModal from "../../components/addarticlemodal/AddArticleModal";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ViewPricing = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [pricing, setPricing] = useState<any>();
  const [timeLine, setTimeLine] = useState<any>();
  const [labels, setLabels] = useState<any>();
  const [dataset, setDataset] = useState<any>();
  const [branchOffices, setBranchOffices] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [pricingTypes, setPricingTypes] = useState<any[]>([]);
  const [endPricingTypes, setEndPricingTypes] = useState<any[]>([]);
  const [articlesSelected, setArticlesSelected] = useState<any[]>([]);

  const [infoForm] = Form.useForm();
  const [behaviourForm] = Form.useForm();
  const [conditionsForm] = Form.useForm();

  const canEdit = hasPermission(privilegesEnum.WRITE_PRICINGS);

  const { id } = useParams();

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_PRICINGS)) {
      handleFetchPricing();
      handleFetchPricingTypes();
      handleFetchEndPricingTypes();
    }
  }, []);

  const handleFetchPricing = async () => {
    setLoading(true);

    let response = await getPricingById(id!);
    let timeLineData = await getPricingTimeLineById(id!);
    setPricing(response);
    setArticlesSelected(response.articles);
    setCategories(response.categories ?? []);
    setBranchOffices(response.branchOffices ?? []);
    setTimeLine(timeLineData);
    setLabels(
      timeLineData?.map((timeLine: any) => timeLine.date.replace("T", " "))
    );

    const sets: any[] = [];

    timeLineData?.forEach((timeLine: any) => {
      timeLine.articles.forEach((articleData: any) => {
        articleData.branchOffices.forEach((branchOfficeData: any) => {
          if (
            sets.find(
              (set: any) =>
                set.label ===
                articleData.articleName +
                  " " +
                  branchOfficeData.branchOfficeName
            ) === undefined
          ) {
            // articleData.branchOffices.forEach((branchOfficeData: any) => {
            const randomColor =
              "#" + Math.floor(Math.random() * 16777215).toString(16);
            const articleSet = {
              label:
                articleData.articleName +
                " " +
                branchOfficeData.branchOfficeName,
              data: [branchOfficeData.price],
              borderColor: randomColor,
              backgroundColor: randomColor,
              stepped: true,
            };
            sets.push(articleSet);
          } else {
            sets.forEach((set: any) => {
              if (
                set.label ===
                articleData.articleName +
                  " " +
                  branchOfficeData.branchOfficeName
              )
                set.data.push(branchOfficeData?.price);
            });
          }
        });
      });
    });
    setDataset(sets);

    setLoading(false);
  };

  const handleFetchEndPricingTypes = async () => {
    const response = await getAllEndPricingTypes();
    setEndPricingTypes(response);
  };

  const handleFetchPricingTypes = async () => {
    const response = await getAllPricingTypes();
    setPricingTypes(response);
  };

  const handleSearchCategories = async (value: string) => {
    if (value.length > 2) {
      const response = await getCategoriesFiltered(value);
      setCategories(response);
    } else setCategories([]);
  };

  const handleSearchBranchOffices = async (value: string) => {
    if (value.length > 1) {
      const response = await getBranchOfficesFiltered(0, { name: value });
      setBranchOffices(response.branchOffices);
    } else setBranchOffices([]);
  };

  const handleCancelButton = () => {
    infoForm.resetFields();
  };

  const getOptions = (items: any) => {
    const options: any[] = [];

    items.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });

    return options;
  };

  const getOptionsCategories = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      const parentsString = item?.parents
          ? item.parents.filter(Boolean).join(" - ")
          : "";
      const categoryName = item?.name || "";

      const categoryTree = parentsString
          ? `${parentsString} - ${categoryName}`
          : categoryName;

      options.push(
          <Select.Option value={item.id} key={item.id}>
            {categoryTree}
          </Select.Option>
      );
    });

    return options;
  };

  const getOptionsEndTypes = (items: any) => {
    const options: any[] = [];
    items.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.value} key={item.value}>
          {item.description_es}
        </Select.Option>
      );
    });

    return options;
  };

  const getOptionsDescription = (items: any) => {
    const options: any[] = [];

    items.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.description}
        </Select.Option>
      );
    });

    return options;
  };

  const handleEdit = async () => {
    setLoading(true);

    const { name, pricingTypeId } = infoForm.getFieldsValue();

    const behaviour = behaviourForm.getFieldsValue();
    const conditions = conditionsForm.getFieldsValue();

    const { percent, value, branchOfficeIds, categoryIds, allConditions } =
      conditions;

    const articlesIds = articlesSelected.map((article) => article.id);

    const { endPrice, newPrice, date } = behaviour;

    const startDate = date ? date[0]?.format("YYYY-MM-DDTHH:mm:ss") : null;
    const endDate = date ? date[1]?.format("YYYY-MM-DDTHH:mm:ss") : null;

    const pricing = {
      name,
      pricingTypeId,
      percent,
      value,
      articlesIds,
      branchOfficeIds,
      categoryIds,
      endPrice,
      newPrice,
      startDate,
      endDate,
      allConditions,
    };

    const response = await editPricing(parseInt(id!), pricing);
    if (response) {
      message.success(t("Common-EditSuccessMessage"));
      await handleFetchPricing();
    } else message.error(t("Common-EditErrorMessage"));
    setLoading(false);
  };

  const handleAddArticle = (articlesToAdd: any) => {
    const newArticles: any[] = [];
    articlesToAdd.forEach((articleToAdd: any) => {
      if (
        articlesSelected.find(
          (article: any) => article.id === articleToAdd.id
        ) === undefined
      )
        newArticles.push(articleToAdd);
    });
    setArticlesSelected([...articlesSelected, ...newArticles]);
  };

  const handleRemoveArticle = (articleRemove: any) => {
    const articlesWithoutRemoved = articlesSelected.filter(
      (article) => article.id !== articleRemove.id
    );

    setArticlesSelected(articlesWithoutRemoved);
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Pricing",
      },
    },
  };

  const data = {
    labels,
    datasets: dataset,
  };

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : !pricing ? (
    <NotFound />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-Pricing")}</h1>
        {hasPermission(privilegesEnum.WRITE_PRICINGS) && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              onClick={handleEdit}
              className={styles.saveButton}
              type="primary"
            >
              {t("Common-Save")}
            </Button>
            <Button
              onClick={handleCancelButton}
              className={styles.cancelButton}
              type="primary"
            >
              {t("Common-Cancel")}
            </Button>
          </div>
        )}
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.formWrapper}>
          <h1 className={styles.subTitle}>{`${t("Common-PricingEvent")} `}</h1>
          <Form
            name="newPricing"
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.newForm}
            form={infoForm}
          >
            <Form.Item
              initialValue={pricing?.name}
              label={t("Common-Name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("Common-Name"),
                },
              ]}
            >
              <Input placeholder={t("Common-Name")} disabled={!canEdit} />
            </Form.Item>
            <Form.Item
              label={t("Common-PricingType")}
              name="pricingTypeId"
              initialValue={pricing?.pricingType.id}
              className={styles.doubleItemLine}
              rules={[
                {
                  required: true,
                  message: t("Common-PricingType"),
                },
              ]}
            >
              <Select
                allowClear
                placeholder={t("Common-PricingType")}
                filterOption={(input: string, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                disabled={!canEdit}
              >
                {getOptionsDescription(pricingTypes)}
              </Select>
            </Form.Item>
            <Form.Item
              label={t("Common-Origin")}
              name="origin"
              initialValue={pricing?.pricingOrigin.description_es}
              className={styles.doubleItemLine}
            >
              <Input disabled={true} />
            </Form.Item>
            <Form.Item
              label={t("Common-Status")}
              name="status"
              initialValue={pricing?.status.description_es}
              className={styles.doubleItemLine}
            >
              <Input disabled={true} />
            </Form.Item>
          </Form>
        </div>
        <div className={styles.formWrapper}>
          <h1 className={styles.subTitle}>{`${t("Common-Conditions")} `}</h1>
          <Form
            name="conditionForm"
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.newForm}
            form={conditionsForm}
          >
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-Percentage")}
                name="percent"
                className={styles.doubleItemLine}
                initialValue={pricing?.percent}
              >
                <Switch defaultChecked={pricing?.percent} disabled={!canEdit} />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: t("Common-Name"),
                  },
                ]}
                label={t("Common-Value")}
                name="value"
                className={styles.doubleItemLine}
                initialValue={pricing?.value}
              >
                <Input placeholder={t("Common-Value")} disabled={!canEdit} />
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-BranchOffices")}
                name="branchOfficeIds"
                className={styles.doubleItemLine}
                initialValue={pricing?.branchOffices.map(
                  (branchOffice: any) => branchOffice.id
                )}
              >
                <Select
                  mode="multiple"
                  allowClear
                  onSearch={handleSearchBranchOffices}
                  placeholder={t("Common-SearchByName")}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={!canEdit}
                >
                  {getOptions(branchOffices)}
                </Select>
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-Categories")}
                name="categoryIds"
                className={styles.doubleItemLine}
                initialValue={pricing?.categories.map(
                  (category: any) => category.id
                )}
              >
                <Select
                  mode="multiple"
                  onSearch={handleSearchCategories}
                  placeholder={t("Common-SearchByName")}
                  allowClear
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={!canEdit}
                >
                  {getOptionsCategories(categories)}
                </Select>
              </Form.Item>
              <Form.Item
                label={t("Common-ApplyAllTogether")}
                name="allConditions"
                initialValue={pricing?.allConditions}
                className={styles.doubleItemLine}
              >
                <Switch
                  defaultChecked={pricing?.allConditions}
                  disabled={!canEdit}
                />
              </Form.Item>
            </div>
            <AddArticleModal
              selectedArticles={articlesSelected}
              onAddArticle={handleAddArticle}
              onRemoveArticle={handleRemoveArticle}
            />
          </Form>
        </div>
        <div className={styles.formWrapper}>
          <h1 className={styles.subTitle}>{`${t("Common-Behaviour")} `}</h1>
          <Form
            name="behaviourForm"
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.newForm}
            form={behaviourForm}
          >
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-Type")}
                name="endPrice"
                className={styles.doubleItemLine}
                initialValue={pricing?.endPrice.value}
                rules={[
                  {
                    required: true,
                    message: t("Common-Type"),
                  },
                ]}
              >
                <Select
                  allowClear
                  placeholder={t("Common-Type")}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={!canEdit}
                >
                  {getOptionsEndTypes(endPricingTypes)}
                </Select>
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.endPrice !== currentValues.endPrice
                }
              >
                {({ getFieldValue }) => {
                  switch (getFieldValue("endPrice")) {
                    case "NEW_PRICE":
                      return (
                        <Form.Item
                          label={t("Common-NewPrice")}
                          name="newPrice"
                          className={styles.doubleItemLine}
                          rules={[
                            {
                              required: true,
                              message: t("Common-NewPrice"),
                            },
                          ]}
                          initialValue={pricing?.newPrice}
                        >
                          <Input
                            placeholder={t("Common-NewPrice")}
                            disabled={!canEdit}
                          />
                        </Form.Item>
                      );
                  }
                }}
              </Form.Item>

              {/*<Form.Item*/}
              {/*  label={t("Common-NewPrice")}*/}
              {/*  name="newPrice"*/}
              {/*  className={styles.doubleItemLine}*/}
              {/*>*/}
              {/*  <Input placeholder={t("Common-NewPrice")} />*/}
              {/*</Form.Item>*/}
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-Date")}
                name="date"
                initialValue={[
                  pricing?.startDate
                    ? moment(pricing?.startDate, "YYYY-MM-DD HH:mm")
                    : null,
                  pricing?.endDate
                    ? moment(pricing?.endDate, "YYYY-MM-DD HH:mm")
                    : null,
                ]}
              >
                <DatePicker.RangePicker
                  showTime={{ format: "HH:mm" }}
                  format="YYYY-MM-DD HH:mm"
                  allowEmpty={[false, true]}
                  className={styles.dateSelector}
                  defaultValue={[
                    pricing?.startDate
                      ? moment(pricing?.startDate, "YYYY-MM-DD HH:mm")
                      : null,
                    pricing?.endDate
                      ? moment(pricing?.endDate, "YYYY-MM-DD HH:mm")
                      : null,
                  ]}
                  // onChange={onChange}
                  // onOk={onOk}
                  disabled={!canEdit}
                />
              </Form.Item>
            </div>
          </Form>
        </div>
        <div className={styles.chartWrapper}>
          <h1 className={styles.subTitle}>{`${t("Common-TimeLine")} `}</h1>

          {timeLine.length > 0 && pricing.endDate ? (
            <Line className={styles.lineChart} options={options} data={data} />
          ) : (
            <Typography.Text className={styles.text}>
              {t("Common-NoArticleApplyingData")}{" "}
            </Typography.Text>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewPricing;

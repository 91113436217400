import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  getClientSegmentById,
  editClientSegment,
  getAllCountries,
  getClientsBySegmentId,
  getAllSexTypes,
  getAllCitiesWithExistingClients,
  getCategoriesFiltered,
  getBranchOfficesFiltered,
  getBrandsFiltered,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";

import {
  Form,
  message,
  Select,
  Button,
  Input,
  Spin,
  Table,
  InputNumber,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import styles from "./ViewClientSegment.module.scss";
import NotFound from "../notfound/NotFound";
import AddArticleModal from "../../components/addarticlemodal/AddArticleModal";

const ViewClientSegment = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [clientSegment, setClientSegment] = useState<any>();
  const [clients, setClients] = useState<any>();
  const [sexTypes, setSexTypes] = useState<any[]>([]);
  const [countries, setCountries] = useState<any[]>([]);
  const [actualPage, setActualPage] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [cities, setCities] = useState<any[]>([]);
  const [branchOffices, setBranchOffices] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [brands, setBrands] = useState<any[]>([]);
  const [articlesSelected, setArticlesSelected] = useState<any[]>([]);

  const [infoForm] = Form.useForm();

  const canEdit = hasPermission(privilegesEnum.WRITE_CLIENTS);

  const { id } = useParams();

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_CLIENTS)) {
      handleFetchClientSegment();
      handleFetchCountries();
      handleFetchClientsBySegmentId();
      handleFetchSexTypes();
      handleFetchCities();
    }
  }, []);

  const handleFetchClientSegment = async () => {
    setLoading(true);

    let response = await getClientSegmentById(id!);
    setCategories(response.categories ?? []);
    setBranchOffices(response.branchOffices ?? []);
    setArticlesSelected(response.articles ?? []);
    setBrands(response.brands ?? []);
    setClientSegment(response);

    setLoading(false);
  };

  const handleSearchBrands = async (value: string) => {
    if (value.length > 2) {
      const response = await getBrandsFiltered(value);
      setBrands(response);
    } else setBrands([]);
  };

  const handleFetchSexTypes = async () => {
    const response = await getAllSexTypes();
    setSexTypes(response);
  };
  const handleFetchCities: () => any = async () => {
    const response = await getAllCitiesWithExistingClients();

    setCities(response);
  };

  const handleSearchBranchOffices = async (value: string) => {
    if (value.length > 1) {
      const response = await getBranchOfficesFiltered(0, { name: value });
      setBranchOffices(response.branchOffices);
    } else setBranchOffices([]);
  };

  const handleSearchCategories = async (value: string) => {
    if (value.length > 2) {
      const response = await getCategoriesFiltered(value);
      setCategories(response);
    } else setCategories([]);
  };

  const handleFetchClientsBySegmentId = async (pagination = actualPage) => {
    let response = await getClientsBySegmentId(id!, pagination);
    const allClients = response.content;
    const clientsWithKey: any[] = [];

    allClients.forEach((client: any) => {
      client.key = client.id;
      clientsWithKey.push(client);
    });
    setClients(clientsWithKey);
    setTotalSize(response.totalElements);
  };

  const handlePagination = (page: number) => {
    handleFetchClientsBySegmentId(page);
    setActualPage(page);
  };

  const handleFetchCountries: () => any = async () => {
    // setLoading(true);
    const response = await getAllCountries();

    setCountries(response);
    // setLoading(false);
  };

  const handleCancelButton = () => {
    infoForm.resetFields();
  };

  const getSexTypesOptions = (items: any) => {
    const options: any[] = [];

    items.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.value} key={item.value}>
          {item.description_es}
        </Select.Option>
      );
    });

    return options;
  };

  const getCountriesOptions = (items: any) => {
    const options: any[] = [];

    items.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });
    return options;
  };

  const getOptions = (items: any) => {
    const options: any[] = [];

    items.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });

    return options;
  };

  const getOptionsCategories = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      const parentsString = item?.parents
          ? item.parents.filter(Boolean).join(" - ")
          : "";
      const categoryName = item?.name || "";

      const categoryTree = parentsString
          ? `${parentsString} - ${categoryName}`
          : categoryName;

      options.push(
          <Select.Option value={item.id} key={item.id}>
            {categoryTree}
          </Select.Option>
      );
    });

    return options;
  };


  const handleEdit = async () => {
    setLoading(true);

    const {
      name,
      description,
      minAge,
      maxAge,
      sex,
      countryIds,
      stateIds,
      brandsIds,
      categoriesIds,
      branchOfficesIds,
    } = infoForm.getFieldsValue();

    const response = await editClientSegment(
      parseInt(id!),
      name,
      description,
      minAge,
      maxAge,
      sex,
      countryIds ?? [],
      stateIds ?? [],
      articlesSelected.map((article) => article.id) ?? [],
      brandsIds ?? [],
      categoriesIds ?? [],
      branchOfficesIds ?? []
    );

    if (response) {
      message.success(t("Common-EditSuccessMessage"));
      await handleFetchClientSegment();
    } else message.error(t("Common-EditErrorMessage"));
    setLoading(false);
  };

  const handleAddArticle = (articlesToAdd: any) => {
    const newArticles: any[] = [];
    articlesToAdd.forEach((articleToAdd: any) => {
      if (
        articlesSelected.find(
          (article: any) => article.id === articleToAdd.id
        ) === undefined
      )
        newArticles.push(articleToAdd);
    });
    setArticlesSelected([...articlesSelected, ...newArticles]);
  };

  const handleRemoveArticle = (articleRemove: any) => {
    const articlesWithoutRemoved = articlesSelected.filter(
      (article) => article.id !== articleRemove.id
    );

    setArticlesSelected(articlesWithoutRemoved);
  };

  const clientColumns = [
    {
      title: t("Common-Name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Common-LastName"),
      dataIndex: "lastname",
      key: "lastname",
    },
  ];

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : !clientSegment ? (
    <NotFound />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-ClientSegment")}</h1>
        {hasPermission(privilegesEnum.WRITE_CLIENTS) && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              onClick={handleEdit}
              className={styles.saveButton}
              type="primary"
            >
              {t("Common-Save")}
            </Button>
            <Button
              onClick={handleCancelButton}
              className={styles.cancelButton}
              type="primary"
            >
              {t("Common-Cancel")}
            </Button>
          </div>
        )}
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.infoWrapper}>
          <Form
            name="basic"
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.newForm}
            form={infoForm}
          >
            <Form.Item
              label={t("Common-Name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("Common-NameNotEntered"),
                },
              ]}
              initialValue={clientSegment?.name}
            >
              <Input disabled={!canEdit} placeholder={t("Common-Name")} />
            </Form.Item>
            <Form.Item
              initialValue={clientSegment?.description}
              label={t("Common-Description")}
              name="description"
            >
              <Input
                disabled={!canEdit}
                placeholder={t("Common-Description")}
              />
            </Form.Item>
            <Form.Item
              className={styles.newItem}
              label={t("Common-Sex")}
              name="sex"
              initialValue={clientSegment?.sex?.value}
            >
              <Select
                defaultValue={clientSegment?.sex?.value}
                placeholder={t("Common-Sex")}
                allowClear
                disabled={!canEdit}
              >
                {getSexTypesOptions(sexTypes)}
              </Select>
            </Form.Item>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                className={styles.doubleItemLine}
                label={t("Common-MinAge")}
                name="minAge"
                initialValue={clientSegment?.minAge}
              >
                <InputNumber
                  className={styles.inputNumber}
                  min={0}
                  placeholder={t("Common-MinAge")}
                  disabled={!canEdit}
                />
              </Form.Item>
              <Form.Item
                className={styles.doubleItemLine}
                label={t("Common-MaxAge")}
                name="maxAge"
                initialValue={clientSegment?.maxAge}
              >
                <InputNumber
                  className={styles.inputNumber}
                  min={0}
                  placeholder={t("Common-MaxAge")}
                  disabled={!canEdit}
                />
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-Country")}
                name="countryIds"
                className={styles.doubleItemLine}
                initialValue={clientSegment?.countries.map(
                  (country: any) => country.id
                )}
              >
                <Select
                  mode="multiple"
                  allowClear
                  placeholder={t("Common-SelectOneOrMore")}
                  // onChange={handleChange}
                  className={styles.multipleSelect}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  defaultValue={clientSegment?.countries.map(
                    (country: any) => country.id
                  )}
                  disabled={!canEdit}
                >
                  {getCountriesOptions(countries)}
                </Select>
              </Form.Item>
              <Form.Item
                label={t("Common-City")}
                name="stateIds"
                className={styles.doubleItemLine}
                initialValue={clientSegment?.states.map(
                  (state: any) => state.id
                )}
              >
                <Select
                  mode="multiple"
                  allowClear
                  placeholder={t("Common-SelectOneOrMore")}
                  // onChange={handleChange}
                  className={styles.multipleSelect}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  defaultValue={clientSegment?.states.map(
                    (state: any) => state.id
                  )}
                  disabled={!canEdit}
                >
                  {getCountriesOptions(cities)}
                </Select>
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-Brands")}
                name="brandsIds"
                className={styles.doubleItemLine}
                initialValue={clientSegment?.brands.map(
                  (brand: any) => brand.id
                )}
              >
                <Select
                  mode="multiple"
                  allowClear
                  onSearch={handleSearchBrands}
                  placeholder={t("Common-SearchByName")}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  defaultValue={clientSegment?.brands.map(
                    (brand: any) => brand.id
                  )}
                  disabled={!canEdit}
                >
                  {getOptions(brands)}
                </Select>
              </Form.Item>
              <Form.Item
                label={t("Common-Categories")}
                name="categoriesIds"
                className={styles.doubleItemLine}
                initialValue={clientSegment?.categories.map(
                  (category: any) => category.id
                )}
              >
                <Select
                  mode="multiple"
                  allowClear
                  onSearch={handleSearchCategories}
                  placeholder={t("Common-SearchByName")}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={!canEdit}
                >
                  {getOptionsCategories(categories)}
                </Select>
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-BranchOffices")}
                name="branchOfficesIds"
                className={styles.doubleItemLine}
                initialValue={clientSegment?.branchOffices.map(
                  (branchOffice: any) => branchOffice.id
                )}
              >
                <Select
                  disabled={!canEdit}
                  mode="multiple"
                  allowClear
                  onSearch={handleSearchBranchOffices}
                  placeholder={t("Common-SearchByName")}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {getOptions(branchOffices)}
                </Select>
              </Form.Item>
            </div>
            <AddArticleModal
              selectedArticles={articlesSelected}
              onAddArticle={handleAddArticle}
              onRemoveArticle={handleRemoveArticle}
            />
          </Form>
        </div>
        {hasPermission(privilegesEnum.READ_CLIENTS) && (
          <div className={styles.tableWrapper}>
            <h1 className={styles.subTitle}>{t("Common-Clients")}</h1>
            <Table
              size="small"
              className={styles.table}
              loading={loading}
              columns={clientColumns}
              dataSource={clients}
              pagination={{
                total: totalSize,
                showSizeChanger: false,
                onChange: handlePagination,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewClientSegment;

import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Form, InputNumber, message, Spin } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";
import { BusinessUniteContext } from "../../components/laoyut/Layout";
import { editBusinessUnit, getBusinessUnitById } from "../../api/Services";
import styles from "./Goals.module.scss";

const Goals = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [businessUnit, setBusinessUnit] = useState<any>();

  const [form] = Form.useForm();

  const { contextValue: businessUnitSelected } =
    useContext(BusinessUniteContext);

  const handleFetchBusinessUnit = async (id: string) => {
    let response = await getBusinessUnitById(id);
    setBusinessUnit(response);
    form.setFieldsValue({
      targetMargin: response.targetMargin,
    });
    setLoading(false);
  };

  const handleEditBusinessUnit = async () => {
    setLoading(true);

    const { targetMargin } = form.getFieldsValue();

    const response = await editBusinessUnit(parseInt(businessUnitSelected!), {
      name: businessUnit.name,
      targetMargin: targetMargin,
    });

    if (response) message.success(t("Common-EditSuccessMessage"));
    else message.error(t("Common-EditErrorMessage"));
    setLoading(false);
  };

  useEffect(() => {
    if (businessUnitSelected) handleFetchBusinessUnit(businessUnitSelected);
  }, [businessUnitSelected]);

  const handleCancelButton = () => {
    form.resetFields();
  };

  console.log(businessUnit?.targetMargin);

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}> {t("Common-Goals")}</h1>
        {/*{hasPermission(privilegesEnum.WRITE_GOALS) && (*/}
        <div className={styles.actionButtonsWrapper}>
          <Button
            onClick={handleEditBusinessUnit}
            className={styles.saveButton}
            type="primary"
          >
            {t("Common-Save")}
          </Button>
          <Button
            onClick={handleCancelButton}
            className={styles.cancelButton}
            type="primary"
          >
            {t("Common-Cancel")}
          </Button>
        </div>
        {/*)}*/}
      </div>
      {hasPermission(privilegesEnum.MODIFY_ORGANIZATION) && (
        <div className={styles.goalsWrapper}>
          {loading ? (
            <div className={styles.loadingWrapper}>
              <Spin
                indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
                delay={500}
              />
            </div>
          ) : (
            <Form
              name="goals"
              layout="vertical"
              form={form}
              className={styles.form}
              autoComplete="off"
            >
              <Form.Item
                label={t("Common-TargetMargin")}
                name="targetMargin"
                initialValue={businessUnit?.targetMargin}
              >
                <InputNumber min={0} className={styles.inputNumber} />
              </Form.Item>
            </Form>
          )}
        </div>
      )}
    </div>
  );
};

export default Goals;

import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  deletePricing,
  cancelPromotion,
  schedulePricing,
  getPricings,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";

import {
  Table,
  Form,
  Button,
  message,
  Space,
  Modal,
  Spin,
  DatePicker,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  PlusOutlined,
  CalendarOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

import styles from "./Pricings.module.scss";

const Pricings = () => {
  const { t } = useTranslation();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalSize, setTotalSize] = useState(0);
  const [actualPage, setActualPage] = useState(0);

  const [scheduledForm] = Form.useForm();

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_PRICINGS)) {
      handleFetchPricings();
    }
  }, []);

  const handleFetchPricings = async (pagination = actualPage) => {
    const response = await getPricings(pagination);
    const allPricings = response.pricings;
    const pricingsWithKey: any[] = [];

    allPricings.forEach((promotion: any) => {
      promotion.key = promotion.id;
      pricingsWithKey.push(promotion);
    });
    setData(pricingsWithKey);
    setTotalSize(response.totalElements);
    setLoading(false);
  };

  const showDeleteConfirm = (itemId: number) => {
    Modal.confirm({
      title: t("Common-DeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: t("Common-Yes"),
      okType: "danger",
      cancelText: t("Common-No"),
      onOk() {
        handleDelete(itemId);
      },
    });
  };


  const handleCancel = async (promotionId: number) => {
    setLoading(true);
    const deleteStatus = await cancelPromotion(promotionId);
    if (!deleteStatus) message.error(t("Common-ErrorMessage"));
    else message.success(t("Common-SuccessMessage"));
    await handleFetchPricings();
    setLoading(false);
  };

  const handleDelete = async (pricingId: number) => {
    setLoading(true);
    const deleteStatus = await deletePricing(pricingId);
    if (!deleteStatus) message.error(t("Common-DeleteErrorMessage"));
    else message.success(t("Common-DeleteSuccessMessage"));
    await handleFetchPricings();
    setLoading(false);
  };

  const handlePagination = (page: number) => {
    handleFetchPricings(page);
    setActualPage(page);
  };

  const handleScheduled = async () => {
    const data = scheduledForm.getFieldsValue();
    const { id } = scheduledForm.getFieldsValue();
    const startDate = data.startDate.format("YYYY-MM-DDTHH:mm:ss");
    const endDate = data.endDate?.format("YYYY-MM-DDTHH:mm:ss");

    const response = await schedulePricing(id, startDate, endDate);
    if (response) {
      Modal.destroyAll();
      message.success(t("Common-ProcessSuccessMessage"));
      await handleFetchPricings(actualPage);
    } else message.error(t("Common-ErrorMessage"));
  };

  const showScheduledModal = (promotion: any) => {
    Modal.info({
      title: t("Common-Schedule"),
      closable: true,
      maskClosable: true,
      okButtonProps: { style: { display: "none" } },
      width: 500,
      icon: null,
      content: (
        <Form
          name="receivedOrder"
          layout="vertical"
          initialValues={{ remember: true }}
          autoComplete="off"
          form={scheduledForm}
          preserve={false}
        >
          <Form.Item hidden initialValue={promotion.id} name="id" />
          <Form.Item
            label={t("Common-StartDate")}
            name="startDate"
            rules={[
              {
                required: true,
                message: t("Common-StartDate"),
              },
            ]}
          >
            <DatePicker
              className={styles.dateSelector}
              showTime
              format="YYYY-MM-DD HH:mm"
            />
          </Form.Item>
          <Form.Item label={t("Common-EndDate")} name="endDate">
            <DatePicker
              className={styles.dateSelector}
              showTime
              format="YYYY-MM-DD HH:mm"
            />
          </Form.Item>
          <Form.Item>
            <Button
              className={styles.saveButton}
              type="primary"
              htmlType="submit"
              disabled={loading}
              onClick={handleScheduled}
            >
              {loading ? (
                <Spin
                  indicator={
                    <LoadingOutlined className="ol-loading-spin-icon" />
                  }
                  delay={500}
                />
              ) : (
                t("Common-Save")
              )}
            </Button>
          </Form.Item>
        </Form>
      ),
    });
  };

  const columns = [
    {
      title: t("Common-Name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Common-Value"),
      dataIndex: "value",
      key: "value",
    },
    {
      title: t("Common-Type"),
      dataIndex: "pricingType",
      key: "pricingType",
      render: (pricingType: any) => pricingType.code,
    },
    {
      title: t("Common-Origin"),
      dataIndex: "pricingOrigin",
      key: "pricingOrigin",
      render: (pricingOrigin: any) => pricingOrigin?.description_es,
    },
    {
      title: t("Common-Analysis"),
      dataIndex: "analysis",
      key: "analysis",
    },
    {
      title: t("Common-Status"),
      dataIndex: "status",
      key: "status",
      render: (status: any) => status?.description_es,
    },
    {
      title: t("Common-StartDate"),
      dataIndex: "startDate",
      key: "startDate",
      render: (date: any) =>
        date
          ? moment(date, "YYYY-MM-DDHH:mm:ss").format("DD-MM-YYYY HH:mm:ss")
          : "",
    },
    {
      title: t("Common-EndDate"),
      dataIndex: "endDate",
      key: "endDate",
      render: (date: any) =>
        date
          ? moment(date, "YYYY-MM-DDHH:mm:ss").format("DD-MM-YYYY HH:mm:ss")
          : "",
    },
    {
      title: t("Common-Actions"),
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          {hasPermission(privilegesEnum.WRITE_PRICINGS) &&
            record.status.value === "CREATED" && (
              <CalendarOutlined
                onClick={() => {
                  showScheduledModal(record);
                }}
              />
            )}
          {/*{hasPermission(privilegesEnum.WRITE_PRICINGS) &&*/}
          {/*  (record.status.value === "CREATED" ||*/}
          {/*    record.status.value === "SCHEDULED") && (*/}
          {/*    <CloseOutlined*/}
          {/*      onClick={() => {*/}
          {/*        showCancelConfirm(record.id);*/}
          {/*      }}*/}
          {/*    />*/}
          {/*  )}*/}
          {hasPermission(privilegesEnum.WRITE_PRICINGS) ? (
            <EditOutlined
              href={`/pricings/${record.id}/view`}
              onClick={() => {
                window.location.href = `/pricings/${record.id}/view`;
              }}
            />
          ) : (
            <EyeOutlined
              href={`/pricings/${record.id}/view`}
              onClick={() => {
                window.location.href = `/pricings/${record.id}/view`;
              }}
            />
          )}
          {hasPermission(privilegesEnum.DELETE_PRICINGS) && (
            <DeleteOutlined
              onClick={() => {
                showDeleteConfirm(record.key);
              }}
            />
          )}
        </Space>
      ),
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-Pricings")}</h1>
        {hasPermission(privilegesEnum.WRITE_PRICINGS) && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              href="/pricings/create"
              className={styles.newButton}
              type="primary"
              icon={<PlusOutlined />}
            />
            <Button
              // onClick={handleCancelButton}
              className={styles.importButton}
              type="primary"
            >
              {t("Common-Import")}
            </Button>
          </div>
        )}
      </div>
      <div className={styles.contentWrapper}>
        {hasPermission(privilegesEnum.READ_PRICINGS) && (
          <div className={styles.tableWrapper}>
            <Table
              size="small"
              className={styles.table}
              loading={loading}
              columns={columns}
              dataSource={data}
              pagination={{ total: totalSize, showSizeChanger:false, onChange: handlePagination }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Pricings;

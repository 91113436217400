import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
    createCategory,
    getRoundingTypes,
    getCategoriesFiltered,
    getAllStatesByCountry,
    getAllIdTypes,
    getAllCountries,
    getAllSexTypes,
} from "../../../api/Services";
import {
    hasPermission,
    privilegesEnum,
} from "../../../helpers/PermissionHelper";

import {
    Button,
    DatePicker,
    Form,
    Input,
    message,
    Select,
    Spin,
    Switch,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { BusinessUniteContext } from "../../../components/laoyut/Layout";

import styles from "./NewClient.module.scss";

const NewClientmockup = () => {
    const { t } = useTranslation();
    const { contextValue: businessUnitSelected } =
        useContext(BusinessUniteContext);

    const [loading, setLoading] = useState(false);
    const [countries, setCountries] = useState<any[]>([]);
    const [statesByCountry, setStatesByCountry] = useState<any[]>([]);
    const [sexTypes, setSexTypes] = useState<any[]>([]);
    const [idTypes, setIdTypes] = useState<any[]>([]);

    const [form] = Form.useForm();
    useEffect(() => {
        handleFetchIdTypes();
        handleFetchSexTypes();
        handleFetchCountries();
    }, []);

    const handleFetchIdTypes = async () => {
        const response = await getAllIdTypes();
        setIdTypes(response);
    };

    const handleFetchCountries = async () => {
        const response = await getAllCountries();
        setCountries(response);
    };

    const handleFetchSexTypes = async () => {
        const response = await getAllSexTypes();
        setSexTypes(response);
    };
    const getCountriesOptions = (items: any) => {
        const options: any[] = [];

        items?.forEach(function (item: any) {
            options.push(
                <Select.Option value={item.id} key={item.id}>
                    {item.name}
                </Select.Option>
            );
        });
        return options;
    };

    const handleFetchStatesByCountry = async (countryId: number) => {
        const response = await getAllStatesByCountry(countryId);
        setStatesByCountry(response);
    };

    return loading ? (
        <Spin
            indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
            delay={500}
        />
    ) : (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <h1 className={styles.title}>{t("Common-NewClient")}</h1>
                <Button
                    // onClick={handleCreateCategory}
                    className={styles.saveButton}
                    type="primary"
                >
                    {t("Common-Add")}
                </Button>
            </div>
            <div className={styles.formWrapper}>
                <h1 className={styles.subTitle}>{`${t("Common-PersonalInfo")} `}</h1>
                <Form
                    name="personalInfo"
                    layout="vertical"
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    className={styles.newForm}
                >
                    <Form.Item
                        label={t("Common-Identifier")}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: t("Common-Identifier"),
                            },
                        ]}
                    >
                        <Input placeholder={t("Common-Identifier")} />
                    </Form.Item>
                    <Form.Item label={t("Common-BirthDate")} name="birthDate">
                        <DatePicker
                            className={styles.dateSelector}
                            showTime
                            format="YYYY-MM-DD"
                        />
                    </Form.Item>
                    <Form.Item label={t("Common-Gender")} name="gender">
                        <Input placeholder={t("Common-Gender")} />
                    </Form.Item>
                    <Form.Item
                        className={styles.newItem}
                        label={t("Common-Country")}
                        name="country"
                    >
                        <Select
                            placeholder={t("Common-Country")}
                            onChange={(value: any) => {
                                handleFetchStatesByCountry(value);
                            }}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input: string, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {getCountriesOptions(countries)}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        className={styles.newItem}
                        label={t("Common-City")}
                        name="city"
                    >
                        <Select
                            placeholder={t("Common-City")}
                            filterOption={(input: string, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                            optionFilterProp="children"
                        >
                            {getCountriesOptions(statesByCountry)}
                        </Select>
                    </Form.Item>
                </Form>
            </div>
            <div className={styles.formWrapper}>
                <h1 className={styles.subTitle}>{`${t("Common-Metrics")} `}</h1>
                <Form
                    name="metrics"
                    layout="vertical"
                    initialValues={{ remember: true }}
                    autoComplete="off"
                    className={styles.newForm}
                >
                    <Form.Item label={t("Common-Segments")} name="segments">
                        <Input placeholder={t("Common-Segments")} />
                    </Form.Item>
                    <Form.Item label={t("Common-Level")} name="level">
                        <Input placeholder={t("Common-Level")} />
                    </Form.Item>
                    <Form.Item label={t("Common-PointsBenefit")} name="points">
                        <Input placeholder={t("Common-PointsBenefit")} />
                    </Form.Item>
                    <Form.Item
                        initialValue={false}
                        label={t("Common-Status")}
                        name="enable"
                    >
                        <Switch />
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};
export default NewClientmockup;

import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  getPromotionById,
  getAllRecurrencesTypes,
  getAllAcceptedPaymentMethods,
  editPromotionRegular,
  editPromotionBuyTogether,
  editPromotionMxN,
  editPromotionProgressive,
  editPromotionBogo,
  getCategoriesFiltered,
  getBranchOfficesFiltered,
  getClientSegmentsFiltered,
  getBrandsFiltered,
  getSkusFiltered,
} from "../../api/Services";
import { hasPermission, privilegesEnum } from "../../helpers/PermissionHelper";
import NotFound from "../notfound/NotFound";
import AddSkuModal from "../../components/addskumodal/AddSkuModal";

import {
  Form,
  message,
  Select,
  Button,
  Input,
  Spin,
  DatePicker,
  Space,
  Switch,
  Checkbox,
  InputNumber,
  TimePicker,
} from "antd";
import {
  LoadingOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import styles from "./ViewPromotion.module.scss";

const ViewPromotion = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [promotion, setPromotion] = useState<any>();
  const [articles, setArticles] = useState<any[]>([]);
  const [branchOffices, setBranchOffices] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [brands, setBrands] = useState<any[]>([]);
  const [recurrenceTypes, setRecurrenceTypes] = useState<any[]>([]);
  const [acceptedPaymentMethods, setAcceptedPaymentMethods] = useState<any[]>(
    []
  );
  const [clientSegments, setClientSegments] = useState<any[]>([]);
  const [skusSelected, setSkusSelected] = useState<any[]>([]);
  const [skusSecondSelected, setSkusSecondSelected] = useState<any[]>([]);

  const [infoForm] = Form.useForm();
  const [conditionsForm] = Form.useForm();
  const [restrictionsForm] = Form.useForm();
  const [extraForm] = Form.useForm();

  const canEdit = hasPermission(privilegesEnum.WRITE_PROMOTIONS);

  const { id } = useParams();

  useEffect(() => {
    if (hasPermission(privilegesEnum.READ_PROMOTIONS)) {
      handleFetchPromotion();
      handleFetchRecurrenceTypes();
      handleFetchAcceptedPaymentMethods();
    }
  }, []);

  const handleFetchPromotion = async () => {
    setLoading(true);

    let response = await getPromotionById(id!);
    setCategories(response.categories ?? []);
    setBranchOffices(response.branchOffices ?? []);
    setBrands(response.brands ?? []);
    setClientSegments(response.clientSegments ?? []);
    if (response.type.value === "REGULAR")
      setArticles([response.regularPromotion.gift]);
    if (response.type.value === "BOGO")
      setArticles([response.buyOneGetOnePromotion.gift]);

    const skus =
      response.type.value === "MxN"
        ? response.mxnPromotion.skus
        : response.type.value === "BOGO"
        ? response.buyOneGetOnePromotion.skus
        : response.type.value === "REGULAR"
        ? response.regularPromotion.skus
        : response.type.value === "PROGRESSIVE_DISCOUNT"
        ? response.progressiveDiscountPromotion.skus
        : response.type.value === "BUY_TOGETHER"
        ? response.buyTogetherPromotion.firstList
        : [];

    const skusSecondList =
      response.type.value === "BUY_TOGETHER"
        ? response.buyTogetherPromotion.secondList
        : [];

    setSkusSelected(skus);
    setSkusSecondSelected(skusSecondList);
    setPromotion(response);

    setLoading(false);
  };

  const handleFetchRecurrenceTypes = async () => {
    const response = await getAllRecurrencesTypes();
    setRecurrenceTypes(response);
  };

  const handleFetchAcceptedPaymentMethods = async () => {
    const response = await getAllAcceptedPaymentMethods();
    setAcceptedPaymentMethods(response);
  };

  const handleSearchCategories = async (value: string) => {
    if (value.length > 2) {
      const response = await getCategoriesFiltered(value);
      setCategories(response);
    } else setCategories([]);
  };

  const handleSearchBranchOffices = async (value: string) => {
    if (value.length > 1) {
      const response = await getBranchOfficesFiltered(0, { name: value });
      setBranchOffices(response.branchOffices);
    } else setBranchOffices([]);
  };

  const handleSearchClientSegments = async (value: string) => {
    if (value.length > 2) {
      const response = await getClientSegmentsFiltered(value);
      setClientSegments(response);
    } else setClientSegments([]);
  };

  const handleSearchBrands = async (value: string) => {
    if (value.length > 2) {
      const response = await getBrandsFiltered(value);
      setBrands(response);
    } else setBrands([]);
  };

  const handleCancelButton = () => {
    infoForm.resetFields();
  };

  const getOptions = (items: any) => {
    const options: any[] = [];

    items.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.id} key={item.id}>
          {item.name}
        </Select.Option>
      );
    });

    return options;
  };

  const getOptionsCategories = (items: any) => {
    const options: any[] = [];

    items?.forEach(function (item: any) {
      const parentsString = item?.parents
          ? item.parents.filter(Boolean).join(" - ")
          : "";
      const categoryName = item?.name || "";

      const categoryTree = parentsString
          ? `${parentsString} - ${categoryName}`
          : categoryName;

      options.push(
          <Select.Option value={item.id} key={item.id}>
            {categoryTree}
          </Select.Option>
      );
    });

    return options;
  };


  const getRecurrenceOptions = () => {
    const options: any[] = [];

    recurrenceTypes.forEach(function (item: any) {
      options.push(
        <Select.Option value={item.value} key={item.value}>
          {item.description_es}
        </Select.Option>
      );
    });

    return options;
  };

  const handleAddSku = (skusToAdd: any) => {
    const newSkus: any[] = [];
    skusToAdd.forEach((skuToAdd: any) => {
      if (
        skusSelected.find((article: any) => article.id === skuToAdd.id) ===
        undefined
      )
        newSkus.push(skuToAdd);
    });
    setSkusSelected([...skusSelected, ...newSkus]);
  };

  const handleRemoveSkus = (skuRemove: any) => {
    const skusRemoved = skusSelected.filter((sku) => sku.id !== skuRemove.id);

    setSkusSelected(skusRemoved);
  };

  const handleAddSecondSku = (skusToAdd: any) => {
    const newSkus: any[] = [];
    skusToAdd.forEach((skuToAdd: any) => {
      if (
        skusSecondSelected.find(
          (article: any) => article.id === skuToAdd.id
        ) === undefined
      )
        newSkus.push(skuToAdd);
    });
    setSkusSecondSelected([...skusSecondSelected, ...newSkus]);
  };

  const handleSearchSkus = async (value: string) => {
    if (value.length > 2) {
      const response = await getSkusFiltered(0, { skuName: value });
      setArticles(response.skus);
    } else setArticles([]);
  };

  const handleRemoveSecondSkus = (skuRemove: any) => {
    const skusRemoved = skusSecondSelected.filter(
      (sku) => sku.id !== skuRemove.id
    );

    setSkusSecondSelected(skusRemoved);
  };

  const handleEdit = async () => {
    setLoading(true);

    const data = infoForm.getFieldsValue();
    data.id = parseInt(id!);

    const conditions = conditionsForm.getFieldsValue();
    const restrictions = restrictionsForm.getFieldsValue();
    const extras = extraForm.getFieldsValue();
    let response;

    const {
      name,
      description,
      recurrenceType,
      date,
      type,
      recurrenceDays,
      dayMonthly,
      dayAnually,
      period,
      repeatInterval,
    } = data;

    const startTime = period ? period[0]?.format("HH:mm") : null;
    const endTime = period ? period[1]?.format("HH:mm") : null;
    const recurrence: {
      type: any;
      dayAnually: any;
      dayMonthly: any;
      monday?: boolean;
      tuesday?: boolean;
      wednesday?: boolean;
      thursday?: boolean;
      friday?: boolean;
      saturday?: boolean;
      sunday?: boolean;
      repeatInterval?: number;
      startTime?: any;
      endTime?: any;
    } | null = recurrenceType
      ? {
          type: recurrenceType,
          dayAnually: dayAnually ? dayAnually.format("YYYY-MM-DD") : null,
          dayMonthly,
          startTime,
          endTime,
          repeatInterval,
        }
      : null;

    if (recurrence)
      recurrenceDays?.forEach(
        (
          element:
            | "monday"
            | "tuesday"
            | "wednesday"
            | "thursday"
            | "friday"
            | "saturday"
            | "sunday"
        ) => (recurrence[element] = true)
      );

    const {
      branchOfficesIds,
      clientSegmentIds,
      categoryIds,
      brandIds,
      acceptedPaymentMethodIds,
      bines,
      binMin,
      binMax,
    } = conditions;

    const {
      quantityApplications,
      quantityPerArticle,
      quantityPerBranchOffice,
      quantityPerClient,
      minimumTicket,
      maximumTicket,
    } = restrictions;

    const { accumulateOtherPromotions, distributeLines } = extras;

    const startDate = date ? date[0]?.format("YYYY-MM-DDTHH:mm:ss") : null;
    const endDate = date ? date[1]?.format("YYYY-MM-DDTHH:mm:ss") : null;

    let promo: any = {
      promotion: {
        name,
        description,
        recurrence,
        startDate,
        endDate,
        type,
        branchOfficeIds: branchOfficesIds ?? [],
        clientSegmentIds: clientSegmentIds ?? [],
        categoryIds: categoryIds ?? [],
        brandIds: brandIds ?? [],
        acceptedPaymentMethodIds: acceptedPaymentMethodIds ?? [],
        bines: bines ?? [],
        binMin,
        binMax,
        minimumTicket,
        maximumTicket,
        quantityApplications,
        quantityPerArticle,
        quantityPerBranchOffice,
        quantityPerClient,
        accumulateOtherPromotions,
        distributeLines,
      },
    };

    switch (promotion?.type.value) {
      case "REGULAR": {
        const { regularPromotionType } = data;
        const skuList = skusSelected.map((sku) => sku.id);

        promo.skuIds = skuList;
        promo.regularPromotionType = regularPromotionType;

        switch (regularPromotionType) {
          case "GIFT": {
            const { giftId } = data;
            promo.giftId = giftId;
            break;
          }
          case "PERCENTAGE_SENT":
          case "PERCENTAGE": {
            const { discountPercentage } = data;
            promo.discountPercentage = discountPercentage;

            break;
          }
          case "MAX_PRICE_PER_ARTICLE":
          case "NOMINAL_SENT":
          case "NOMINAL": {
            const { discountValue } = data;
            promo.discountValue = discountValue;
            break;
          }
        }
        response = await editPromotionRegular(parseInt(id!), promo);
        break;
      }
      case "BUY_TOGETHER": {
        const { discountFirstList, discountSecondList } = data;

        const skusFirstList = skusSelected.map((sku) => sku.id);
        const skusSecondList = skusSecondSelected.map((sku) => sku.id);

        promo.discountFirstList = discountFirstList;
        promo.discountSecondList = discountSecondList;
        promo.skuIdFirstList = skusFirstList;
        promo.skuIdSecondList = skusSecondList;

        response = await editPromotionBuyTogether(parseInt(id!), promo);
        break;
      }
      case "MxN": {
        const {
          quantityForPromotion,
          quantityWithDiscount,
          discountPercent,
          allProducts,
          highPrice,
          forTotalArticles,
        } = data;

        const skuList = skusSelected.map((sku) => sku.id);

        promo.skuIds = skuList;
        promo.quantityForPromotion = quantityForPromotion;
        promo.quantityWithDiscount = quantityWithDiscount;
        promo.discountPercent = discountPercent;
        promo.allProducts = allProducts;
        promo.highPrice = highPrice;
        promo.forTotalArticles = forTotalArticles;

        response = await editPromotionMxN(parseInt(id!), promo);
        break;
      }
      case "PROGRESSIVE_DISCOUNT": {
        const { lines } = data;

        const skuList = skusSelected.map((sku) => sku.id);
        promo.lines = lines;
        promo.skuIds = skuList;
        response = await editPromotionProgressive(parseInt(id!), promo);
        break;
      }
      case "BOGO": {
        const { discountType, bogoGiftId } = data;
        const skuList = skusSelected.map((sku) => sku.id);

        promo.type = discountType;
        promo.giftId = bogoGiftId ?? null;
        promo.skuIds = skuList;
        response = await editPromotionBogo(parseInt(id!), promo);
        break;
      }
      default:
        break;
    }

    if (response) {
      message.success(t("Common-EditSuccessMessage"));
      await handleFetchPromotion();
    } else message.error(t("Common-EditErrorMessage"));
    setLoading(false);
  };

  return loading ? (
    <Spin
      indicator={<LoadingOutlined className="ol-loading-spin-icon" />}
      delay={500}
    />
  ) : !promotion ? (
    <NotFound />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>{t("Common-Promotion")}</h1>
        {hasPermission(privilegesEnum.WRITE_PROMOTIONS) && (
          <div className={styles.actionButtonsWrapper}>
            <Button
              onClick={handleEdit}
              className={styles.saveButton}
              type="primary"
            >
              {t("Common-Save")}
            </Button>
            <Button
              onClick={handleCancelButton}
              className={styles.cancelButton}
              type="primary"
            >
              {t("Common-Cancel")}
            </Button>
          </div>
        )}
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.infoWrapper}>
          <Form
            name="promotionForm"
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.newForm}
            form={infoForm}
          >
            <Form.Item
              label={t("Common-Name")}
              name="name"
              initialValue={promotion?.name}
              rules={[
                {
                  required: true,
                  message: t("Common-Name"),
                },
              ]}
            >
              <Input placeholder={t("Common-Name")} disabled={!canEdit} />
            </Form.Item>
            <Form.Item
              label={t("Common-Description")}
              name="description"
              initialValue={promotion?.description}
            >
              <Input
                placeholder={t("Common-Description")}
                disabled={!canEdit}
              />
            </Form.Item>
            <Form.Item
              label={t("Common-Recurrence")}
              initialValue={promotion?.recurrence?.type?.value}
              name="recurrenceType"
            >
              <Select
                allowClear
                placeholder={t("Common-Recurrence")}
                showSearch
                optionFilterProp="children"
                defaultValue={promotion?.recurrence?.type?.value}
                filterOption={(input: string, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {getRecurrenceOptions()}
              </Select>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.recurrenceType !== currentValues.recurrenceType
              }
            >
              {({ getFieldValue }) => {
                switch (getFieldValue("recurrenceType")) {
                  case "WEEKLY":
                    return (
                      <Form.Item
                        name="recurrenceDays"
                        label={t("Common-On")}
                        rules={[{ required: true, message: t("Common-On") }]}
                        initialValue={[
                          promotion?.recurrence?.monday ? "monday" : "",
                          promotion?.recurrence?.tuesday ? "tuesday" : "",
                          promotion?.recurrence?.wednesday ? "wednesday" : "",
                          promotion?.recurrence?.thursday ? "thursday" : "",
                          promotion?.recurrence?.friday ? "friday" : "",
                          promotion?.recurrence?.saturday ? "saturday" : "",
                          promotion?.recurrence?.sunday ? "sunday" : "",
                        ]}
                      >
                        <Checkbox.Group
                          defaultValue={[
                            promotion?.recurrence?.monday ? "monday" : "",
                            promotion?.recurrence?.tuesday ? "tuesday" : "",
                            promotion?.recurrence?.wednesday ? "wednesday" : "",
                            promotion?.recurrence?.thursday ? "thursday" : "",
                            promotion?.recurrence?.friday ? "friday" : "",
                            promotion?.recurrence?.saturday ? "saturday" : "",
                            promotion?.recurrence?.sunday ? "sunday" : "",
                          ]}
                          options={[
                            {
                              label: t("Common-Monday"),
                              value: "monday",
                            },
                            { label: t("Common-Tuesday"), value: "tuesday" },
                            {
                              label: t("Common-Wednesday"),
                              value: "wednesday",
                            },
                            { label: t("Common-Thursday"), value: "thursday" },
                            { label: t("Common-Friday"), value: "friday" },
                            { label: t("Common-Saturday"), value: "saturday" },
                            { label: t("Common-Sunday"), value: "sunday" },
                          ]}
                        />
                      </Form.Item>
                    );
                  case "MONTHLY":
                    return (
                      <Form.Item
                        label={t("Common-Day")}
                        name="dayMonthly"
                        rules={[
                          {
                            required: true,
                            message: t("Common-Day"),
                          },
                        ]}
                        initialValue={promotion?.recurrence?.dayMonthly}
                      >
                        <InputNumber
                          min="1"
                          max="31"
                          className={styles.inputNumber}
                          placeholder={t("Common-Day")}
                        />
                      </Form.Item>
                    );
                  case "ANUALLY":
                    return (
                      <Form.Item
                        label={t("Common-Day")}
                        name="dayAnually"
                        rules={[
                          {
                            required: true,
                            message: t("Common-Day"),
                          },
                        ]}
                        initialValue={promotion?.recurrence?.dayAnually}
                      >
                        <DatePicker
                          className={styles.dateSelector}
                          placeholder={t("Common-Date")}
                          // onChange={handleChangeDate}
                        />
                      </Form.Item>
                    );
                }
              }}
            </Form.Item>
            <Form.Item
              label={t("Common-Interval")}
              name="repeatInterval"
              initialValue={promotion?.recurrence?.repeatInterval}
            >
              <Input placeholder={t("Common-Interval")} />
            </Form.Item>
            <Form.Item
              name="period"
              label={t("Common-Period")}
              initialValue={[
                promotion?.recurrence?.startTime
                  ? moment(promotion?.recurrence?.startTime, "HH:mm")
                  : null,
                promotion?.recurrence?.endTime
                  ? moment(promotion?.recurrence?.endTime, "HH:mm")
                  : null,
              ]}
            >
              <TimePicker.RangePicker
                format="HH:mm"
                className={styles.dateSelector}
                allowEmpty={[false, true]}
                defaultValue={[
                  promotion?.recurrence?.startTime
                    ? moment(promotion?.recurrence?.startTime, "HH:mm")
                    : null,
                  promotion?.recurrence?.endTime
                    ? moment(promotion?.recurrence?.endTime, "HH:mm")
                    : null,
                ]}
              />
            </Form.Item>
            <Form.Item
              label={t("Common-Date")}
              initialValue={[
                promotion?.startDate
                  ? moment(promotion?.startDate, "YYYY-MM-DDHH:mm:ss")
                  : null,
                promotion?.endDate
                  ? moment(promotion?.endDate, "YYYY-MM-DDHH:mm:ss")
                  : null,
              ]}
              name="date"
            >
              <DatePicker.RangePicker
                showTime={{ format: "HH:mm" }}
                format="YYYY-MM-DD HH:mm"
                className={styles.dateSelector}
                defaultValue={[
                  promotion?.startDate
                    ? moment(promotion?.startDate, "YYYY-MM-DDHH:mm:ss")
                    : null,
                  promotion?.endDate
                    ? moment(promotion?.endDate, "YYYY-MM-DDHH:mm:ss")
                    : null,
                ]}
              />
            </Form.Item>
            <Form.Item
              className={styles.newItem}
              label={t("Common-Type")}
              name="type"
              initialValue={promotion?.type.description_es}
              rules={[
                {
                  required: true,
                  message: t("Common-Type"),
                },
              ]}
            >
              <Select
                defaultValue={promotion?.type.value}
                disabled={true}
                placeholder={t("Common-Type")}
                allowClear
              >
                <Select.Option value="buytogether">
                  Comprar juntos
                </Select.Option>
                <Select.Option value="bogo">Buy one get one</Select.Option>
                <Select.Option value="progressive">Progresivo</Select.Option>
                <Select.Option value="mxn">MxN</Select.Option>
                <Select.Option value="regular">Regular</Select.Option>
              </Select>
            </Form.Item>
            {promotion?.type?.value === "REGULAR" && (
              <>
                <AddSkuModal
                  title={t("Common-Skus")}
                  selectedArticles={skusSelected}
                  onAddArticle={handleAddSku}
                  onRemoveArticle={handleRemoveSkus}
                />
                <Form.Item
                  label={t("Common-DiscountType")}
                  name="regularPromotionType"
                  initialValue={promotion?.regularPromotion.type.value}
                  rules={[
                    {
                      required: true,
                      message: t("Common-DiscountType"),
                    },
                  ]}
                >
                  <Select
                    placeholder="Tipo"
                    // onChange={onGenderChange}
                    allowClear
                  >
                    <Select.Option value="NOMINAL">Nominal</Select.Option>
                    <Select.Option value="NOMINAL_SENT">
                      Envio nominal
                    </Select.Option>
                    <Select.Option value="PERCENTAGE">Porcentual</Select.Option>
                    <Select.Option value="PERCENTAGE_SENT">
                      Porcentual envio
                    </Select.Option>
                    <Select.Option value="MAX_PRICE_PER_ARTICLE">
                      Precio maximo de articulo
                    </Select.Option>
                    <Select.Option value="FREE_SHIPPING">
                      Envio gratis
                    </Select.Option>
                    <Select.Option value="GIFT">Regalo</Select.Option>
                    <Select.Option value="NOMINAL_DISCOUNT">
                      Descuento nominal
                    </Select.Option>
                    <Select.Option value="PRICE_TABLE">
                      Tabla de precios
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.regularPromotionType !==
                    currentValues.regularPromotionType
                  }
                >
                  {({ getFieldValue }) => {
                    switch (getFieldValue("regularPromotionType")) {
                      case "GIFT":
                        return (
                          <Form.Item
                            name="giftId"
                            label={t("Common-Sku")}
                            rules={[{ required: true }]}
                            initialValue={promotion?.regularPromotion.gift.id}
                          >
                            <Select
                              allowClear
                              showSearch
                              onSearch={handleSearchSkus}
                              placeholder={t("Common-SearchByName")}
                              filterOption={(input: string, option: any) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {getOptions(articles)}
                            </Select>
                          </Form.Item>
                        );
                      case "PERCENTAGE_SENT":
                      case "PERCENTAGE":
                        return (
                          <Form.Item
                            label={t("Common-DiscountPercent")}
                            name="discountPercentage"
                            initialValue={
                              promotion?.regularPromotion.discountPercentage
                            }
                            rules={[
                              {
                                required: true,
                                message: t("Common-DiscountPercent"),
                              },
                            ]}
                          >
                            <Input placeholder={t("Common-DiscountPercent")} />
                          </Form.Item>
                        );
                      case "MAX_PRICE_PER_ARTICLE":
                      case "NOMINAL_SENT":
                      case "NOMINAL":
                        return (
                          <Form.Item
                            label={t("Common-DiscountValue")}
                            name="discountValue"
                            initialValue={
                              promotion?.regularPromotion.discountValue
                            }
                            rules={[
                              {
                                required: true,
                                message: t("Common-DiscountValue"),
                              },
                            ]}
                          >
                            <Input
                              type="number"
                              placeholder={t("Common-DiscountValue")}
                            />
                          </Form.Item>
                        );
                    }
                  }}
                </Form.Item>
              </>
            )}
            {promotion.type.value === "BOGO" && (
              <>
                <AddSkuModal
                  title={t("Common-Skus")}
                  selectedArticles={skusSelected}
                  onAddArticle={handleAddSku}
                  onRemoveArticle={handleRemoveSkus}
                />
                <Form.Item
                  label={t("Common-DiscountType")}
                  name="discountType"
                  initialValue={
                    promotion?.buyOneGetOnePromotion.bogopromotionType.value
                  }
                  rules={[
                    {
                      required: true,
                      message: t("Common-DiscountType"),
                    },
                  ]}
                >
                  <Select
                    placeholder="Tipo"
                    // onChange={onGenderChange}
                    allowClear
                  >
                    <Select.Option value="GIFT">
                      {t("Common-Gift")}
                    </Select.Option>
                    <Select.Option value="FREE_SHIPPING">
                      {t("Common-FreeShipping")}
                    </Select.Option>
                    <Select.Option value="MAX_PRICE_PER_ARTICLE">
                      {t("Common-MaxPricePerArticle")}
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.discountType !== currentValues.discountType
                  }
                >
                  {({ getFieldValue }) =>
                    getFieldValue("discountType") === "GIFT" ? (
                      <Form.Item
                        name="bogoGiftId"
                        label={t("Common-Sku")}
                        initialValue={promotion?.buyOneGetOnePromotion.gift.id}
                        rules={[{ required: true }]}
                      >
                        <Select
                          allowClear
                          showSearch
                          onSearch={handleSearchSkus}
                          placeholder={t("Common-SearchByName")}
                          filterOption={(input: string, option: any) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {getOptions(articles)}
                        </Select>
                      </Form.Item>
                    ) : null
                  }
                </Form.Item>
              </>
            )}
            {promotion.type.value === "MxN" && (
              <>
                <AddSkuModal
                  title={t("Common-Skus")}
                  selectedArticles={skusSelected}
                  onAddArticle={handleAddSku}
                  onRemoveArticle={handleRemoveSkus}
                />
                <div className={styles.formItemsWrapper}>
                  <Form.Item
                    label={t("Common-QuantityForPromotion")}
                    name="quantityForPromotion"
                    initialValue={promotion?.mxnPromotion.quantityForPromotion}
                    rules={[
                      {
                        required: true,
                        message: t("Common-QuantityForPromotion"),
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      placeholder={t("Common-QuantityForPromotion")}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t("Common-QuantityWithDiscount")}
                    name="quantityWithDiscount"
                    initialValue={promotion?.mxnPromotion.quantityWithDiscount}
                    rules={[
                      {
                        required: true,
                        message: t("Common-QuantityWithDiscount"),
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      placeholder={t("Common-QuantityWithDiscount")}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t("Common-DiscountPercent")}
                    name="discountPercent"
                    initialValue={promotion?.mxnPromotion.discountPercent}
                    rules={[
                      {
                        required: true,
                        message: t("Common-DiscountPercent"),
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      placeholder={t("Common-DiscountPercent")}
                    />
                  </Form.Item>
                </div>
                <div className={styles.formItemsWrapper}>
                  <Form.Item
                    label={t("Common-AllProducts")}
                    name="allProducts"
                    initialValue={promotion?.mxnPromotion.allProducts}
                    rules={[
                      {
                        required: true,
                        message: t("Common-AllProducts"),
                      },
                    ]}
                  >
                    <Switch
                      defaultChecked={promotion?.mxnPromotion.allProducts}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t("Common-HighPrice")}
                    name="highPrice"
                    initialValue={promotion?.mxnPromotion.highPrice}
                    rules={[
                      {
                        required: true,
                        message: t("Common-HighPrice"),
                      },
                    ]}
                  >
                    <Switch
                      defaultChecked={promotion?.mxnPromotion.highPrice}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t("Common-ForTotalArticles")}
                    name="forTotalArticles"
                    initialValue={promotion?.mxnPromotion.forTotalArticles}
                    rules={[
                      {
                        required: true,
                        message: t("Common-ForTotalArticles"),
                      },
                    ]}
                  >
                    <Switch
                      defaultChecked={promotion?.mxnPromotion.forTotalArticles}
                    />
                  </Form.Item>
                </div>
              </>
            )}
            {promotion.type.value === "PROGRESSIVE_DISCOUNT" && (
              <>
                <AddSkuModal
                  title={t("Common-Skus")}
                  selectedArticles={skusSelected}
                  onAddArticle={handleAddSku}
                  onRemoveArticle={handleRemoveSkus}
                />
                <Form.List
                  name="lines"
                  initialValue={promotion?.progressiveDiscountPromotion.lines}
                >
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          className={styles.contactSpace}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            label={t("Common-Quantity")}
                            name={[name, "quantity"]}
                            rules={[
                              {
                                required: true,
                                message: t("Common-Quantity"),
                              },
                            ]}
                          >
                            <Input placeholder={t("Common-Quantity")} />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            label={t("Common-DiscountPercent")}
                            name={[name, "discount"]}
                            rules={[
                              {
                                required: true,
                                message: t("Common-DiscountPercent"),
                              },
                            ]}
                          >
                            <Input placeholder={t("Common-DiscountPercent")} />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button
                          type="primary"
                          className={styles.actionsButton}
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                        />
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </>
            )}
            {promotion.type.value === "BUY_TOGETHER" && (
              <>
                <div>
                  <AddSkuModal
                    title={t("Common-Skus")}
                    selectedArticles={skusSelected}
                    onAddArticle={handleAddSku}
                    onRemoveArticle={handleRemoveSkus}
                  />
                  <Form.Item
                    label={t("Common-DiscountPercent")}
                    name="discountFirstList"
                    initialValue={
                      promotion?.buyTogetherPromotion.discountFirstList
                    }
                    className={styles.buyTogetherItems}
                    rules={[
                      {
                        required: true,
                        message: t("Common-DiscountPercent"),
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      placeholder={t("Common-DiscountPercent")}
                    />
                  </Form.Item>
                </div>
                <div>
                  <AddSkuModal
                    title={t("Common-Skus")}
                    selectedArticles={skusSecondSelected}
                    onAddArticle={handleAddSecondSku}
                    onRemoveArticle={handleRemoveSecondSkus}
                  />
                  <Form.Item
                    label={t("Common-DiscountPercent")}
                    name="discountSecondList"
                    initialValue={
                      promotion?.buyTogetherPromotion.discountSecondList
                    }
                    className={styles.buyTogetherItems}
                    rules={[
                      {
                        required: true,
                        message: t("Common-DiscountPercent"),
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      placeholder={t("Common-DiscountPercent")}
                    />
                  </Form.Item>
                </div>
              </>
            )}
          </Form>
        </div>
        <div className={styles.infoWrapper}>
          <h1 className={styles.subTitle}>{`${t("Common-Conditions")} `}</h1>
          <Form
            name="conditionForm"
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.newForm}
            form={conditionsForm}
          >
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-BranchOffices")}
                name="branchOfficesIds"
                className={styles.doubleItemLine}
                initialValue={promotion?.branchOffices.map(
                  (branchOffice: any) => branchOffice.id
                )}
              >
                <Select
                  mode="multiple"
                  allowClear
                  onSearch={handleSearchBranchOffices}
                  placeholder={t("Common-SearchByName")}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {getOptions(branchOffices)}
                </Select>
              </Form.Item>
              <Form.Item
                label={t("Common-ClientSegments")}
                name="clientSegmentIds"
                className={styles.doubleItemLine}
                initialValue={promotion?.clientSegments.map(
                  (clientSegment: any) => clientSegment.id
                )}
              >
                <Select
                  mode="multiple"
                  allowClear
                  onSearch={handleSearchClientSegments}
                  placeholder={t("Common-SearchByName")}
                  defaultValue={promotion?.clientSegments.map(
                    (clientSegment: any) => clientSegment.id
                  )}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {getOptions(clientSegments)}
                </Select>
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-Categories")}
                name="categoryIds"
                className={styles.doubleItemLine}
                initialValue={promotion?.categories.map(
                  (category: any) => category.id
                )}
              >
                <Select
                  mode="multiple"
                  onSearch={handleSearchCategories}
                  placeholder={t("Common-SearchByName")}
                  allowClear
                  defaultValue={promotion?.categories.map(
                    (category: any) => category.id
                  )}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {getOptionsCategories(categories)}
                </Select>
              </Form.Item>
              <Form.Item
                label={t("Common-Brands")}
                name="brandIds"
                initialValue={promotion?.brands.map((brand: any) => brand.id)}
                className={styles.doubleItemLine}
              >
                <Select
                  mode="multiple"
                  allowClear
                  onSearch={handleSearchBrands}
                  placeholder={t("Common-SearchByName")}
                  defaultValue={promotion?.brands.map((brand: any) => brand.id)}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {getOptions(brands)}
                </Select>
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-AcceptedPaymentMethods")}
                name="acceptedPaymentMethodIds"
                className={styles.doubleItemLine}
                initialValue={promotion?.acceptedPaymentMethods.map(
                  (paymentMethod: any) => paymentMethod.id
                )}
              >
                <Select
                  mode="multiple"
                  allowClear
                  placeholder={t("Common-AcceptedPaymentMethods")}
                  filterOption={(input: string, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  defaultValue={promotion?.acceptedPaymentMethods.map(
                    (paymentMethod: any) => paymentMethod.id
                  )}
                >
                  {getOptions(acceptedPaymentMethods)}
                </Select>
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-MinimumTicket")}
                name="minimumTicket"
                initialValue={promotion?.minimumTicket}
                className={styles.doubleItemLine}
              >
                <Input type="number" placeholder={t("Common-MinimumTicket")} />
              </Form.Item>
              <Form.Item
                label={t("Common-MaximumTicket")}
                name="maximumTicket"
                initialValue={promotion?.maximumTicket}
                className={styles.doubleItemLine}
              >
                <Input type="number" placeholder={t("Common-MaximumTicket")} />
              </Form.Item>
            </div>
          </Form>
        </div>
        <div className={styles.infoWrapper}>
          <h1 className={styles.subTitle}>{`${t("Common-Restrictions")} `}</h1>
          <Form
            name="restrictionsForm"
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.newForm}
            form={restrictionsForm}
          >
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-QuantityApplications")}
                name="quantityApplications"
                initialValue={promotion?.quantityApplications}
                className={styles.doubleItemLine}
              >
                <Input
                  type="number"
                  placeholder={t("Common-QuantityApplications")}
                />
              </Form.Item>
              <Form.Item
                label={t("Common-QuantityPerBranchOffice")}
                name="quantityPerBranchOffice"
                initialValue={promotion?.quantityPerBranchOffice}
                className={styles.doubleItemLine}
              >
                <Input
                  type="number"
                  placeholder={t("Common-QuantityPerBranchOffice")}
                />
              </Form.Item>
            </div>
            <div className={styles.formItemsWrapper}>
              <Form.Item
                label={t("Common-QuantityPerClient")}
                name="quantityPerClient"
                initialValue={promotion?.quantityPerClient}
                className={styles.doubleItemLine}
              >
                <Input
                  type="number"
                  placeholder={t("Common-QuantityPerClient")}
                />
              </Form.Item>
              <Form.Item
                label={t("Common-QuantityPerArticle")}
                name="quantityPerArticle"
                initialValue={promotion?.quantityPerArticle}
                className={styles.doubleItemLine}
              >
                <Input
                  type="number"
                  placeholder={t("Common-QuantityPerArticle")}
                />
              </Form.Item>
            </div>
          </Form>
        </div>
        <div className={styles.infoWrapper}>
          <h1 className={styles.subTitle}>{`${t("Common-MoreOptions")} `}</h1>
          <Form
            name="extraForm"
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            className={styles.newForm}
            form={extraForm}
          >
            <Form.Item
              initialValue={promotion?.accumulateOtherPromotions}
              label={t("Common-AccumulateOtherPromos")}
              name="accumulateOtherPromotions"
              rules={[
                {
                  required: true,
                  message: t("Common-AccumulateOtherPromos"),
                },
              ]}
            >
              <Switch checked={promotion?.accumulateOtherPromotions} />
            </Form.Item>
            <Form.Item
              initialValue={promotion?.distributeLines}
              label={t("Common-DistributeLines")}
              name="distributeLines"
              rules={[
                {
                  required: true,
                  message: t("Common-DistributeLines"),
                },
              ]}
            >
              <Switch checked={promotion?.distributeLines} />
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ViewPromotion;
